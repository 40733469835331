import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';
import "./notification-message.scss";

const NotificationMessage = () => {
  const [isHideNotification, setHideNotification] = useState(false);

  const notification = useSelector(
    ({ common }) => common.commonDetailsData.home_page_notification_message
  );

  const hideNotificationBar = () => {
    setHideNotification(true);
    setLocalStorageValue("hideNotification", "true");
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  const hideNotification = getLocalStorageValue("hideNotification");

  return (
    <>
      {notification && (
        <div
          className={
            hideNotification
              ? "notification-message-hide"
              : isHideNotification
                ? "notification-message-hide"
                : "notification-message-show"
          }
        >
          <div className="container">
            <div className="grid">
              <div className="notification-text">
                <p>{notification}</p>
              </div>

              <div className="cross-icon" onClick={hideNotificationBar}>
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationMessage;
