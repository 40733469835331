import {
  FETCH_BLOG_LIST,
  FETCH_BLOG_LIST_SUCCESS,
  FETCH_BLOG_LIST_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  blogData: [],
  blogDataLoading: true,
  blogDataError: "",
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG_LIST:
      return {
        ...state,
        blogDataLoading: true,
      };
    case FETCH_BLOG_LIST_SUCCESS:
      return {
        ...state,
        blogDataLoading: false,
        blogData: action.payload,
      };
    case FETCH_BLOG_LIST_FAILURE:
      return {
        ...state,
        blogDataError: action.payload.error,
      };
    default:
      return state;
  }
};

export default blogReducer;
