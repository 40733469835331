import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import Location from "./Location";
import Details from "./Details";
import {  resetSearch } from "redux/actions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const storeList = useSelector(({ store }) => store.storeListData);
  
  const breadcrumbList = [{ lable: "Store Details", link: "", searchTag: "" }];

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

 
  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Store Details"} //Need to pass title through api res
      />

       <Location storeList={storeList} />
      
      <Details />
    </>
  );
};
export default ContactUs;
