import React from "react";
import "./information-section.scss";

const CustomInformation = ({ section }) => {
  return (
    <>
      <div className="container">
        <div className="information-section">
          <div className="information-alignment">
            {section.custom_child_section.map((item) => {
              return (
                <div className="info-box" key={item.cms_new_pages_child_section_id}>
                  <h1>{item.no_of_count}</h1>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomInformation;
