import React from "react";
import "./provide-section.scss";

const ProvideSection = ({ services_section }) => {
  return (
    <div>
      <div className="provide-section">
        <div className="container">
          <div className="provide-section-title">
            <h1>{services_section.title}</h1>
          </div>
          <div className="grid">
            {services_section.services_list.map((item) => {
              return (
                <div className="grid-items" key={item.our_services_id}>
                  <div className="icon-center-align">
                    <img src={item.icon_image} alt="WideIcon" />
                  </div>
                  <h2>{item.title}</h2>
                  <p>{item.short_description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvideSection;
