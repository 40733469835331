import React, { useEffect, useState } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import ProvideSection from "./ProvideSection";
import { getFooterhelpdetails } from "redux/actions";
import { useParams } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';

const Page = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const [pageSlug] = useState(slug ? slug : "");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      slug: pageSlug,
      ClientStoreId: storeId,

      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getFooterhelpdetails(body));
  }, [dispatch]); // eslint-disable-line

  const pageData = useSelector(({ footer }) => footer.footerhelpPageData);

  const breadcrumbList = [{ lable: pageData.title, link: "", searchTag: "" }];

  return (
    <div>
      {Object.keys(pageData).length > 0 && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={pageData.title} //Need to pass title through api res
          />
          <ProvideSection pageData={pageData} />
        </>
      )}
    </div>
  );
};
export default Page;
