import {
  FETCH_WEEKLYADGALLERY_DATA,
  FETCH_WEEKLYADGALLERY_DATA_SUCCESS,
  FETCH_WEEKLYADGALLERY_DATA_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch about data
export const fetchWeeklyadgallery = (payload) => {
  return {
    type: FETCH_WEEKLYADGALLERY_DATA,
    payload: payload,
  };
};
export const fetchWeeklyadgallerySuccess = (payload) => {
  return {
    type: FETCH_WEEKLYADGALLERY_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchWeeklyadgalleryFailure = (payload) => {
  return {
    type: FETCH_WEEKLYADGALLERY_DATA_FAILURE,
    payload: payload,
  };
};

export const getWeeklyadgallery = (body) => async (dispatch) => {
  dispatch(fetchWeeklyadgallery());

  axios
    .post("/AogGetWeeklyAdGallery", body)
    .then((res) => {
      dispatch(fetchWeeklyadgallerySuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchWeeklyadgalleryFailure({ error: error.data.message }));
    });
};
