import {
  FETCH_STORE_LIST,
  FETCH_STORE_LIST_SUCCESS,
  FETCH_STORE_LIST_FAILURE,
  SELECT_STORE_TYPE,
  SELECT_CURRENT_STORE,
  FETCH_STORE_DETAILS,
  FETCH_STORE_DETAILS_SUCCESS,
  FETCH_STORE_DETAILS_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  storeListData: [],
  storeListDataLoading: true,
  storeListDataError: "",
  storeType: "store_pickup",
  currentStoreID: "",
  storeDetails: null,
  storeDetailsLoading: false,
  storeDetailsError: "",
};

const store = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORE_LIST:
      return {
        ...state,
        storeListDataLoading: true,
      };
    case FETCH_STORE_LIST_SUCCESS:
      return {
        ...state,
        storeListDataLoading: false,
        storeListData: action.payload,
      };
    case FETCH_STORE_LIST_FAILURE:
      return {
        ...state,
        storeListDataLoading: false,
        storeListDataError: action.payload.error,
      };

    // Store details
    case FETCH_STORE_DETAILS:
      return {
        ...state,
        storeDetailsLoading: true,
      };
    case FETCH_STORE_DETAILS_SUCCESS:
      return {
        ...state,
        storeDetailsLoading: false,
        storeDetails: action.payload,
      };
    case FETCH_STORE_DETAILS_FAILURE:
      return {
        ...state,
        storeDetailsError: action.payload.error,
      };

    case SELECT_STORE_TYPE:
      return {
        ...state,
        storeType: action.payload,
      };

    case SELECT_CURRENT_STORE:
      return {
        ...state,
        currentStoreID: action.payload.ClientStoreId,
      };
    default:
      return state;
  }
};

export default store;
