import React from "react";
import "./add-space-section-main.scss";

const AdsSpaceSectionMain = ({ adsDetails }) => {
  return (
    <>
      {adsDetails && adsDetails.ads_image ? (
        <div className="ads-space-section-department">
          <a
            href={`${adsDetails.ads_url}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={adsDetails.ads_image} alt="ads" />
          </a>

        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AdsSpaceSectionMain;
