import React from "react";

export const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow-design right-arrow-alignment"
      onClick={onClick}
    >
      <i className="fa-solid fa-right-long"></i>
    </div>
  );
};

export const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow-design left-arrow-alignment" onClick={onClick}>
      <i className="fa-solid fa-left-long"></i>
    </div>
  );
};
