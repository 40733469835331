import React from "react";
import "./text-section.scss";
import AppleLogo from "assets/images/apple-logo.jpg";
import GoogleplayLogo from "assets/images/googleplay-logo.png";
import PwaLogo from "assets/images/pwa_app_logo.png";



const TextSection = ({ mobileappData }) => {

  return (
    <>
      <div className="welcome-white-foodliners-section">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              {mobileappData &&
                <img
                  src={
                    mobileappData
                      ? mobileappData.text_section.image
                      : ""
                  }
                  alt="WhitesAboutImage"
                />
              }
            </div>
            <div className="grid-items">
              <h3 dangerouslySetInnerHTML={{
                __html: mobileappData
                  ? mobileappData.text_section.title
                  : ""
              }}>
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: mobileappData
                    ? mobileappData.text_section.sub_title1
                    : "",
                }}
              >
              </p>

              <div className="innergrid">
                {mobileappData.text_section.ios_app_url && (
                  <span>
                    <a href={mobileappData.text_section.ios_app_url} target={`_blank`}><img src={AppleLogo} alt="googleplay" /></a>
                  </span>
                )}
                {mobileappData.text_section.android_app_url && (
                  <span>
                    <a href={mobileappData.text_section.android_app_url} target={`_blank`}><img src={GoogleplayLogo} alt="ios" /></a>
                  </span>
                )}
              </div>

              <div className="app-notes">
                {(mobileappData.text_section.pwa_url !== "") ?
                  <span><a href={mobileappData.text_section.pwa_url} target={`_blank`}><img src={PwaLogo} alt="PWA" /></a></span>
                  :
                  null
                  // <img src={PwaLogo} alt="PWA" />
                }

                {(mobileappData.text_section.pwa_url !== "") ?
                  <p>
                    <h4>To install Web apps on your phone:</h4>

                    Click the PWA above from your phone,click on share (up arrow on iphone ) and click add to home screen.

                  </p>
                  :
                  null
                }
              </div>


              <div className="notes">
                <span><p
                  dangerouslySetInnerHTML={{
                    __html: mobileappData
                      ? mobileappData.text_section.sub_title2
                      : "",
                  }}
                >
                  { }
                </p>
                </span>
                <span><p
                  dangerouslySetInnerHTML={{
                    __html: mobileappData
                      ? mobileappData.text_section.sub_title3
                      : "",
                  }}
                >
                  { }
                </p>
                </span>

              </div>







            </div>



          </div>
        </div>
      </div>
    </>
  );
};

export default TextSection;
