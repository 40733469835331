import {
    FETCH_FEATURE_PRODUCT_LIST,
    FETCH_FEATURE_PRODUCT_LIST_SUCCESS,
    FETCH_FEATURE_PRODUCT_LIST_FAILURE,  
  } from "redux/constants/actionTypes";
  
  let initialState = {
    featureproductData: {},
    featureproductDataLoading: true,
    featureproductDataError: "",
  };
  
  const featureproductReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_FEATURE_PRODUCT_LIST:
        return {
          ...state,
          featureproductDataLoading: true,
        };
      case FETCH_FEATURE_PRODUCT_LIST_SUCCESS:
        return {
          ...state,
          featureproductDataLoading: false,
          featureproductData: action.payload,
        };
      case FETCH_FEATURE_PRODUCT_LIST_FAILURE:
        return {
          ...state,
          featureproductDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default featureproductReducer;
  