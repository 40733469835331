import React, { useEffect } from "react";
import "./job-providesection.scss";

const ProvideSection = ({ pageData }) => {
  useEffect(()=>{
    if(pageData.redirect_to_other_page){
      window.location.assign(pageData.redirect_to_other_page);
    }
  },[pageData]);

  return (
    <div>
      {pageData.redirect_to_other_page === "" && ( 
      <div className="provide-section-page">
        <div className="container">
          <div className="provide-section-title-page"></div>
          <div className="faq">
            <>
              {pageData.image ? (
                <div className="rewards-image">
                  <img
                    src={pageData.image}
                    alt="rewardimg"
                    style={{ maxWidth: "300px" }}
                  />
                </div>
              ) : (
                ""
              )}
              <h1>{pageData.title}</h1>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageData ? pageData.description : "",
                }}
              ></span>
            </>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};
export default ProvideSection;
