import {
  FETCH_BANNER_AND_TESTIMONIALS_DATA,
  FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS,
  FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE,
  FETCH_OFFER_LIST_WITH_PRODUCTS,
  FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS,
  FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE,
  FETCH_PREVIOUS_BUYS_PRODUCT_LIST,
  FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS,
  FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCTS_DATA,
  FETCH_PRODUCTS_DATA_SUCCESS,
  FETCH_PRODUCTS_DATA_FAILURE,
  FETCH_HOME_PAGE_COUPONS_DATA,
  FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS,
  FETCH_HOME_PAGE_COUPONS_DATA_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch banner and testimonials data
export const fetchBannerAndTestimonialsData = (payload) => {
  return {
    type: FETCH_BANNER_AND_TESTIMONIALS_DATA,
    payload: payload,
  };
};
export const fetchBannerAndTestimonialsDataSuccess = (payload) => {
  return {
    type: FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS,
    payload: payload
  };
};
export const fetchBannerAndTestimonialsDataFailure = (payload) => {
  return {
    type: FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE,
    payload: payload,
  };
};

export const getBannerAndTestimonialsData = (body) => async (dispatch) => {
  dispatch(fetchBannerAndTestimonialsData());

  axios
    .post("/AogGetBannerAndTestimonials", body)
    .then((res) => {
      dispatch(fetchBannerAndTestimonialsDataSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchBannerAndTestimonialsDataFailure({ error: error.data.message })
      );
    });
};

// fetch Offers With Product data
export const fetchOffersWithProductData = (payload) => {
  return {
    type: FETCH_OFFER_LIST_WITH_PRODUCTS,
    payload: payload,
  };
};
export const fetchOffersWithProductDataSuccess = (payload,campaing_data) => {
  return {
    type: FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS,
    payload: payload,
    campaing_data: campaing_data,
  };
};
export const fetchOffersWithProductDataFailure = (payload) => {
  return {
    type: FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE,
    payload: payload,
  };
};

export const getOffersWithProductData = (body) => async (dispatch) => {
  dispatch(fetchOffersWithProductData());

  axios
    .post("/AogGetHomePageProducts", body)
    .then((res) => {
      dispatch(fetchOffersWithProductDataSuccess(res.data.data,res.data.campaing_data));
    })
    .catch((error) => {
      dispatch(
        fetchOffersWithProductDataFailure({ error: error.data.message })
      );
    });
};

// fetch Previous products data

export const fetchPreviousProductDetails = (payload) => {
  return {
    type: FETCH_PREVIOUS_BUYS_PRODUCT_LIST,
    payload: payload,
  };
};
export const fetchPreviousProductDetailsSuccess = (payload) => {
  return {
    type: FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchPreviousProductDetailsFailure = (payload) => {
  return {
    type: FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE,
    payload: payload,
  };
};

export const getPreviosProductDetails = (body) => async (dispatch) => {
  dispatch(fetchPreviousProductDetails());

  axios
    .post("/AogGetRecentlyPurchaseProducts", body)
    .then((res) => {
      dispatch(fetchPreviousProductDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchPreviousProductDetailsFailure({ error: error.data.message })
      );
    });
};

// fetch Product data
export const fetchProductData = (payload) => {
  return {
    type: FETCH_PRODUCTS_DATA,
    payload: payload,
  };
};
export const fetchProductDataSuccess = (payload) => {
  return {
    type: FETCH_PRODUCTS_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchProductDataFailure = (payload) => {
  return {
    type: FETCH_PRODUCTS_DATA_FAILURE,
    payload: payload,
  };
};

export const getProductList = (body) => async (dispatch) => {
  dispatch(fetchProductData());

  axios
    .post("/AogGetProducts", body)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch(fetchProductDataSuccess(res.data.data));
      } else {
        dispatch(fetchProductDataSuccess([]));
      }
    })
    .catch((error) => {
      dispatch(fetchProductDataFailure({ error: error.data.message }));
    });
};
// fetch home page coupons data
export const fetchHomePageCouponsData = (payload) => {
  return {
    type: FETCH_HOME_PAGE_COUPONS_DATA,
    payload: payload,
  };
};
export const fetchHomePageCouponsDataSuccess = (payload) => {
  return {
    type: FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchHomePageCouponsDataFailure = (payload) => {
  return {
    type: FETCH_HOME_PAGE_COUPONS_DATA_FAILURE,
    payload: payload,
  };
};

export const getHomePageCouponsData = (body) => async (dispatch) => {
  dispatch(fetchHomePageCouponsData());

  axios
    .post("/AogGetHomePageCoupons", body)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch(fetchHomePageCouponsDataSuccess(res.data.data));
      } else {
        dispatch(fetchHomePageCouponsDataSuccess([]));
      }
    })
    .catch((error) => {
      dispatch(fetchHomePageCouponsDataFailure({ error: error.data.message }));
    });
};
