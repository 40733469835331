import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./new-sale-products.scss";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import Slider from "react-slick/lib/slider";
import ProductCardTheme2 from "components/ProductCardTheme2";
import { Link } from "react-router-dom";

const WhatOnSaleProducts = () => {
  const offersWhatsOnSalesProductData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );

  const [whatsOnSalesProductSlug, setWhatsOnSalesProductsSlug] =
    useState("whats-on-sale");

  const whatsOnSalesProductList =
    offersWhatsOnSalesProductData?.find(
      (offer) => offer.section_slug === "whats-on-sale"
    )?.products_list || [];

  const whatsOnSalesProductsTitle =
    offersWhatsOnSalesProductData?.find(
      (offer) => offer.section_slug === "whats-on-sale"
    )?.title || "";

  useEffect(() => {
    offersWhatsOnSalesProductData?.length &&
      setWhatsOnSalesProductsSlug(whatsOnSalesProductSlug);
  }, [offersWhatsOnSalesProductData]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: Math.min(featureproductData?.products_list.length, 6),
    slidesToShow: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="new-sale-product-theme2">
        <div className="container">
          <div className="container-fluid">
            {whatsOnSalesProductList?.length > 0 && (
              <div className="whats-on-sale-product">
                <div className="heading">
                  <h2>{whatsOnSalesProductsTitle}</h2>
                  {/* <Link to="/departments/from-our-weekly-ads">
                  View All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                    />
                  </svg>
                </Link> */}
                </div>
              </div>
            )}

            <div className="product-row deal-all-wrapper">
              <div className="new-sale-all-wrapper">
                <Slider {...settings}>
                  {whatsOnSalesProductList.slice(0, 6).map((product) => {
                    return (
                      <ProductCardTheme2 product={product} key={product.id} />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatOnSaleProducts;
