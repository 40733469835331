import {
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS,
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS,
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE,
  FETCH_CART_LIST,
  FETCH_CART_LIST_SUCCESS,
  FETCH_CART_LIST_FAILURE,
  ADD_PRODUCT_TO_CART_LIST,
  ADD_PRODUCT_TO_CART_LIST_SUCCESS,
  ADD_PRODUCT_TO_CART_LIST_FAILURE,
  REMOVE_PRODUCT_TO_CART_LIST,
  REMOVE_PRODUCT_TO_CART_LIST_SUCCESS,
  REMOVE_PRODUCT_TO_CART_LIST_FAILURE,
  UPDATE_PRODUCT_TO_CART_LIST,
  UPDATE_PRODUCT_TO_CART_LIST_SUCCESS,
  UPDATE_PRODUCT_TO_CART_LIST_FAILURE,
  CLEAR_CART_LIST,
  CLEAR_CART_LIST_SUCCESS,
  CLEAR_CART_LIST_FAILURE,
  ADD_WISHLIST_DATA,
  ADD_WISHLIST_DATA_SUCCESS,
  ADD_WISHLIST_DATA_FAILURE,

  //catering and meal kit product
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
  RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE,
  CLEAR_CATERING_CART_LIST,
  CLEAR_CATERING_CART_LIST_SUCCESS,
  CLEAR_CATERING_CART_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
} from "redux/constants/actionTypes";
import axios from "config/axios";

// fetch shopping cart data
export const fetchCartWishlistDetailsWithItems = (payload) => {
  return {
    type: FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS,
    payload: payload,
  };
};
export const fetchCartWishlistDetailsWithItemsSuccess = (payload) => {
  return {
    type: FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS,
    payload: payload,
  };
};
export const fetchCartWishlistDetailsWithItemsFailure = (payload) => {
  return {
    type: FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE,
    payload: payload,
  };
};

export const getCartWishlistDetailsWithItems = (body) => async (dispatch) => {
  dispatch(fetchCartWishlistDetailsWithItems());

  axios
    .post("/AogGetCountCartAndWishlistItems", body)
    .then((res) => {
      dispatch(fetchCartWishlistDetailsWithItemsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchCartWishlistDetailsWithItemsFailure({ error: error.data.message })
      );
    });
};

// fetch cart list
export const fetchCartList = (payload) => {
  return {
    type: FETCH_CART_LIST,
    payload: payload,
  };
};
export const fetchCartListSuccess = (payload, cartDetails) => {
  return {
    type: FETCH_CART_LIST_SUCCESS,
    payload: payload,
    cartDetails: cartDetails,
  };
};
export const fetchCartListFailure = (payload) => {
  return {
    type: FETCH_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const getCartList = (body) => async (dispatch) => {
  dispatch(fetchCartList());
  axios
    .post("/AogGetCart", body)
    .then((res) => {
      dispatch(fetchCartListSuccess(res.data.data.items, res.data));
    })
    .catch((error) => {
      dispatch(fetchCartListFailure({ error: error.data.message }));
    });
};

//  add to cart list

export const addProductToCartList = (payload) => {
  return {
    type: ADD_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const addProductToCartListSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const addProductToCartListFailure = (payload) => {
  return {
    type: ADD_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const addToCart = (body) => async (dispatch) => {
  dispatch(addProductToCartList());

  return axios
    .post("/AogAddToCart", body)
    .then((res) => {
      dispatch(addProductToCartListSuccess(res.data.item));
    })
    .catch((error) => {
      dispatch(addProductToCartListFailure({ error: error.data.message }));
    });
};
// remove to cart list

export const removeProductToCartList = (payload) => {
  return {
    type: REMOVE_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const removeProductToCartListSuccess = (payload) => {
  return {
    type: REMOVE_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const removeProductToCartListFailure = (payload) => {
  return {
    type: REMOVE_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const getRemoveProductToCartList = (body) => async (dispatch) => {
  dispatch(removeProductToCartList(body.item_id));

  axios
    .post("/AogRemoveItemFromCart", body)
    .then((res) => {
      dispatch(removeProductToCartListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(removeProductToCartListFailure({ error: error.data.message }));
    });
};

// edit to cart list

export const updateProductToCartList = (payload) => {
  return {
    type: UPDATE_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const updateProductToCartListSuccess = (payload) => {
  return {
    type: UPDATE_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const updateProductToCartListFailure = (payload) => {
  return {
    type: UPDATE_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const editProductToCartList = (body) => async (dispatch) => {
  dispatch(updateProductToCartList(body.item_id));

  axios
    .post("/AogCartItemEdit", body)
    .then((res) => {
      dispatch(updateProductToCartListSuccess(res.data.item));
    })
    .catch((error) => {
      dispatch(updateProductToCartListFailure({ error: error.data.message }));
    });
};

// clear cart list

export const clearAllProductToCartList = (payload) => {
  return {
    type: CLEAR_CART_LIST,
    payload: payload,
  };
};
export const clearAllProductToCartListSuccess = (payload) => {
  return {
    type: CLEAR_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const clearAllProductToCartListFailure = (payload) => {
  return {
    type: CLEAR_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const clearCartList = (body) => async (dispatch) => {
  dispatch(clearAllProductToCartList());

  axios
    .post("/AogClearCart", body)
    .then((res) => {
      dispatch(clearAllProductToCartListSuccess());
    })
    .catch((error) => {
      dispatch(clearAllProductToCartListFailure({ error: error.data.message }));
    });
};

//  add to Wishlist

export const addProductToWishList = (payload) => {
  return {
    type: ADD_WISHLIST_DATA,
    payload: payload,
  };
};
export const addProductToWishListSuccess = (payload) => {
  return {
    type: ADD_WISHLIST_DATA_SUCCESS,
    payload: payload,
  };
};
export const addProductToWishListFailure = (payload) => {
  return {
    type: ADD_WISHLIST_DATA_FAILURE,
    payload: payload,
  };
};

export const addToWishlist = (body) => async (dispatch) => {
  dispatch(addProductToWishList());

  return axios
    .post("/AogAddToWishlist", body)
    .then((res) => {
      dispatch(addProductToWishListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(addProductToWishListFailure({ error: error.data.message }));
    });
};

//catering nad meal kit add product

//  add to cart list

export const addCateringAndMealKitProductToCartList = (payload) => {
  return {
    type: ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const addCateringAndMealKitProductToCartListSuccess = (payload) => {
  return {
    type: ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const addCateringAndMealKitProductToCartListFailure = (payload) => {
  return {
    type: ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const addToCartCateringAndMealKitItem = (body) => async (dispatch) => {
  dispatch(addCateringAndMealKitProductToCartList());

  return axios
    .post("/AddToCartCateringAndMealkitItem", body)
    .then((res) => {
      if (res.data.status !== "error") {
        dispatch(addCateringAndMealKitProductToCartListSuccess(res.data.item));
      } else {
        dispatch(
          addCateringAndMealKitProductToCartListFailure({
            error: res.data.message,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(
        addCateringAndMealKitProductToCartListFailure({
          error: error.data.message,
        })
      );
    });
};

export const resetAddCateringAndMealKitProductToCartError = (payload) => {
  return {
    type: RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR,
    payload: payload,
  };
};

// Remove catering item from cart

export const removeCateringProductToCartList = (payload) => {
  return {
    type: REMOVE_CATERING_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const removeCateringProductToCartListSuccess = (payload) => {
  return {
    type: REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const removeCateringProductToCartListFailure = (payload) => {
  return {
    type: REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const getRemoveCateringProductToCartList =
  (body) => async (dispatch) => {
    dispatch(removeCateringProductToCartList());

    axios
      .post("/RemoveCateringAndMealKitItemInCart", body)
      .then((res) => {
        dispatch(removeCateringProductToCartListSuccess(body.meal_item_id));
      })
      .catch((error) => {
        dispatch(
          removeCateringProductToCartListFailure({ error: error.data.message })
        );
      });
  };
// clear items from catering cart list

export const clearAllCateringProductToCartList = (payload) => {
  return {
    type: CLEAR_CATERING_CART_LIST,
    payload: payload,
  };
};
export const clearAllCateringProductToCartListSuccess = (payload) => {
  return {
    type: CLEAR_CATERING_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const clearAllCateringProductToCartListFailure = (payload) => {
  return {
    type: CLEAR_CATERING_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const clearCateringCartList = (body) => async (dispatch) => {
  dispatch(clearAllCateringProductToCartList());

  axios
    .post("/ClearCateringAndMealKitItemCart", body)
    .then((res) => {
      dispatch(clearAllCateringProductToCartListSuccess());
    })
    .catch((error) => {
      dispatch(
        clearAllCateringProductToCartListFailure({ error: error.data.message })
      );
    });
};

//update catering and meal product
export const updateCateringAndMealKitProductToCartList = (payload) => {
  return {
    type: UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
    payload: payload,
  };
};
export const updateCateringAndMealKitProductToCartListSuccess = (payload) => {
  return {
    type: UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
    payload: payload,
  };
};
export const updateCateringAndMealKitProductToCartListFailure = (payload) => {
  return {
    type: UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
    payload: payload,
  };
};

export const updateToCartCateringAndMealKitItem = (body) => async (dispatch) => {
  dispatch(updateCateringAndMealKitProductToCartList(body.product_id));

  return axios
    .post("/UpdateCateringAndMealkitItemInCart", body)
    .then((res) => {
      dispatch(updateCateringAndMealKitProductToCartListSuccess(res.data.item));
    })
    .catch((error) => {
      dispatch(updateCateringAndMealKitProductToCartListFailure({ error: error.data.message }));
    });
};