import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { getAwardDetails, resetSearch } from "redux/actions";
import AwardCard from "./awards-card";
import { getLocalStorageValue } from 'config/helper';

const Award = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
    };
    dispatch(getAwardDetails(body));
  }, [dispatch]); // eslint-disable-line

  const awardData = useSelector(({ aboutus }) => aboutus.awardData);

  const breadcrumbList = [{ lable: "Awards", link: "", searchTag: "" }];

  return (
    <div>
      {awardData && awardData.data && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title="Awards" //Need to pass title through api res
          />
          {awardData.data.map((awardData) => {
            return (
              <AwardCard awardData={awardData} key={awardData.awards_id} />
            );
          })}
        </>
      )}
    </div>
  );
};
export default Award;
