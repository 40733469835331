import React from "react";
import FeatureProductSlider from "./FeatureProductSlider";

const BestSeller = ({ departmentproductData }) => {
   return (
    <>
      <section className="bestseller-slider">
        <div className="container">
          {departmentproductData.map((product, index) => {
            return (
              <FeatureProductSlider product={product} key={index}/>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default BestSeller;
