import React from "react";
import Slider from "react-slick/lib/slider";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import RecipesCard from "components/RecipesPage/RecipesCard";
import "./recipes-similar-product.scss";

const RecipesSimilarProduct = ({ products }) => {
  const settings = {
    dots: false,
    infinite: products.length > 5,
    slidesToShow: products.length > 5 ? 5 : products.length,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: products.length > 5,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: products.length > 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: products.length > 5,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: products.length > 5,
          dots: false,
          draggable: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="recipes-related-product-section">
        <div className="container">
          <div>
            <h1>Similar Recipes</h1>
          </div>
          <div className="recipes-product-list-wrapper">
            <Slider {...settings}>
              {products.map((product) => {
                return <RecipesCard product={product} isShowButton={false} />;
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecipesSimilarProduct;
