import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { addDonation } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const DonationForm = ({ headerSectionData }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const storeList = useSelector(({ store }) => store.storeListData);
  const clientId = getLocalStorageValue("RSAclient-id");

  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const AogSaveDonationApiUrl = commonDetailsData.header_section.AogApiURL;

  const optionList = [
    { title: "Yes", value: "Yes" },
    { title: "No", value: "No" },
  ];

  const [selectedStoreLocation, setSelectedStoreLocation] = useState();
  const [previousDonation, setPreviousDonation] = useState("");
  const [country] = useState("USA");

  const handleSelectStoreLocation = (_, optionName) => {
    setSelectedStoreLocation(optionName);
  };

  const handleSelectPreviousonation = (_, optionName) => {
    setPreviousDonation(optionName);
  };

  const { donationMessage, donationStatus } = useSelector(
    ({ donation }) => donation
  );

  const handleSubmitdonationForm = (data, e) => {
    const {
      selectStore,
      event_date,
      org_name,
      title,
      Adress,
      City,
      State,
      Zipcode,
      Country,
      emailId,
      PhoneNo,
      fax,
      about_organization,
      event_mission_location_text,
      why_text,
      requested_items,
      recognized_text,
      number_of_people,
      proximity,
      tax_id_number,
      prevoiusly_recieved_donation,
      org_achievement,
      primary_contact_name,
      irs_tax,
    } = data;

    // New object variable "AogSaveDonationApiUrl" is added - 10/10/2023 - KS

    const body = {
      RSAClientId: clientId,
      ClientStoreId: selectStore,
      today_date: new Date(new Date().getTime()).toISOString().split("T")[0],
      event_date: event_date,
      org_name: org_name,
      title: title,
      Adress: Adress,
      City: City,
      primary_contact_name: primary_contact_name,
      State: State,
      Zipcode: Zipcode,
      Country: Country,
      emailId: emailId,
      PhoneNo: PhoneNo,
      fax: fax,
      about_organization: about_organization,
      event_mission_location_text: event_mission_location_text,
      why_text: why_text,
      requested_items: requested_items,
      recognized_text: recognized_text,
      prevoiusly_recieved_donation: prevoiusly_recieved_donation,
      number_of_people: number_of_people,
      proximity: proximity,
      tax_id_number: tax_id_number,
      org_achievement: org_achievement,
      AogSaveDonationApiUrl: AogSaveDonationApiUrl,
      irs_tax_image: irs_tax[0] ? irs_tax[0] : null,
    };

    dispatch(addDonation(body)).then(() => {
      setTimeout(() => e.target.reset(), 1500);
    });
  };

  return (
    <>
      <div>
        <div className="container">
          <form
            name="employemnt"
            onSubmit={handleSubmit(handleSubmitdonationForm)}
          >
            <div className="donation-form-section">
              <div className="donation-two-col-grid">
                <div className="form-control">
                  <label>
                    Store Location<span>*</span>
                  </label>

                  <select
                    value={selectedStoreLocation}
                    name="selectStore"
                    onChange={handleSelectStoreLocation}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {storeList.length > 0 &&
                      storeList.map((store) => {
                        return (
                          <option
                            value={store.ClientStoreId}
                            className="store-option"
                          >
                            {store.ClientStoreName}
                          </option>
                        );
                      })}
                  </select>
                  {errors.selectStore &&
                    errors.selectStore.type === "required" && (
                      <p>Please Select Store.</p>
                    )}
                </div>

                <div className="form-control">
                  <label>
                    Event Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="event_date"
                    id="event_date"
                    ref={register({ required: true })}
                  />
                  {errors.event_date &&
                    errors.event_date.type === "required" && (
                      <p>Please enter event date</p>
                    )}
                </div>
              </div>

              <div className="donation-two-col-grid"></div>

              <div className="donation-three-col-grid">
                <div className="form-control">
                  <label>
                    Organization Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="org_name"
                    ref={register({
                      required: true,
                      pattern: /^[A-Za-z]/,
                    })}
                  />
                  {errors.org_name && errors.org_name.type === "required" && (
                    <p>Please enter Organization Name</p>
                  )}
                  {errors.org_name && errors.org_name.type === "pattern" && (
                    <p>Please enter valid Organization Name</p>
                  )}
                </div>

                <div className="form-control">
                  <label>
                    Primary Contact Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="primary_contact_name"
                    ref={register({ required: true, pattern: /^[A-Za-z]/ })}
                  />
                  {errors.primary_contact_name &&
                    errors.primary_contact_name.type === "required" && (
                      <p>Please enter Primary Contact Name</p>
                    )}
                  {errors.primary_contact_name &&
                    errors.primary_contact_name.type === "pattern" && (
                      <p>Please enter valid Primary Contact Name</p>
                    )}
                </div>

                <div className="form-control">
                  <label>
                    Title<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    ref={register({
                      required: true,
                    })}
                  />

                  {errors.title && errors.title.type === "required" && (
                    <p>Please enter your Title</p>
                  )}
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div className="form-control">
                  <label>
                    Street Address<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="Adress"
                    id="Adress"
                    ref={register({ required: true })}
                  />
                  {errors.Adress && errors.Adress.type === "required" && (
                    <p>Please enter your Address</p>
                  )}
                </div>

                <div className="form-control">
                  <label>
                    City<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="City"
                    id="City"
                    ref={register({ required: true })}
                  />
                  {errors.City && errors.City.type === "required" && (
                    <p>Please enter City Name</p>
                  )}
                </div>
              </div>
              <div className="donation-three-col-grid">
                <div className="form-control">
                  <label>
                    State / Province / Region<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="State"
                    id="State"
                    ref={register({ required: true })}
                  />
                  {errors.State && errors.State.type === "required" && (
                    <p>Please enter State</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Zip Code<span>*</span>
                  </label>
                  <input
                    type="number"
                    name="Zipcode"
                    id="Zipcode"
                    ref={register({ required: true, pattern: /^[0-9]{5}$/ })}
                  />
                  {errors.Zipcode && errors.Zipcode.type === "required" && (
                    <p>Please enter Zipcode</p>
                  )}
                  {errors.Zipcode && errors.Zipcode.type === "pattern" && (
                    <p>Please enter valid Zipcode</p>
                  )}
                </div>

                <div className="form-control">
                  <label>
                    Country<span>*</span>
                  </label>

                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    defaultValue={country}
                    ref={register({ required: true })}
                  />
                </div>
              </div>

              <div className="donation-three-col-grid">
                <div className="form-control">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="emailId"
                    id="emailId"
                    ref={register({
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    })}
                  />

                  {errors.emailId && errors.emailId.type === "required" && (
                    <p>Please enter Email Id</p>
                  )}
                  {errors.emailId && errors.emailId.type === "pattern" && (
                    <p>Please enter valid Email Id</p>
                  )}
                </div>

                <div className="form-control">
                  <label>
                    Phone No<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="PhoneNo"
                    id="PhoneNo"
                    ref={register({
                      required: true,
                      pattern: /^[0-9]{10}$/,
                    })}
                  />

                  {errors.PhoneNo && errors.PhoneNo.type === "required" && (
                    <p>Please enter your Phone No</p>
                  )}
                  {errors.PhoneNo && errors.PhoneNo.type === "pattern" && (
                    <p>Please enter valid Phone No</p>
                  )}
                </div>
                <div className="form-control">
                  <label>Fax</label>
                  <input type="text" name="fax" id="fax" ref={register()} />
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div>
                  <div className="form-control leaving-text">
                    <label>
                      Tell Us About Your Organization <span>*</span>
                    </label>
                    <textarea
                      name="about_organization"
                      id="about_organization"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>
                    {errors.about_organization &&
                      errors.about_organization.type === "required" && (
                        <p>Please enter description</p>
                      )}
                  </div>
                </div>

                <div>
                  <div className="form-control leaving-text">
                    <label>
                      Organization Achievement & Impact on the Community{" "}
                      <span>*</span>
                    </label>
                    <textarea
                      name="org_achievement"
                      id="org_achievement"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>

                    {errors.org_achievement &&
                      errors.org_achievement.type === "required" && (
                        <p>Please enter description</p>
                      )}

                    <span className="hint-text">
                      What has your Organization achieved in the last year and
                      what was it's impact on the community?
                    </span>
                  </div>
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div>
                  <div className="form-control leaving-text">
                    <label>
                      Event Name, Date, Location & Mission <span>*</span>
                    </label>
                    <textarea
                      name="event_mission_location_text"
                      id="event_mission_location_text"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>

                    {errors.event_mission_location_text &&
                      errors.event_mission_location_text.type ===
                      "required" && <p>Please enter description</p>}

                    <span className="hint-text">
                      Only events that directly benefit community residents will
                      be considered.
                    </span>
                  </div>
                </div>

                <div>
                  <div className="form-control leaving-text">
                    <label>
                      Why {headerSectionData.client_name}?<span>*</span>
                    </label>
                    <textarea
                      name="why_text"
                      id="why_text"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>

                    {errors.why_text && errors.why_text.type === "required" && (
                      <p>Please enter description</p>
                    )}

                    <span className="hint-text">
                      Reason why {headerSectionData.client_name} Fresh is a good
                      fit for your cause.
                    </span>
                  </div>
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div>
                  <div className="form-control leaving-text">
                    <label>
                      Items Requested? <span>*</span>
                    </label>

                    <textarea
                      name="requested_items"
                      id="requested_items"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>

                    {errors.requested_items &&
                      errors.requested_items.type === "required" && (
                        <p>Please enter items requested</p>
                      )}

                    <span className="hint-text">
                      You are applying for a donation not a sponsorship,
                      Typically we donate grocery items from our stores. Please
                      list the items and quantities your organization is
                      requesting.
                    </span>
                  </div>
                </div>

                <div>
                  <div className="form-control leaving-text">
                    <label>
                      How will {headerSectionData.client_name} be Recognized?{" "}
                      <span>*</span>
                    </label>
                    <textarea
                      name="recognized_text"
                      id="recognized_text"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>

                    {errors.recognized_text &&
                      errors.recognized_text.type === "required" && (
                        <p>Please enter the description</p>
                      )}

                    <span className="hint-text">
                      Will our logo will be displayed?
                    </span>
                  </div>
                </div>
              </div>

              <div className="donation-sec-col-grid">
                <div className="form-control">
                  <label>
                    Hav your Organization recieved a donation from{" "}
                    {headerSectionData.client_name} in the past?
                    <br /> (Yes or No) <span>*</span>
                  </label>

                  <select
                    value={previousDonation}
                    name="prevoiusly_recieved_donation"
                    className="form-control show-items dropdown-align select-dropdown"
                    onChange={handleSelectPreviousonation}
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {optionList.length > 0 &&
                      optionList.map((option) => {
                        return (
                          <option value={option.value}>{option.title}</option>
                        );
                      })}
                  </select>
                  {errors.prevoiusly_recieved_donation &&
                    errors.prevoiusly_recieved_donation.type === "required" && (
                      <p>Please Select Yes/No</p>
                    )}
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div className="form-control">
                  <label>
                    Number of People impacted<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="number_of_people"
                    id="number_of_people"
                    ref={register({ required: true })}
                  />
                  {errors.number_of_people &&
                    errors.number_of_people.type === "required" && (
                      <p>Please enter number of people</p>
                    )}
                  <span className="hint-text">
                    How many people do you expect to attend the event?
                  </span>
                </div>

                <div className="form-control">
                  <label>
                    Proximity to {headerSectionData.client_name} store
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="proximity"
                    id="proximity"
                    ref={register({ required: true })}
                  />

                  {errors.proximity && errors.proximity.type === "required" && (
                    <p>Please enter approximate distance</p>
                  )}
                  <span className="hint-text">
                    How many mile is your event form the nearest{" "}
                    {headerSectionData.client_name} store.
                  </span>
                </div>
              </div>

              <div className="donation-two-col-grid">
                <div className="form-control">
                  <label>
                    Tax Id Number<span>*</span>
                  </label>

                  <input
                    type="text"
                    name="tax_id_number"
                    id="tax_id_number"
                    ref={register({ required: true })}
                  />

                  {errors.tax_id_number &&
                    errors.tax_id_number.type === "required" && (
                      <p>Please enter Tax Id Number</p>
                    )}

                  <span className="hint-text">
                    Organization 501(c)3 tax identification number.
                  </span>
                </div>

                <div className="form-control">
                  <label>
                    Current copy of 501(c)(3) form (IRS tax exempt letter)
                    <span>*</span>
                  </label>
                  <input
                    type="file"
                    name="irs_tax"
                    id="irs_tax"
                    ref={register({ required: true })}
                    accept="image/*,.doc,.docx,.pdf,"
                  />

                  {errors.irs_tax && errors.irs_tax.type === "required" && (
                    <p>Please enter IRS tax exempt letter</p>
                  )}
                  <span className="hint-text">
                    Application will not be considered without a current IRS tax
                    exempt letter.
                  </span>
                </div>
              </div>

              {donationStatus ? (
                <span
                  className={
                    donationStatus === "success"
                      ? "donation-success"
                      : "donation-error"
                  }
                >
                  {donationMessage}
                </span>
              ) : (
                ""
              )}

              <div className="donation-form-submit-button">
                <button type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default DonationForm;
