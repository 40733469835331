import {
  FETCH_COMMON_DETAILS,
  FETCH_COMMON_DETAILS_SUCCESS,
  FETCH_COMMON_DETAILS_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  commonDetailsData: {},
  commonDetailsDataLoading: true,
  commonDetailsDataError: "",
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMON_DETAILS:
      return {
        ...state,
        commonDetailsDataLoading: true,
      };
    case FETCH_COMMON_DETAILS_SUCCESS:
      return {
        ...state,
        commonDetailsDataLoading: false,
        commonDetailsData: action.payload,
      };
    case FETCH_COMMON_DETAILS_FAILURE:
      return {
        ...state,
        commonDetailsDataError: action.payload.error,
      };

    default:
      return state;
  }
};

export default commonReducer;
