import React from "react";
import { useState } from "react";

import "./back-to-top.scss";

const BackToTop = () => {
    const [isShowButton, setIsShowButton] = useState(false);

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            setIsShowButton(true);
        } else {
            setIsShowButton(false);
        }
    }

    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
            <button
                type="button"
                className={
                    isShowButton
                        ? "btn btn-danger btn-floating btn-lg show"
                        : "btn btn-danger btn-floating btn-lg hide"
                }
                id="btn-back-to-top"
                onClick={backToTop}
            >
                <i className="fas fa-arrow-up"></i>
            </button>
        </>
    );
};

export default BackToTop;