import React, { useState } from "react";
import { useRef } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";
import { Link } from "react-router-dom";
import DownMenu from "assets/icons/menu-down.svg";

import "./departmentmenu-bar.scss";

const Departmentmenu = ({ menuName, couponDepartmentmenu }) => {
  const [isShowShopByDepartment, setIsShowShopByDepartment] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsShowShopByDepartment(false));

  return (
    <div className="shop-by-department" ref={ref}>
      <button
        className="invert-button"
        onClick={() => setIsShowShopByDepartment(!isShowShopByDepartment)}
      >
        <span>{menuName}</span>
        <img src={DownMenu} alt="DownMenu" />
      </button>
      <div
        className={
          isShowShopByDepartment
            ? "shop-by-department-menu shop-by-department-menu-show"
            : "shop-by-department-menu shop-by-department-menu-hidden"
        }
      >
        <div className="shop-by-department-menu-content">
          <div className="grid">
            {couponDepartmentmenu &&
              couponDepartmentmenu.length > 0 &&
              couponDepartmentmenu.map((el) => {
                return (
                  <Link
                    to={
                      el.new_page_slug !== ""
                        ? `/custom-pages/${el.new_page_slug}`
                        : `/coupon-department/${el.department_name_slug}`
                    }
                    key={el.department_id}
                    onClick={() =>
                      setIsShowShopByDepartment(!isShowShopByDepartment)
                    }
                  >
                    <div className="grid-items">
                      <div className="department-icon">
                        <img
                          src={el.department_image_url}
                          alt={"departmentimage"}
                        />
                      </div>
                      <div className="department-name">
                        <p>{el.department_name}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departmentmenu;
