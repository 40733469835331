import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import Feedback from "./FeedBack";
import Location from "./Location";
import Details from "./Details";
import { getLocalStorageValue } from 'config/helper';
import { getContactReasonList, resetSearch } from "redux/actions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const storeList = useSelector(({ store }) => store.storeListData);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const contactReasonList = useSelector(
    ({ contactus }) => contactus.contactReasonList
  );
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const breadcrumbList = [{ lable: "Locations", link: "", searchTag: "" }];

  useEffect(() => {
    dispatch(resetSearch());
  }, []);// eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    !contactReasonList.length && dispatch(getContactReasonList(body));
  }, [dispatch, storeId]); // eslint-disable-line

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Locations"} //Need to pass title through api res
      />

      <Location storeList={storeList} />
      <Details />
      <Feedback storeList={storeList} />
    </>
  );
};
export default ContactUs;
