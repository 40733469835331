import {
    FETCH_FOOTER_HELP_DETAILS,
    FETCH_FOOTER_HELP_DETAILS_SUCCESS,
    FETCH_FOOTER_HELP_DETAILS_FAILURE
    
  } from "redux/constants/actionTypes";
  
  let initialState = {
    footerhelpPageData: {},
    footerhelpPageDataLoading: true,
    footerhelpPageDataError: "",
  };
  
  const footerReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_FOOTER_HELP_DETAILS:
        return {
          ...state,
          footerhelpPageDataLoading: false,
        };
      case FETCH_FOOTER_HELP_DETAILS_SUCCESS:
        return {
          ...state,
          aboutusDataLoading: false,
          footerhelpPageData: action.payload,
        };
      case FETCH_FOOTER_HELP_DETAILS_FAILURE:
        return {
          ...state,
          footerhelpPageDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default footerReducer;
  