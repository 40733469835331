import React from "react";
import "./historywrp-right.scss";

const historyWrapright = ({ historyData }) => {
  
  return (
    <>
           <div className="content">
            
              {!!historyData.image?
               <img
                src={
                  historyData
                    ? historyData.image
                    : ""
                }
                alt="AboutImage"
                className="right-side"
              />
         :''}
           <h1>
                {historyData
                  ? historyData.history_title
                  : ""}
              </h1>

              <p
                dangerouslySetInnerHTML={{
                  __html: historyData
                    ? historyData.short_description
                    : "",
                }}
              >
                {}
              </p>
           
         
          </div>
       
    </>
  );
};

export default historyWrapright;
