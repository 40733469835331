import React, { useEffect } from "react";
import Breadcrumb from "components/Common/Breadcrumb";
import RecipeProductInfo from "./RecipeProductInfo";
import RecipeProductPreview from "./RecipeProductPreview";
import { getRecipeDetail, getSimilarRecipeList } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import RecipesSimilarProduct from "./RecipesSimilarProduct";

const RecipeProductDescription = () => {
  const dispatch = useDispatch();
  const { recipeId } = useParams();
  const [searchParams] = useSearchParams();
  const includeNutrition = searchParams.get("includeNutrition");

  const { recipeDetail, similarRecipeList } = useSelector(
    ({ recipes }) => recipes
  );
  const breadcrumbList = [
    {
      lable: "Department",
      link: ``,
      searchTag: "",
    },
    {
      lable: recipeDetail ? recipeDetail.title : "",
      link: "",
      searchTag: "",
    },
  ];

  useEffect(() => {
    recipeId && dispatch(getRecipeDetail(recipeId, includeNutrition));
  }, [dispatch, recipeId, includeNutrition]); // eslint-disable-line

  useEffect(() => {
    recipeId && dispatch(getSimilarRecipeList(recipeId));
  }, [dispatch, recipeId]); // eslint-disable-line

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />

      {recipeDetail && (
        <>
          <RecipeProductPreview productDetails={recipeDetail} />
          <RecipeProductInfo productDetails={recipeDetail} />
        </>
      )}
      {!!similarRecipeList.length && (
        <RecipesSimilarProduct products={similarRecipeList} />

      )}
    </>
  );
};
export default RecipeProductDescription;
