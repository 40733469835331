import React from "react";
import ListProduct from "./ListProduct";
import "./list-items.scss";

const ListItems = ({ customerListDetails, list_id, isCompletedGrid }) => {
  // const customerListDetails = [
  //   {
  //     "item_id":1,
  //     "image":"",
  //     "name":"product 1",
  //     "final_price":"2.99",
  //     "final_total":"2.99"
  //   },
  //   {
  //     "item_id":2,
  //     "image":"",
  //     "name":"product 2",
  //     "final_price":"3.99",
  //     "final_total":"3.99"
  //   },
  //   {
  //     "item_id":3,
  //     "image":"",
  //     "name":"product 3",
  //     "final_price":"4.99",
  //     "final_total":"4.99"
  //   },
  // ]

  return (
    <>
      <div className="custom-list-items-table">
        <div className="custom-list-table-header-align">
          <div className="first-title">
            <input type="checkbox" id="termsandconditions" />
            <label htmlFor="termsandconditions">
              <span>Products</span>
            </label>
          </div>
          <div className="child-all-title">
            <div className="child-title">
              <span>Quantity</span>
            </div>
            <div className="child-title">
              <span>Price</span>
            </div>
            <div className="child-title">
              <span>Department</span>
            </div>
            <div className="child-title">
              <span>Remove</span>
            </div>
          </div>
        </div>
        <div className="cart-items-body">
          {customerListDetails &&
            !!customerListDetails.length &&
            customerListDetails.map((product) => {
              return (
                <ListProduct
                  product={product}
                  list_id={list_id}
                  key={product.product_id}
                  isCompletedGrid={isCompletedGrid}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};
export default ListItems;
