import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { getGivingBackDetails, resetSearch } from "redux/actions";
import GivingBackCard from "./giving-back-card";
import { getLocalStorageValue } from 'config/helper';


const GivingBack = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
    };
    dispatch(getGivingBackDetails(body));
  }, [dispatch]); // eslint-disable-line

  const givingbackData = useSelector(({ aboutus }) => aboutus.givingbackData);

  const breadcrumbList = [{ lable: "Giving Back", link: "", searchTag: "" }];

  return (
    <div>
      {givingbackData && givingbackData.data && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title="Giving Back" //Need to pass title through api res
          />
          {givingbackData.data.map((givingbackData) => {
            return (
              <GivingBackCard
                givingbackData={givingbackData}
                key={givingbackData.givingback_id}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
export default GivingBack;
