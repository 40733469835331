import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryDetails, resetSearch } from "redux/actions";
import HistoryCard from "./history-card";
import HistoryCardFlip from "./history-card-flip";
import HistoryWrap from "./history-wrap";
import HistoryWrapRight from "./histrorywrap-right";

import { getLocalStorageValue } from "config/helper";

const History = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
    };
    dispatch(getHistoryDetails(body));
  }, [dispatch]); // eslint-disable-line

  const historyData = useSelector(({ aboutus }) => aboutus.historyData);

  const breadcrumbList = [{ lable: "History", link: "", searchTag: "" }];

  return (
    <div>
      {historyData && historyData.data && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title="History" //Need to pass title through api res
          />
          {historyData.data.map((historyData, index) => {
            return index % 2 === 0 ? (
              // <HistoryCard
              //   historyData={historyData}
              //   key={historyData.history_id}
              // />
              <HistoryWrap
                historyData={historyData}
                key={historyData.history_id}
              />
            ) : (
              <React.Fragment>
                <HistoryWrapRight
                  historyData={historyData}
                  key={historyData.history_id}

                  // <HistoryCardFlip
                  //   historyData={historyData}
                  //   key={historyData.history_id}
                  // />
                />
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};
export default History;
