import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getCommonDetails } from "redux/actions";
import { selectCurrentStore } from "redux/actions/store";
import LocationIcon from "assets/icons/location.svg";
import DownIcon from "assets/icons/down.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';

const StoreLocationModal = (props) => {
  const { isShowDeliveryPopup, onClose, selectedStore } = props;
  const [isShowSelectItems, setIsShowSelectItems] = useState(false);
  const clientId = getLocalStorageValue("RSAclient-id");
  const aogEndPointURL = getLocalStorageValue("api-end-point-aog");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeList = useSelector(({ store }) => store.storeListData);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn)

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

 
  const refSelect = useRef();
  const refDelivery = useRef();

  useOnClickOutside(refDelivery, () => onClose());
  useOnClickOutside(refSelect, () => setIsShowSelectItems(false));

  const handleStoreChange = (selectedStore) => {
    setIsShowSelectItems(false);

    dispatch(selectCurrentStore(selectedStore));
    setLocalStorageValue('selected-store-id', selectedStore.ClientStoreId);
    setLocalStorageValue("selected-initial-store-id", selectedStore.ClientStoreId)
    if (clientId && aogEndPointURL) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: parseInt(selectedStore.ClientStoreId),
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getCommonDetails(body));
    }
    navigate("/");
  };
  // 17/feb/2023 - Navina - storelocation popup issue solved (57-64)
  const handleCloseModal =()=> {
    if(!getLocalStorageValue("selected-initial-store-id")){
      setLocalStorageValue("selected-initial-store-id", getLocalStorageValue("selected-store-id"));
    }
    
    onClose();
  }

  return (
    <>
      {isShowDeliveryPopup && (
        <div className="modal-backdrop">
          <div className="modal store-selector-modal" ref={refDelivery}>
            <div className="store-selector-modal-header">
              <div className="logo-center-align">
                <img
                  src={headerSectionData.rsa_client_logo}
                  alt={headerSectionData.image_alt_text}
                />
              </div>
            </div>
            <div className="store-selector-modal-body">
              <div className="input-label"><strong>{headerSectionData?headerSectionData.store_list_pop_lable:'Select Store'}</strong></div>
              <div className="input">
                <div
                  ref={refSelect}
                  className="store-modal-select"
                  onClick={() => setIsShowSelectItems(!isShowSelectItems)}
                >
                  <div>
                    <div className="store-modal-location-icon">
                      <img src={LocationIcon} alt="LocationIcon" />
                    </div>
                    <div className="store-modal-down-icon">
                      <img src={DownIcon} alt="DownIcon" />
                    </div>
                    <span>
                      {selectedStore
                        ? selectedStore.ClientStoreName
                        : "Select Store"}
                    </span>
                  </div>
                  <div
                    className={
                      isShowSelectItems
                        ? "dropdown dropdown-show"
                        : "dropdown dropdown-hidden"
                    }
                  >
                    <div className="list-menu">
                      <ul>
                        {storeList &&
                          storeList.map((el) => {
                            return (
                              <li
                                className={
                                  el.ClientStoreId ===
                                    selectedStore?.ClientStoreId
                                    ? "selected"
                                    : ""
                                }
                                key={el.ClientStoreId}
                                onClick={() => handleStoreChange(el)}
                              >
                                {el.ClientStoreName}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  !!getLocalStorageValue("selected-store-id") && selectedStore
                    ? "continue-shopping-btn"
                    : "disable-btn continue-shopping-btn"
                }
              >
                <button onClick={handleCloseModal}>
                  <span>Continue</span>
                  <i className="fa-solid fa-right-long"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StoreLocationModal;
