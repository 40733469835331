import {
    FETCH_MOBILEAPP_DETAILS,
    FETCH_MOBILEAPP_DETAILS_SUCCESS,
    FETCH_MOBILEAPP_DETAILS_FAILURE,
  
  } from "redux/constants/actionTypes";
  
  let initialState = {
    mobileappData: {},
    mobileappDataLoading: true,
    mobileappDataError: "",
  };
  
  const mobileappReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_MOBILEAPP_DETAILS:
        return {
          ...state,
          mobileappDataLoading: true,
        };
      case FETCH_MOBILEAPP_DETAILS_SUCCESS:
        return {
          ...state,
          aboutusDataLoading: false,
          mobileappData: action.payload,
        };
      case FETCH_MOBILEAPP_DETAILS_FAILURE:
        return {
          ...state,
          mobileappDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default mobileappReducer;
  