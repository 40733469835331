import React, { useState, useRef } from "react";
import DownArrow from "assets/icons/arrow-down.svg";
import { useParams } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { addProductToList, getListDetails } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

import "./add-item-dropdown.scss";

const AddItemDropdown = ({ disabled }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [productQuantity, setProductQuantity] = useState(1);
  const [listText, setListText] = useState("");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const { addProductLoading } = useSelector(({ customlist }) => customlist);

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const refDropdown = useRef();
  useOnClickOutside(refDropdown, () => setShowMyListDropdown(false));

  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
  };
  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
    }
  };
  const handleText = (e) => {
    const { value } = e.target;
    setListText(value);
  };

  const fetchListDetails = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      list_id: id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      PageNo: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    setShowMyListDropdown(false);
    setListText("");
    setProductQuantity(1);
    dispatch(getListDetails(body));
  };

  const handleAddProductToList = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      items_data: [
        {
          product_name: listText,
          qty: productQuantity,
        },
      ],
      list_id: id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    listText &&
      dispatch(addProductToList(body)).then((res) => {
        res.status === "success" && fetchListDetails();
      });
  };
  return (
    <>
      <div
        className={`add-item-button ${disabled ? "disabled" : ""}`}
        ref={refDropdown}
      >
        <button onClick={() => setShowMyListDropdown(!isShowMyListDropdown)}>
          <span>Add New item</span>
          <img src={DownArrow} alt="DownArrow" />
        </button>
        <div
          className={
            isShowMyListDropdown
              ? "add-item-dropdown add-item-show"
              : "add-item-dropdown add-item-hidden"
          }
        >
          <div className="add-item-dropdown-design">
            <div className="dropdown-body">
              <div className="form-control">
                <label>Enter Item Name</label>
                <input
                  type="text"
                  onChange={(e) => handleText(e)}
                  value={listText}
                />
              </div>

              <div className="quantity-button">
                <p>Qty.</p>
                <button>
                  <span>{productQuantity}</span>
                  <div className="arrow-alignment">
                    <div onClick={handleIncrementCount}>
                      <i className="fa-solid fa-angle-up"></i>
                    </div>
                    <div onClick={handleDecrementCount}>
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </button>
              </div>

              <div className="apply-text-alignment">
                {!addProductLoading ? (
                  <p onClick={() => handleAddProductToList()}>Save </p>
                ) : (
                  <p>Saving.. </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddItemDropdown;
