import React, { useState, useEffect } from "react";

const DropdownListItem = ({
  onSelectOption,
  group,
  dataList,
  isShowDropdownOption
}) => {

  const [selectedOption, setSelectOption] = useState([])

  useEffect(() => {
    !isShowDropdownOption && setSelectOption([])
  }, [isShowDropdownOption]) // eslint-disable-line

  const handleChangeOption = option => {
    let updatedSelectedOption = selectedOption;

    if (updatedSelectedOption.includes(option)) {
      updatedSelectedOption = updatedSelectedOption.filter(
        (iftType) => iftType !== option
      );
    } else {
      updatedSelectedOption.push(option);
    }
    setSelectOption([...updatedSelectedOption])
    onSelectOption(group.toLowerCase(), updatedSelectedOption)
  }

  return (
    <div className="group-wrapper">
      <div className="group-name"> <strong>{group}</strong></div>
      <div className="sidebar-item-list">
        {dataList &&
          dataList.map(op => Object.values(op)).flat().map((option, i) => {
            if (option.includes('.png')) return null
            return (
              <div className="ift-item-with-checkbox" key={option}>
                <input
                  type="checkbox"
                  id={group + i}
                  onChange={() => handleChangeOption(option)}
                  checked={selectedOption.includes(option)}
                />
                <label htmlFor={group + i}>
                  <span>{option}</span>
                </label>
              </div>
            );
          })}
      </div>

    </div>
  );
};

export default DropdownListItem;
