import React, { useEffect } from "react";
import TestimonialsSection from "routes/home/TestimonialsSection";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import ProvideSection from "./ProvideSection";
import AddSection from "./AddSection";
import { getLocalStorageValue } from 'config/helper';
import DepartmentInformation from "./DepartmentInformation";
import { getBannerAndTestimonialsData } from "redux/actions";
import { useParams } from "react-router-dom";

const CouponDepartment = () => {
  const dispatch = useDispatch();
  const { departmentSlug } = useParams();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);

  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");


  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getBannerAndTestimonialsData(body));
  }, [dispatch]); // eslint-disable-line

  const couponDepartmentmenu = useSelector(({ couponsDepartment }) => couponsDepartment.coupondepartmentData);

  let singleDepartmentData = couponDepartmentmenu.length > 0 ? couponDepartmentmenu.find(

    (couponDepartmentmenu) => couponDepartmentmenu.department_name_slug === departmentSlug
  ) : {};

  const breadcrumbList = [
    { lable: "Department", link: "", searchTag: "" },
    { lable: singleDepartmentData.department_name, link: "", searchTag: "" },
  ];

  return (
    <div>
      <>
        <BreadcrumbDetailed
          breadcrumbList={breadcrumbList}
          title={singleDepartmentData.department_name} //Need to pass title through api res
        />
        <DepartmentInformation singleDepartmentData={singleDepartmentData} />
        <ProvideSection />
        <AddSection />
        <TestimonialsSection />
      </>
    </div>
  );
};
export default CouponDepartment;
