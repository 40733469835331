import {
    FETCH_MOBILEAPP_DETAILS,
    FETCH_MOBILEAPP_DETAILS_SUCCESS,
    FETCH_MOBILEAPP_DETAILS_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch about data
  export const fetchMobileAppDetails = (payload) => {
    return {
      type: FETCH_MOBILEAPP_DETAILS,
      payload: payload,
    };
  };
  export const fetchMobileAppDetailsSuccess = (payload) => {
    return {
      type: FETCH_MOBILEAPP_DETAILS_SUCCESS,
      payload: payload,
    };
  };
  export const fetchMobileAppDetailsFailure = (payload) => {
    return {
      type: FETCH_MOBILEAPP_DETAILS_FAILURE,
      payload: payload,
    };
  };
  
  export const getMobileAppDetails = (body) => async (dispatch) => {
    dispatch(fetchMobileAppDetails());
  
    axios
      .post("/AogGetAppDetails", body)
      .then((res) => {
        dispatch(fetchMobileAppDetailsSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchMobileAppDetailsFailure({ error: error.data.message }));
      });
  };
  