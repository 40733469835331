import React from "react";
import { useSelector } from "react-redux";
import favLogo from "assets/logo/fav_v3.png";
import favLogo104 from "assets/images/Favorites_104.png";
import { getLocalStorageValue } from "config/helper";
const WishList = () => {
  const clientId = getLocalStorageValue("RSAclient-id");
const cartWishlistDetails = useSelector(({ cartWishlist }) => cartWishlist);
const allow_ecommerce = useSelector(({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce);
const favoriteListImageLogo = useSelector(({ common }) => common.commonDetailsData.header_section.favorite_list_image);
  return (
    <>
      { cartWishlistDetails && allow_ecommerce == "1" && (
        <div className="wish-list info-wrapper" >
          <div>
            <img

                  src={clientId === "104" ? favLogo104 : favoriteListImageLogo}
                  alt="Favotites"
                  style={{ height: "24px", width: "30px" }}
                />
            {cartWishlistDetails.wishlistItemsCount !== "0" ? (
              <div>
                <span>{cartWishlistDetails.wishlistItemsCount} </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <p>Favorites</p>
          </div>
        </div>
      )}
    </>
  );
};

export default WishList;
