import axios from "axios";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';
// axios.defaults.baseURL = "https://whitesfoodliner.ongrocery.shop/api/v1/";

const getBaseUrl = async () =>
  process.env.REACT_APP_RECIPES_BACKEND_URL;

let axiosInstance = axios.create({
  timeout: 100000,
});

// request inceptors for taking token
axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();

    return config;
  },
  (error) => Promise.reject(error)
);

// request inceptors for taking token
// axiosInstance.interceptors.request.use(
//     config => {
//       config.headers.authorization = `bearer ${getLocalStorageValue(
//         "user-token")}`
//       return config;
//     },
//     error => Promise.reject(error),
//   );

// response inceptors for handling response
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.status === "token_expired") {
      const store = getLocalStorageValue("selected-store-id");
      localStorage.clear();
      setLocalStorageValue("selected-store-id", store);
      window.location.href = "/login";
      return;
    }
    return response;
  },
  (error) => {
    // const store = getLocalStorageValue("selected-store-id");
    // localStorage.clear();
    // setLocalStorageValue("selected-store-id", store);
    // window.location.href = "/login";
    return Promise.reject(error);

  }
);

export default axiosInstance;