import {
  ADD_LIST,
  ADD_LIST_SUCCESS,
  ADD_LIST_FAILURE,
  FETCH_LIST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_LIST_DATA,
  FETCH_LIST_DATA_SUCCESS,
  FETCH_LIST_DATA_FAILURE,
  UPDATE_LIST_STATUS,
  UPDATE_LIST_STATUS_SUCCESS,
  UPDATE_LIST_STATUS_FAILURE,
  // SELECT_CHECKOUT_STORE_TYPE,
  // SELECT_CHECKOUT_PAYMENT_METHOD,
  // FETCH_STORE_TIME_SLOTS,
  // FETCH_STORE_TIME_SLOTS_SUCCESS,
  // FETCH_STORE_TIME_SLOTS_FAILURE,
  // FETCH_CART_AMOUNT_DETAILS,
  // FETCH_CART_AMOUNT_DETAILS_SUCCESS,
  // FETCH_CART_AMOUNT_DETAILS_FAILURE,
  // SELECT_CHECKOUT_TIME_SLOT,
  // SELECT_CUSTOMER_ADDRESS,
  // SAVE_PLACE_ORDER,
  // SAVE_PLACE_ORDER_SUCCESS,
  // SAVE_PLACE_ORDER_FAILURE,
  // CHECKOUT_SPECIAL_INSTRUCTION,
  // CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE,
  // CHECKOUT_ORDER_PERFERRED_CONTACT,
  REMOVE_LIST,
  REMOVE_LIST_FAILURE,
  REMOVE_LIST_SUCCESS,
  REMOVE_LIST_ITEM,
  REMOVE_LIST_ITEM_FAILURE,
  REMOVE_LIST_ITEM_SUCCESS,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE,
  MARK_UNCOMPLETED_PRODUCT,
  MARK_UNCOMPLETED_PRODUCT_SUCCESS,
  MARK_UNCOMPLETED_PRODUCT_FAILURE,
  MARK_COMPLETE_ALL_PRODUCT,
  MARK_COMPLETE_ALL_PRODUCT_SUCCESS,
  MARK_COMPLETE_ALL_PRODUCT_FAILURE,
  ADD_PRODUCT_TO_LIST,
  ADD_PRODUCT_TO_LIST_SUCCESS,
  ADD_PRODUCT_TO_LIST_FAILURE,
  ADD_MULTIPLE_PRODUCT_TO_LIST,
  ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS,
  ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  List: null,
  ListLoading: false,
  ListError: "",
  customerList: [],
  customerListLoading: false,
  customerListError: "",
  customerListName: "",
  customerListDetails: [],
  customerListDetailsLoading: false,
  customerListDetailsError: "",
  // checkoutStoreType: "",
  // checkoutPaymentMethod: "",
  // storeTimeSlots: null,
  // storeTimeSlotsLoading: false,
  // storeTimeSlotsError: "",
  // cartAmountDetails: {},
  // cartAmountDetailsLoading: false,
  // cartAmountDetailsError: "",
  // checkoutTimeSlot: "",
  // savePlaceOrder: {},
  // savePlaceOrderLoading: false,
  // savePlaceOrderError: "",
  // checkoutInstruction: "",
  // checkoutContactType: "call_me",
  // checkoutContact: "",

  removeListLoading: true,
  removeListError: false,
  removeListStatus: "",
  removeListItemLoading: true,
  removeListItemError: false,
  removeListItemStatus: "",
  addOrAddAllProductToCartLoading: false,
  addOrAddAllProductToCartError: "",
  markUncompletedProductLoading: false,
  markUncompletedProductError: "",
  uncompleteProductList: [],
  uncompleteProductMessage: "",
  markCompleteAllProductLoading: false,
  markCompleteAllProductError: "",
  addProductLoading: false,
  addProductError: false,
  addProductStatus: "",
  addMultipleProductLoading: false,
  addMultipleProductStatus: "",
  addMultipleProductError: ""
};

const customlistReducer = (state = initialState, action) => {
  switch (action.type) {
    // add List
    case ADD_LIST:
      return {
        ...state,
        ListLoading: true,
      };
    case ADD_LIST_SUCCESS:
      return {
        ...state,
        ListLoading: false,

        ListError: action.payload.message,
        customerList: [...state.customerList, action.payload.data],
      };
    case ADD_LIST_FAILURE:
      return {
        ...state,
        ListLoading: false,
        ListError: action.payload.error,
      };

    // Get lists
    case FETCH_LIST:
      return {
        ...state,
        customerListLoading: true,
      };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        customerListLoading: false,
        customerList: action.payload.data,
        ListError: "",
      };
    case FETCH_LIST_FAILURE:
      return {
        ...state,
        customerListError: action.payload.error,
      };

    // FETCH LIST DATA
    case FETCH_LIST_DATA:
      return {
        ...state,
        customerListDetailsLoading: true,
        customerListName: "",
      };
    case FETCH_LIST_DATA_SUCCESS:
      return {
        ...state,
        customerListDetailsLoading: false,
        customerListName: action.payload.list_name,
        customerListDetails: action.payload.data,
        customerListDetailsError: "",
      };
    case FETCH_LIST_DATA_FAILURE:
      return {
        ...state,
        customerListDetailsError: action.payload.error,
      };
    // MAKE LIST ACTIVE
    case UPDATE_LIST_STATUS:
      return {
        ...state,
        customerListLoading: true,
        customerListError: "",
      };
    case UPDATE_LIST_STATUS_SUCCESS:
      return {
        ...state,
        customerListLoading: false,
        customerList: state.customerList.map((el) =>
          el.list_id === action.payload.list_id
            ? { ...el, status: "1", status_label: "* Active List" }
            : { ...el, status: "0", status_label: "" }
        ),
        customerListError: "",
      };
    case UPDATE_LIST_STATUS_FAILURE:
      return {
        ...state,
        customerListError: action.payload.error,
      };

    // // Cart amount
    // case FETCH_CART_AMOUNT_DETAILS:
    //   return {
    //     ...state,
    //     cartAmountDetailsLoading: true,
    //   };
    // case FETCH_CART_AMOUNT_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     cartAmountDetailsLoading: false,
    //     cartAmountDetails: action.payload,
    //   };
    // case FETCH_CART_AMOUNT_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     cartAmountDetailsError: action.payload.error,
    //   };

    // // Save order
    // case SAVE_PLACE_ORDER:
    //   return {
    //     ...state,
    //     savePlaceOrderLoading: true,
    //   };
    // case SAVE_PLACE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     savePlaceOrderLoading: false,
    //     savePlaceOrder: action.payload,
    //   };
    // case SAVE_PLACE_ORDER_FAILURE:
    //   return {
    //     ...state,
    //     savePlaceOrderError: action.payload.error,
    //   };

    // case SELECT_CHECKOUT_STORE_TYPE:
    //   return {
    //     ...state,
    //     checkoutStoreType: action.payload,
    //   };
    // case SELECT_CHECKOUT_PAYMENT_METHOD:
    //   return {
    //     ...state,
    //     checkoutPaymentMethod: action.payload,
    //   };
    // case SELECT_CHECKOUT_TIME_SLOT:
    //   return {
    //     ...state,
    //     checkoutTimeSlot: action.payload,
    //   };
    // case SELECT_CUSTOMER_ADDRESS:
    //   return {
    //     ...state,
    //     customerAddress: action.payload,
    //   };
    // case CHECKOUT_SPECIAL_INSTRUCTION:
    //   return {
    //     ...state,
    //     checkoutInstruction: action.payload,
    //   };
    // case CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE:
    //   return {
    //     ...state,
    //     checkoutContactType: action.payload,
    //   };
    // case CHECKOUT_ORDER_PERFERRED_CONTACT:
    //   return {
    //     ...state,
    //     checkoutContact: action.payload + "",
    //   };

    // List Remove
    case REMOVE_LIST:
      return {
        ...state,
        removeListLoading: true,
      };
    case REMOVE_LIST_SUCCESS:
      return {
        ...state,
        removeListLoading: false,
        removeListStatus: action.payload.status,
        customerList: state.customerList.filter(
          (i) => i.list_id !== action.payload.list_id
        ),
      };
    case REMOVE_LIST_FAILURE:
      return {
        ...state,
        removeListError: action.payload.error,
      };
    // List Product Remove
    case REMOVE_LIST_ITEM:
      return {
        ...state,
        removeListItemLoading: true,
      };
    case REMOVE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        removeListItemLoading: false,
        // removeListItemStatus:action.payload.status,
        customerListDetails: state.customerListDetails.filter(
          (i) => i.product_id !== action.payload.product_id
        ),
      };
    case REMOVE_LIST_ITEM_FAILURE:
      return {
        ...state,
        removeListItemError: action.payload.error,
      };
    // add or mark as completed or add multiple
    case ADD_OR_ADD_ALL_TO_CART_PRODUCT:
      return {
        ...state,
        addOrAddAllProductToCartLoading: true,
        uncompleteProductList: [],
        uncompleteProductMessage: "",
      };
    case ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS:
      return {
        ...state,
        addOrAddAllProductToCartLoading: false,
        uncompleteProductList: action.payload.uncomplete_product,
        uncompleteProductMessage:
          action.payload.status === "error" ? action.payload.message : "",
        customerListDetails: state.customerListDetails.map((product) => {
          if (action.payload.complete_product.includes(product.product_id)) {
            return {
              ...product,
              is_complete: "1",
            };
          } else {
            return product;
          }
        }),
      };
    case ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE:
      return {
        ...state,
        addOrAddAllProductToCartError: action.payload.error,
      };
    // mark un completed and Uncomplete all products
    case MARK_UNCOMPLETED_PRODUCT:
      return {
        ...state,
        markUncompletedProductLoading: true,
      };
    case MARK_UNCOMPLETED_PRODUCT_SUCCESS:
      return {
        ...state,
        markUncompletedProductLoading: false,
        customerListDetails: state.customerListDetails.map((product) => {
          if (
            action.payload
              .map((pdt) => pdt.product_id)
              .includes(product.product_id)
          ) {
            return {
              ...product,
              is_complete: "0",
            };
          } else {
            return product;
          }
        }),
      };
    case MARK_UNCOMPLETED_PRODUCT_FAILURE:
      return {
        ...state,
        markUncompletedProductError: action.payload.error,
      };
    //mark and complete all items
    case MARK_COMPLETE_ALL_PRODUCT:
      return {
        ...state,
        addOrAddAllProductToCartLoading: true,
        uncompleteProductMessage: "",
      };
    case MARK_COMPLETE_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        addOrAddAllProductToCartLoading: false,
        customerListDetails: state.customerListDetails.map((product) => {
          if (
            action.payload
              .map((pdt) => pdt.product_id)
              .includes(product.product_id)
          ) {
            return {
              ...product,
              is_complete: "1",
            };
          } else {
            return product;
          }
        }),
      };
    case MARK_COMPLETE_ALL_PRODUCT_FAILURE:
      return {
        ...state,
        addOrAddAllProductToCartError: action.payload.error,
      };

    case ADD_PRODUCT_TO_LIST:
      return {
        ...state,
        addProductLoading: true,
      };
    case ADD_PRODUCT_TO_LIST_SUCCESS:
      return {
        ...state,
        addProductLoading: false,
        addProductStatus: action.payload,
      };
    case ADD_PRODUCT_TO_LIST_FAILURE:
      return {
        ...state,
        addProductLoading: false,
        addProductError: action.payload.error,
      };
    case ADD_MULTIPLE_PRODUCT_TO_LIST:
      return {
        ...state,
        addMultipleProductLoading: true,
      };
    case ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS:
      return {
        ...state,
        addMultipleProductLoading: false,
        addMultipleProductStatus: action.payload,
      };
    case ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE:
      return {
        ...state,
        addMultipleProductLoading: false,
        addMultipleProductError: action.payload.error,
      };
    default:
      return state;
  }
};

export default customlistReducer;
