import React from "react";
import { useSelector } from "react-redux";
import "./workplace-info.scss";

const WorkplaceInfo = () => {

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  return (
    <>
      {headerSectionData.employment_form_description !== ""?(
        <div className="work-place-info-section">
          <div className="container">
            <p
                dangerouslySetInnerHTML={{
                  __html: headerSectionData
                    ? headerSectionData.employment_form_description
                    : "",
                }}
              >
            </p>
          </div>
        </div>
      ):(
        <div className="work-place-info-section">
          <div className="container">
            
          </div>
        </div>
      )}
      
    </>
  );
};
export default WorkplaceInfo;
