import React from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import EmploymentForm from "./EmploymentForm";
import WorkplaceInfo from "./WorkplaceInfo";
import { resetSearch, getJobsList } from "redux/actions";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";

const Employment = () => {
  const breadcrumbList = [{ lable: "Employment", link: "", searchTag: "" }];
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { jobs_section: jobList = [] } = useSelector(
    ({ career }) => career.joblistData
  );

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const [callFormSubmit, setCallFormSubmit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    !jobList.length && dispatch(getJobsList(body));
  }, [dispatch, storeId]); // eslint-disable-line

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Employment"} //Need to pass title through api res
      />
      <WorkplaceInfo />
      <EmploymentForm
        jobList={jobList}
        setCallFormSubmit={setCallFormSubmit}
        callFormSubmit={callFormSubmit}
      />
    </>
  );
};
export default Employment;
