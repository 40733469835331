import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useSelector } from 'react-redux';
import DropdownListItem from "./DropdownListItem";
import downIcon from "assets/icons/down-black.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import './view-by-dropdown.scss'
import { useDispatch } from 'react-redux';
import { getRecipesList, } from "redux/actions";

const ViewByDropdown = ({
  lable = "",
  iconTag,
}) => {
  const dispatch = useDispatch()
  const refDropdown = useRef();
  const [isShowDropdownOption, setIsShowDropdownOption] = useState(false);
  useOnClickOutside(refDropdown, () => setIsShowDropdownOption(false));

  const [selectedOptionValue, setSelectedOptionValue] = useState({});

  const { recipesMasterData } = useSelector(
    ({ recipes }) => recipes
  );

  useEffect(() => {
    !isShowDropdownOption && setSelectedOptionValue({})
  }, [isShowDropdownOption]) // eslint-disable-line

  const handleChange = (group, value) => {

    if (value.length === 0) {
      delete selectedOptionValue[group]
      setSelectedOptionValue({ ...selectedOptionValue })
    } else {
      setSelectedOptionValue({ ...selectedOptionValue, ...{ [group]: value } });
    }
  };

  let viewByGroupData = Object.assign(recipesMasterData || {})

  const handleApply = () => {

    const queryParams = Object.keys(selectedOptionValue).reduce((acc, cur) => {
      return acc ? acc + `&${cur}=${selectedOptionValue[cur].join(",")}` : `${cur}=${selectedOptionValue[cur].join(",")}`
    }, "")
    setIsShowDropdownOption(false)
    queryParams && dispatch(getRecipesList(queryParams))

  }


  return (
    <div className="view-by-show-items" ref={refDropdown}>
      <button onClick={() => setIsShowDropdownOption(!isShowDropdownOption)}>
        {iconTag && iconTag}
        <span>{lable}</span>
        <img src={downIcon} alt="downIcon" />
      </button>

      <div
        className={
          isShowDropdownOption
            ? "dropdown-align dropdown-show"
            : "dropdown-align dropdown-hidden"
        }
      >
        <div className="list-menu" id="style-4">
          <div className="action-button">
            <span onClick={handleApply}>Apply</span>
          </div>
          {!!Object.keys(viewByGroupData).length && Object.keys(viewByGroupData).map((group, index) => {
            if (group === "Types") return null
            return (
              <DropdownListItem
                group={group}
                dataList={viewByGroupData[group]}
                onSelectOption={handleChange}
                key={index}
                selectedOptionValue={selectedOptionValue}
                isShowDropdownOption={isShowDropdownOption}
              />
            );
          })}
          <div className="action-button">
            <span onClick={handleApply}>Apply</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewByDropdown;
