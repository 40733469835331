import React from "react";
import "./information-section.scss";

const InformationSection = ({ client_review }) => {
  return (
    <>
      <div className="container">
        <div className="information-section">
          <div className="information-alignment">
            {client_review.map((item) => {
              return (
                <div className="info-box" key={item.client_review_id}>
                  <h1>{item.count}</h1>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default InformationSection;
