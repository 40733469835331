import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "assets/icons/search.svg";
import { getRecipesProductList } from "redux/actions";
import ProductCard from "./IngredientProductCard";
import Pagination from "components/Common/Pagination";
import _ from "lodash";
import "./ingredients-popup.scss";
import { getLocalStorageValue } from 'config/helper';

const IngradientsPopup = ({ show, close, ingredient, search }) => {

  const { original = "", name = "" } = ingredient
  const dispatch = useDispatch();

  const pageLimit = 20

  const [searchedText, setSearchedText] = useState(name);
  const [page, setPage] = useState(1);

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");


  const { recipeProductList, recipeProductListLoading, recipeProductTotalPages } = useSelector(
    ({ recipes }) => recipes
  );


  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: 1,
      category_ids: "",
      ift: "",
      limit: pageLimit,
      sort_by: "",
      min_price: "",
      max_price: "",
      funtional_text: "",
      funtional_level: "",
      function_names: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchedText || ""

    };
    dispatch(getRecipesProductList(body));
  }, [clientId, storeId, isUserLoggedIn, pageLimit]); // eslint-disable-line

  const handleChange = (e) => {
    setSearchedText(e.target.value);
    setPage(1);
    getSearchResult.current(e.target.value);
  };



  const handleSetPage = (data) => {
    setPage(data);

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: data,
      category_ids: "",
      ift: "",
      limit: pageLimit,
      sort_by: "",
      min_price: "",
      max_price: "",
      funtional_text: "",
      funtional_level: "",
      function_names: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchedText || ""
    };
    getChangePageSearchResult.current(body);
  };


  const getSearchResult = useRef(
    _.debounce((searchedText) => {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        PageNo: 1,
        category_ids: "",
        ift: "",
        limit: pageLimit,
        sort_by: "",
        min_price: "",
        max_price: "",
        funtional_text: "",
        funtional_level: "",
        function_names: "",
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        search: searchedText || ""

      };
      dispatch(getRecipesProductList(body));
    }, 500)
  );


  const getChangePageSearchResult = useRef(
    _.debounce((body) => {
      dispatch(getRecipesProductList(body));
    }, 500)
  );

  return (
    <>
      {show ? (
        <div className="ingradient-container">
          <div className="modal">
            <div className="modal-header">
              <h3>{original}</h3>
              <div className="close-btn" onClick={() => close()}>
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="search-products">
              <div className="relative-div">
                <input
                  type="text"
                  onChange={handleChange}
                  value={searchedText}
                />
                <div className="icon-wrapper">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
              </div>
            </div>

            <div className="product-list">
              <div className="product-list-grid">
                {recipeProductList &&
                  !!recipeProductList.length &&
                  recipeProductList.map((product) => {
                    return (
                      <div className="product-list-grid-items" key={product.product_id}>
                        <ProductCard product={product} />
                      </div>
                    );
                  })}
              </div>
              {!recipeProductListLoading && recipeProductList &&
                !recipeProductList.length && (
                  <div className="no-product">
                    No items found. Search for products and add to cart
                  </div>
                )}
              {recipeProductListLoading &&
                !recipeProductList.length && (
                  <div className="loading-msg">
                    Loading...
                  </div>
                )}

            </div>
            {recipeProductList.length ? (
              <Pagination
                pages={recipeProductTotalPages}
                current={page}
                onClick={handleSetPage}
              />
            ) : (
              ""
            )}
          </div>
        </div>)
        : (
          ""
        )}

    </>
  );
};

export default IngradientsPopup;
