import React, { useEffect, useState } from "react";
import {
  getCartList,
  getRemoveProductToCartList,
} from "redux/actions/cartWishlist";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "assets/icons/outline-close.svg";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import { getLocalStorageValue } from 'config/helper';

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [hideDropdown, setHideDropdown] = useState(false);

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { productCartList, productCartDetails, cateringAndMealKitProductCartList } = useSelector(({ cartWishlist }) => cartWishlist);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const isShowMultipleCart = !!cateringAndMealKitProductCartList.length

  const cartTotalAmount =
    productCartList &&
    productCartList.reduce((acc, prodcut) => {
      return acc + +prodcut.final_total;
    }, 0);

  const isEbtProductAvailable = productCartList.some(
    (product) => product.ebt_item === "1"
  );
  const cartTotalAmountForCateringAndMealKit =
    cateringAndMealKitProductCartList &&
    cateringAndMealKitProductCartList.reduce((acc, prodcut) => {
      return acc + +prodcut.final_total;
    }, 0);

  const totalCartProductLength = productCartList.length + cateringAndMealKitProductCartList.length

  const handleMouseEnter = () => {
    setHideDropdown(false);
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn && dispatch(getCartList(body));
  }, [isUserLoggedIn, storeId]); // eslint-disable-line

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      member_number: memberNumber,
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };
    dispatch(getRemoveProductToCartList(body));
  };


  const handleRedirectOnPdpPage = product => {
    setHideDropdown(true)
    navigate(`/product-description/${product.department_slug}/${product.slug}`)
  }
  const {
    allow_delivery = "0",
    allow_pickup = "0",
    minimum_delivery_order_amount = "0",
    minimum_pickup_order_amount = "0"
  } = productCartDetails

  let buttonDisable = false
  let minimum_order_amount_message = ""

  if (allow_delivery === "1" && minimum_delivery_order_amount < cartTotalAmount) {
    buttonDisable = true
  } else if (allow_pickup === "1" && minimum_pickup_order_amount < cartTotalAmount) {
    buttonDisable = true
  }
  else if ((allow_delivery === "1" && minimum_delivery_order_amount < cartTotalAmount) || (allow_pickup === "1" && minimum_pickup_order_amount < cartTotalAmount)) {
    buttonDisable = true
  }

  // min amount msg based on remaining amount and pickup or delivery
  if ((allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount) && (allow_pickup === "1" && minimum_pickup_order_amount > cartTotalAmount)) {
    minimum_order_amount_message = `Min: <span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span><span className="danger">✘</span> &nbsp;&nbsp;&nbsp;&nbsp; <span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  }
  else if (allow_pickup === "1" && allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount && minimum_pickup_order_amount <= cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="success">$${minimum_pickup_order_amount} (pickup)✔</span>&nbsp;&nbsp;&nbsp;&nbsp; <span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  } else if (allow_pickup === "1" && allow_delivery === "1" && minimum_pickup_order_amount > cartTotalAmount && minimum_delivery_order_amount <= cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span> <span className="danger">✘</span>&nbsp;&nbsp;&nbsp;&nbsp; <span className="success">$${minimum_delivery_order_amount} (delivery)✔</span>`
  }
  else if (allow_pickup === "1" && minimum_pickup_order_amount > cartTotalAmount) {
    minimum_order_amount_message = `Min: <span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span><span className="danger">✘</span>`
  } else if (allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  }
  return (
    <>
      <div className="info-relative" onMouseEnter={handleMouseEnter}>
        <div className="info-wrapper">
          <div>
            <i className="fa-solid fa-cart-shopping"></i>

            {totalCartProductLength !== 0 ? (
              <div className="bg-primary">
                <span>{totalCartProductLength}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <span>Shopping Cart</span>
            <h6>{cartTotalAmount ? `$ ${cartTotalAmount.toFixed(2)}` : ""}</h6>
          </div>
        </div>
        <div
          className={
            hideDropdown
              ? "shopping-cart-dropdown-hide"
              : "shopping-cart-dropdown"
          }
        >
          {isShowMultipleCart ? <div className="shopping-cart-border">

            <div className="multiple-cart-list-view-design-align">

              {productCartList && !!productCartList.length && (
                <div className="multiple-cart">
                  <div className="multiple-cart-info">
                    <p>Grocery</p>
                    <div className="multiple-cart-info-wrapper">

                      <div>
                        <i className="fa-solid fa-cart-shopping"></i>

                        {productCartList.length !== 0 ? (
                          <div className="bg-primary">
                            <span>{productCartList.length}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                    </div>
                  </div>

                  <div className="total-payment-info">
                    <p>Total</p>
                    <h4>
                      {cartTotalAmount
                        ? `$ ${cartTotalAmount.toFixed(2)}`
                        : "$ 0"}
                    </h4>
                  </div>
                  {/* <p style={{paddingLeft: '30px'}}>
                    {minimum_order_amount_message || ""}
                  </p> */}
                  {!isEbtProductAvailable && <div>
                    <ReactHtmlParser
                      htmlString={minimum_order_amount_message || ""}
                    />
                  </div>}
                  <div className="multiple-view-checkout-cart">
                    <Link to="/your-cart" className="yourcart-button">
                      <button onClick={() => setHideDropdown(true)}>
                        View cart
                      </button>
                    </Link>
                    {isEbtProductAvailable ? <Link to="/checkout" className="checkout-button">
                      <button onClick={() => setHideDropdown(true)}>
                        Checkout
                      </button>
                    </Link> : !buttonDisable ? (
                      <div className="checkout-button">
                        <button className="disable-btn">
                          Checkout
                        </button>
                      </div>
                    ) : (
                      <Link to="/checkout" className="checkout-button">
                        <button onClick={() => setHideDropdown(true)}>
                          Checkout
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              )}

              {/* catering and meal kit */}

              {cateringAndMealKitProductCartList && !!cateringAndMealKitProductCartList.length && (
                <div className="multiple-cart">
                  <div className="multiple-cart-info">
                    <p>Catering & Meals</p>
                    <div className="multiple-cart-info-wrapper">

                      <div>
                        <i className="fa-solid fa-cart-shopping"></i>

                        {cateringAndMealKitProductCartList.length !== 0 ? (
                          <div className="bg-primary">
                            <span>{cateringAndMealKitProductCartList.length}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                    </div>
                  </div>

                  <div className="total-payment-info">
                    <p>Total</p>
                    <h4>
                      {cartTotalAmountForCateringAndMealKit
                        ? `$ ${cartTotalAmountForCateringAndMealKit.toFixed(2)}`
                        : "$ 0"}
                    </h4>
                  </div>

                  <div className="multiple-view-checkout-cart">
                    <Link to="/your-cart/catering-meal" className="yourcart-button">
                      <button onClick={() => setHideDropdown(true)}>
                        View cart
                      </button>
                    </Link>
                    <Link to="/checkout/catering-meal" className="checkout-button">
                      <button onClick={() => setHideDropdown(true)}>
                        Checkout
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div> : <div className="shopping-cart-border">
            <div className="list-view-design-align">
              {productCartList.length === 0 ? (
                <div className="cart-empty-message">
                  <h2>Cart is empty</h2>
                </div>
              ) : (
                ""
              )}

              {productCartList.map((product) => {
                const { image, name, qty, final_price, product_id, item_id } =
                  product;
                return (
                  <div className="cart-grid" key={product_id}>
                    <div className="cart-grid-items">
                      <div className="product-image-show">
                        <img src={image} alt="CartProductImage" />
                      </div>
                    </div>
                    <div className="cart-grid-items">

                      <h3 onClick={() => handleRedirectOnPdpPage(product)}>{name}</h3>


                      <p>
                        {qty} × {final_price}
                      </p>
                    </div>
                    <div className="cart-grid-items">
                      <img
                        src={CloseIcon}
                        alt="CloseIcon"
                        onClick={() => handleRemoveProductToCart(item_id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {productCartList && !!productCartList.length && (
              <>
                <div className="total-payment">
                  <p>Total</p>
                  <h4>
                    {cartTotalAmount
                      ? `$ ${cartTotalAmount.toFixed(2)}`
                      : "$ 0"}
                  </h4>
                </div>
                {/* <p style={{paddingLeft: '30px'}}>
                    {minimum_order_amount_message || ""}
                  </p> */}
                {!isEbtProductAvailable && <div>
                  <ReactHtmlParser
                    htmlString={minimum_order_amount_message || ""}
                  />
                </div>}

                <div className="view-checkout-cart">
                  <Link to="/your-cart" className="yourcart-button">
                    <button onClick={() => setHideDropdown(true)}>
                      View cart
                    </button>
                  </Link>
                  {isEbtProductAvailable ? <Link to="/checkout" className="checkout-button">
                    <button onClick={() => setHideDropdown(true)}>
                      Checkout
                    </button>
                  </Link> : !buttonDisable ? (
                    <div className="checkout-button">
                      <button className="disable-btn">
                        Checkout
                      </button>
                    </div>
                  ) : (
                    <Link to="/checkout" className="checkout-button">
                      <button onClick={() => setHideDropdown(true)}>
                        Checkout
                      </button>
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>}

        </div>
      </div>
    </>
  );
};

export default ShoppingCart;