import {
  FETCH_EMPLOYMENT_DETAILS,
  FETCH_EMPLOYMENT_DETAILS_SUCCESS,
  FETCH_EMPLOYMENT_DETAILS_FAILURE,


} from "redux/constants/actionTypes";
import axios from "config/axios";

export const fetchEmploymentDetails = (payload) => {
  return {
    type: FETCH_EMPLOYMENT_DETAILS,
    payload: payload,
  };
};

export const fetchEmploymentSuccess = (payload) => {
  return {
    type: FETCH_EMPLOYMENT_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchEmploymentFailure = (payload) => {
  return {
    type: FETCH_EMPLOYMENT_DETAILS_FAILURE,
    payload: payload,
  };
};


export const addEmployee = (body) => async (dispatch) => {
  dispatch(fetchEmploymentDetails());
  return axios
    .post("/AogAddEmployees", body)
    .then((res) => {
      dispatch(fetchEmploymentSuccess(res.data));
      return res
    })
    .catch((error) => {
      dispatch(
        fetchEmploymentFailure({ error: error.response.data.message })
      );
    });
};



