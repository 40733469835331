import {
  FETCH_HOME_ADS_LIST,
  FETCH_HOME_ADS_LIST_SUCCESS,
  FETCH_HOME_ADS_LIST_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch blog data
export const fetchAdsDetails = (payload) => {
  return {
    type: FETCH_HOME_ADS_LIST,
    payload: payload,
  };
};
export const fetchAdsSuccess = (payload) => {
  return {
    type: FETCH_HOME_ADS_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchAdsFailure = (payload) => {
  return {
    type: FETCH_HOME_ADS_LIST_FAILURE,
    payload: payload,
  };
};

export const getAdsectiondetails = (body) => async (dispatch) => {
  dispatch(fetchAdsDetails());

  axios
    .post("/AogAdsSection", body)
    .then((res) => {
      dispatch(fetchAdsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchAdsFailure({ error: error.data.message }));
    });
};
