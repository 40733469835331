import React, { useState } from "react";
import AddIngredientsToMyList from "./AddIngredientsToMyList";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, addProductToList } from "redux/actions";
import IngredientsPopup from "./IngredientsPopup";
import { getLocalStorageValue } from 'config/helper';

const Ingredients = ({ extendedIngredients }) => {
  const extendedIngredientList = extendedIngredients;

  const dispatch = useDispatch();

  const { wishListItemsLoading } = useSelector(
    ({ cartWishlist }) => cartWishlist
  );

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);

  const [isShowModal, setModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const [isShowAddIngredientsDropdown, setAddIngredientsDropdown] =
    useState(false);
  const [isShowAddIngredientsNewListModal, setAddIngredientsNewListModal] =
    useState(false);

  const [selectedId, setSelectedId] = useState("");

  const handleAddToWishlist = (list_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id: selectedId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      qty: 1,
      list_id,
    };

    dispatch(addToWishlist(body)).then(() => {
      setShowMyListDropdown(false);
    });
  };

  const handleAddProductToList = (id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      items_data: extendedIngredientList.map((ingredient) => {
        return {
          product_name: ingredient.name,
          qty: 1,
        };
      }),
      list_id: id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(addProductToList(body)).then(() => {
      setAddIngredientsDropdown(false);
    });
  };

  const handleShowMyListDropdown = (isShow, id) => {
    setShowMyListDropdown(isShow);
    setSelectedId(id);
  };

  const Toggle = () => setModal(!isShowModal);

  const handleOpenIngredientPopup = (product) => {
    Toggle();
    setSelectedIngredient(product);
  };

  return (
    <>
      <div className="ingradients-wrapper">
        {extendedIngredientList.map((eq, i) => {
          const {
            measures: {
              us: { amount = "", unitShort = "" },
            },
          } = eq;
          return (
            <div className="ingradient-item" key={eq.id}>
              <div className="ingradient">
                <strong>{eq.original}</strong>
                <div className="ingradient-product-info">
                  {eq.image ? (
                    <img
                      src={
                        "https://spoonacular.com/cdn/ingredients_100x100/" +
                        eq.image
                      }
                      alt={eq.name}
                    />
                  ) : (
                    ""
                  )}

                  <div className="product-detail">
                    <strong>{eq.name}</strong>
                    <span>
                      <strong>qty: </strong>
                      {amount} {unitShort}
                    </span>
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <div>
                  <button onClick={() => handleOpenIngredientPopup(eq)}>
                    +<span>Add to cart</span>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() =>
                      handleShowMyListDropdown(!isShowMyListDropdown, eq.id)
                    }
                  >
                    +<span>Add to list</span>
                  </button>
                </div>

                {isShowMyListDropdown && selectedId === eq.id && (
                  <div>
                    <AddIngredientsToMyList
                      isShowMyListDropdown={selectedId === eq.id}
                      isShowNewListModal={isShowNewListModal}
                      setShowNewListModal={setShowNewListModal}
                      setShowMyListDropdown={() =>
                        setShowMyListDropdown(!isShowMyListDropdown)
                      }
                      handleApply={handleAddToWishlist}
                      loading={wishListItemsLoading}
                      productId={eq.id}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="add-ingradient-button">
        <button
          onClick={() =>
            setAddIngredientsDropdown(!isShowAddIngredientsDropdown)
          }
        >
          +<span>Add Ingredients To List</span>
        </button>
        {isShowAddIngredientsDropdown && (
          <div>
            <AddIngredientsToMyList
              isShowMyListDropdown={isShowAddIngredientsDropdown}
              isShowNewListModal={isShowAddIngredientsNewListModal}
              setShowNewListModal={setAddIngredientsNewListModal}
              setShowMyListDropdown={() =>
                setAddIngredientsDropdown(!isShowAddIngredientsDropdown)
              }
              handleApply={handleAddProductToList}
              loading={wishListItemsLoading}
            />
          </div>
        )}
      </div>
      {isShowModal && (
        <IngredientsPopup
          show={isShowModal}
          close={Toggle}
          ingredient={selectedIngredient}
        />
      )}
    </>
  );
};
export default Ingredients;
