import React from "react";
import ImageFadeIn from "react-image-fade-in";
import "./product-preview.scss";

const ProductPreview = ({ image }) => {

  return (
    <div>
      <div className="recipe-product-preview-slider">
        <div className="selected-product">
          <ImageFadeIn src={image} />
        </div>
      </div>
    </div>
  );
};
export default ProductPreview;
