import React from "react";
import "./download-clipped-coupons.scss";
import { getLocalStorageValue } from 'config/helper';

const DownloadCartItems = React.forwardRef(({ couponCartList,customerData}, ref) => {

    const barcodeImage =  getLocalStorageValue("user-barcode");
     return (
        <>
            {couponCartList.length > 0 && (
                <div className="download-coupon-cart-items-table" ref={ref}>
                    <div className="download-coupon-cart-section-align">
                        <div className="download-coupon-cart-title">
                            <h2>Clipped Coupons</h2>
                        </div>
                        <div className="download-coupon-page-grid-align">
                            <div>
                                <p>
                                    There are{" "}
                                    {couponCartList.length > 0 ? couponCartList.length : "0"} coupons
                                    clipped.
                                </p>
                            </div>
                        </div>
                    </div>
                    <img alt="Barcode"
                                      src={barcodeImage ?  barcodeImage:''}
                                                                    style={{ marginLeft: '108px' }}
                                                                   
                                                                />
              
                    <table className="download-coupon-cart-table">
                        <thead>
                            <th></th>
                            <th className="title">Title</th>
                            <th>Use(times)</th>
                        </thead>
                        <tbody>
                            {couponCartList.map((coupon, index) => {
                                const { image_path, title } = coupon;
                                return (
                                    <tr key={index}>
                                        <td data-label="Image">
                                            <img src={image_path} alt="CouponImage" />
                                        </td>
                                        <td data-label="Title" className="title">
                                            {title}
                                        </td>
                                        <td data-label="Limit">{1}</td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
});
export default DownloadCartItems;
