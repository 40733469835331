import {
    FETCH_COUPONDEPARTMENT_DATA,
    FETCH_COUPONDEPARTMENT_SUCCESS,
    FETCH_COUPONDEPARTMENT_FAILURE,

    FETCH_COUPON_DEPARTMENT_SERVICES_DATA,
    FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS,
    FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE
    } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch coupon menu data
  export const fetchcouponDepartmentDetails = (payload) => {
    return {
      type: FETCH_COUPONDEPARTMENT_DATA,
      payload: payload,
    };
  };
  export const fetchcouponDepartmentSuccess = (payload) => {
    return {
      type: FETCH_COUPONDEPARTMENT_SUCCESS,
      payload: payload,
    };
  };
  export const fetchcouponDepartmentFailure = (payload) => {
    return {
      type: FETCH_COUPONDEPARTMENT_FAILURE,
      payload: payload,
    };
  };
  
  export const fetchcouponDepartmentServices = (payload) => {
    return {
      type: FETCH_COUPON_DEPARTMENT_SERVICES_DATA,
      payload: payload,
    };
  };
  export const fetchcouponDepartmentServicesSuccess = (payload) => {
    return {
      type: FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS,
      payload: payload,
    };
  };
  export const fetchcouponDepartmentServicesFailure = (payload) => {
    return {
      type: FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE,
      payload: payload,
    };
  };
  
 
  export const getcouponDepartmentDetails = (body) => async (dispatch) => {
    dispatch(fetchcouponDepartmentDetails());
  
    axios
      .post("/AogGetDepartmentForCoupons", body)
      .then((res) => {
        dispatch(fetchcouponDepartmentSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchcouponDepartmentFailure({ error: error.data.message }));
      });
  };
  
  export const getcouponDepartmentservices = (body) => async (dispatch) => {
    dispatch(fetchcouponDepartmentServices());
  
    axios
      .post("/AogGetDepartmentForCouponsDetails", body)
      .then((res) => {
        
         
        dispatch(fetchcouponDepartmentServicesSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchcouponDepartmentServicesFailure({ error: error.data.message }));
      });
  };
  