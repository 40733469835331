import {
  FETCH_CATERING_DEPARTMENT_TREE,
  FETCH_CATERING_DEPARTMENT_TREE_SUCCESS,
  FETCH_CATERING_DEPARTMENT_TREE_FAILURE,
  FETCH_CATERING_PRODUCT_LIST,
  FETCH_CATERING_PRODUCT_LIST_SUCCESS,
  FETCH_CATERING_PRODUCT_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION,
  RESET_SELECTED_CUSTOM_OPTIONS,
  UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION,
  RESET_SELECTED_CART_CUSTOM_OPTIONS,
} from "redux/constants/actionTypes";

import axios from "config/axios";

export const fetchCateringDepartmentTree = (payload) => {
  return {
    type: FETCH_CATERING_DEPARTMENT_TREE,
    payload: payload,
  };
};
export const fetchCateringDepartmentTreeSuccess = (payload) => {
  return {
    type: FETCH_CATERING_DEPARTMENT_TREE_SUCCESS,
    payload: payload,
  };
};
export const fetchCateringDepartmentTreeFailure = (payload) => {
  return {
    type: FETCH_CATERING_DEPARTMENT_TREE_FAILURE,
    payload: payload,
  };
};

export const getCateringDepartmentTree = (body) => async (dispatch) => {
  dispatch(fetchCateringDepartmentTree());
  axios
    .post("/AogGetCateringAndMealkitTreeView", body)
    .then((res) => {
      dispatch(fetchCateringDepartmentTreeSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchCateringDepartmentTreeFailure({ error: error.data.message })
      );
    });
};

export const fetchCateringProductList = (payload) => {
  return {
    type: FETCH_CATERING_PRODUCT_LIST,
    payload: payload,
  };
};
export const fetchCateringProductListSuccess = (payload) => {
  return {
    type: FETCH_CATERING_PRODUCT_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchCateringProductListFailure = (payload) => {
  return {
    type: FETCH_CATERING_PRODUCT_LIST_FAILURE,
    payload: payload,
  };
};

export const getCateringProductList = (body) => async (dispatch) => {
  dispatch(fetchCateringProductList());
  axios
    .post("/AogGetProducts", body)
    .then((res) => {
      dispatch(fetchCateringProductListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchCateringProductListFailure({ error: error.data.message }));
    });
};



export const resetSelectedCustomOption = (payload) => {
  return {
    type: RESET_SELECTED_CUSTOM_OPTIONS,
    payload: payload,
  };
};


export const handleUpdateCateringAndMealCustomOption = (payload) => {
  return {
    type: UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION,
    payload: payload,
  };
};

export const handleUpdateCateringAndMealCartCustomOption = (payload, productIndex, replace) => {
  return {
    type: UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION,
    payload: payload,
    productIndex: productIndex,
    replace
  };
};

export const resetSelectedCartCustomOption = (payload) => {
  return {
    type: RESET_SELECTED_CART_CUSTOM_OPTIONS,
    payload: payload,
  };
};
