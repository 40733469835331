import {
    FETCH_WEEKLYAD_DATA,
    FETCH_WEEKLYAD_DATA_SUCCESS,
    FETCH_WEEKLYAD_DATA_FAILURE,
  
  } from "redux/constants/actionTypes";
  
  let initialState = {
    weeklyadData: {},
    weeklyadDataLoading: true,
    weeklyadDataError: "",
  };
  
  const weeklyadReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_WEEKLYAD_DATA:
        return {
          ...state,
          weeklyadDataLoading: true,
        };
      case FETCH_WEEKLYAD_DATA_SUCCESS:
        return {
          ...state,
          weeklyadDataLoading: false,
          weeklyadData: action.payload,
        };
      case FETCH_WEEKLYAD_DATA_FAILURE:
        return {
          ...state,
          weeklyadDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default weeklyadReducer;
  