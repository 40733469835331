import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { groupBy } from "lodash";
import {
  getRemoveCateringProductToCartList,
  updateToCartCateringAndMealKitItem,
  handleUpdateCateringAndMealCartCustomOption,
  addToCartCateringAndMealKitItem
} from "redux/actions";
import CateringDropdown from "./CateringDropdown";
import CateringCheckBoxGroup from "./CateringCheckBoxGroup";
import CateringRadioGroup from "./CateringRadioGroup";
import { getLocalStorageValue } from "config/helper";

const CateringCartProduct = ({ product, productIndex }) => {
  const {
    image,
    name,
    qty = 1,
    final_price,
    product_id,
    final_total,
    custom_options_select,
    custom_options_arr,
    item_note,
    meal_cart_id,
    meal_item_id,
    sku
  } = product;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productQuantity] = useState(+qty);
  const [isShowProductButton, setShowProductButton] = useState(false);
  const [updateProductNote, setUpdateProductNote] = useState(item_note ?? "");
  const { currentStoreID } = useSelector(({ store }) => store);
  const { selectedCateringAndMealCartOption } = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal
  );
  const {
    selectedCateringAndMealKitProductUpdateId,
    cateringAndMealKitProductUpdateLoading
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const currentProduct = selectedCateringAndMealCartOption[productIndex] || {};

  const requiredOptions = custom_options_select
    ? custom_options_select.filter((option) => option.is_required === "1")
    : [];
  const requiredOptionsIds = requiredOptions.map((option) => option.id);
  const isDisabledAddToCart = !requiredOptionsIds.every((requiredOptionsId) => {
    return currentProduct[requiredOptionsId]
      ? !!currentProduct[requiredOptionsId].length
      : false;
  });

  const selectedOptionList = Object.values(currentProduct).flat();
  const optionItemList = selectedOptionList.filter(
    (option) => option.is_add_option_price === "1"
  );
  const displayOptionItemList = selectedOptionList.filter(
    (option) => option.is_add_option_price === "0"
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const selectedOptionsByGroup = groupBy(custom_options_arr, "option_id");
    dispatch(
      handleUpdateCateringAndMealCartCustomOption(
        selectedOptionsByGroup,
        productIndex
      )
    );
  }, [custom_options_arr]); // eslint-disable-line

  const [updatebutton, setUpdateButton] = useState();
  const handleUpdateProductToCart = (index) => {
    setUpdateButton(parseInt(index));
    const optionPrice = optionItemList.reduce((acc, cur) => {
      return (acc += +cur.price);
    }, 0);
    const displayOptionPrice = displayOptionItemList.reduce((acc, cur) => {
      return (acc += +cur.price);
    }, 0);

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      qty: productQuantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      item_note: updateProductNote,
      custom_options_arr: JSON.stringify(selectedOptionList) || "",
      option_price: optionPrice || "0",
      display_option_item_price: displayOptionPrice || "",
      meal_item_id
    };

    dispatch(updateToCartCateringAndMealKitItem(body)).then(() => {
      setUpdateButton("");
      setShowProductButton(false);
    });
  };

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const optionPrice = optionItemList.reduce((acc, cur) => {
      return (acc += +cur.price);
    }, 0);
    const displayOptionPrice = displayOptionItemList.reduce((acc, cur) => {
      return (acc += +cur.price);
    }, 0);

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      qty: productQuantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: "",
      custom_options_arr: JSON.stringify(selectedOptionList) || "",
      option_price: optionPrice || "0",
      display_option_item_price: displayOptionPrice || ""
    };

    dispatch(addToCartCateringAndMealKitItem(body));
  };

  const handleRemoveProductToCart = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      meal_item_id,
      meal_cart_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getRemoveCateringProductToCartList(body));
  };

  // const handleIncrementCount = () => {
  //   setProductQuantity(productQuantity + 1);
  // };
  // const handleDecrementCount = () => {
  //   if (productQuantity > 1) {
  //     setProductQuantity(productQuantity - 1);
  //   }

  //   return productQuantity;
  // };

  const handleChangeProductNote = (value) => {
    setUpdateProductNote(value);
    setShowProductButton(true);
  };

  return (
    <div className="cart-items-list-view-section" key={product_id}>
      <div className="sub-grid">
        <div className="sub-grid-items">
          <div className="product-view-grid">
            <div className="product-view-grid-items">
              <div className="product-image-show">
                <img src={image} alt="ProductImage" />
              </div>
            </div>
            <div className="product-view-grid-items">
              <div className="title-icon-mobile-show">
                <div className="mobile-view-title-icon-align">
                  <span></span>
                  <i
                    className="fa-solid fa-trash-can"
                    onClick={handleRemoveProductToCart}></i>
                </div>
              </div>
              <div className="product-name">
                <Link
                  to={`/catering-product-description/${product.department_slug}/${product.slug}`}>
                  <h6>{name}</h6>
                </Link>
                {isShowProductButton && (
                  <div
                    className={`update-button ${
                      isDisabledAddToCart ? "disabled" : ""
                    }`}>
                    <button
                      disabled={isDisabledAddToCart}
                      onClick={() => handleUpdateProductToCart(productIndex)}>
                      {selectedCateringAndMealKitProductUpdateId ===
                        product_id &&
                      productIndex === updatebutton &&
                      cateringAndMealKitProductUpdateLoading
                        ? "Updating.."
                        : "Update"}
                    </button>
                  </div>
                )}
              </div>

              <p className="product-sku">
                UPC: <span> {sku}</span>
              </p>

              <h5>By Best Choice</h5>
              <font>${final_total}</font>
              {/* 
      <div className="rating-alignment">
        <div className="star">
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
        </div>
        <div className="count">
          <span>(4.0)</span>
        </div>
      </div> */}
              <div className="mobile-view-show">
                <div className="mobiel-quantity-button">
                  <button className="disabled">
                    <span>{productQuantity}</span>
                    <div className="arrow-alignment">
                      <div>
                        <i className="fa-solid fa-angle-up"></i>
                      </div>
                      <div>
                        <i className="fa-solid fa-angle-down"></i>
                      </div>
                    </div>
                  </button>
                  <div className="duplicate-item">
                    <span onClick={handleAddToCart}>Duplicate item</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-grid-items">
          <div className="all-action-alignment">
            <div className="price-count">
              <span>${final_price}</span>
            </div>
            <div className="quantity-button ">
              <button className="disabled">
                <span>{productQuantity}</span>
                <div className="arrow-alignment">
                  <div>
                    <i className="fa-solid fa-angle-up"></i>
                  </div>
                  <div>
                    <i className="fa-solid fa-angle-down"></i>
                  </div>
                </div>
              </button>

              <div className="duplicate-item">
                <span onClick={handleAddToCart}>Duplicate item</span>
              </div>
            </div>
            <div className="price-count">
              <span>${final_total}</span>
            </div>
            <div className="remove-icon" onClick={handleRemoveProductToCart}>
              <i className="fa-solid fa-trash-can"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="catering-allow-substitition-grid">
        {custom_options_select &&
          custom_options_select.map((option) => {
            if (option.values && !!option.values.length) {
              return (
                <div key={option.option_id} className="">
                  {option.type === "dropdown" ? (
                    <div className="substitution">
                      <p>
                        <strong>{option.title}</strong>
                        {option.is_required === "1" && <span>*</span>}
                      </p>
                      <div className="select-substitition-dropdown">
                        <CateringDropdown
                          optionList={
                            option.values
                              ? option.values.map((list) => {
                                  return {
                                    title: list.value,
                                    value: list.id,
                                    option: list
                                  };
                                })
                              : []
                          }
                          lable=""
                          placeholder="Select Option"
                          defaultValue=""
                          groupData={option}
                          custom_options_arr={custom_options_arr}
                          productIndex={productIndex}
                          setShowProductButton={() =>
                            setShowProductButton(true)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {option.type === "radio" ? (
                    <CateringRadioGroup
                      groupData={option}
                      custom_options_arr={custom_options_arr}
                      productIndex={productIndex}
                      setShowProductButton={() => setShowProductButton(true)}
                    />
                  ) : (
                    ""
                  )}
                  {option.type === "checkbox" ? (
                    <CateringCheckBoxGroup
                      groupData={option}
                      custom_options_arr={custom_options_arr}
                      productIndex={productIndex}
                      setShowProductButton={() => setShowProductButton(true)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            } else {
              return <div className="allow-substitition-grid-items"></div>;
            }
          })}
      </div>
      <div className="catering-textarea-style">
        <textarea
          onChange={(e) => {
            handleChangeProductNote(e.target.value);
          }}
          placeholder="Enter item notes or substitution notes..."
          value={updateProductNote}></textarea>
      </div>
    </div>
  );
};

export default CateringCartProduct;
