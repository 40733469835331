import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteListItem,
  addOrAddAllProductToCart,
  productMarkUncompleted,
  getCartList,
  productMarkCompleteAll,
} from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const ListProduct = ({ product, list_id, isCompletedGrid }) => {
  const { image, name, qty = 1, price, department_name, product_id } = product;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);

  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const [productQuantity, setProductQuantity] = useState(+qty);

  const handleRemoveProductFromList = (product_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id: list_id,
      product_id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(deleteListItem(body));
  };

  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
  };
  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
    }
  };

  const handleAddToCart = (product_id, qty) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      items_data: [{ product_id, qty }],
      list_id: list_id,
    };

    isUserLoggedIn &&
      dispatch(addOrAddAllProductToCart(body)).then((res) => {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: +storeId || 1,
          AppName: "shop",
          member_number: memberNumber,
          user_token: userToken,
          Version: "1",
          DeviceType: "web",
        };
        isUserLoggedIn && dispatch(getCartList(body));
      });
  };

  const handleClickMarkAsComplete = (product_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id: list_id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      items_data: [{ product_id }],
    };
    dispatch(productMarkCompleteAll(body));
  };

  const handleProductUncomplete = (product_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id: list_id,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      AppName: isUserLoggedIn ? "shop" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      items_data: [{ product_id }],
    };

    isUserLoggedIn && dispatch(productMarkUncompleted(body));
  };

  return (
    <div className="cart-items-list-view-section" key={product_id}>
      <div className="sub-grid">
        <div className="sub-grid-items">
          <div className="product-view-grid">
            <div className="product-view-grid-items">
              <div className="product-image-show">
                <img src={image} alt="ProductImage" />
              </div>
            </div>
            <div className="product-view-grid-items">
              <h6>{name}</h6>
              {isCompletedGrid ? (
                <div className="add-mark-section">
                  <div className="add-to-cart">
                    <button onClick={() => handleProductUncomplete(product_id)}>
                      <span>Uncomplete</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="add-mark-section">
                  {allow_ecommerce === "1" && (
                    <div className="add-to-cart">
                      <button onClick={() => handleAddToCart(product_id, qty)}>
                        <span>Add To Cart</span>
                      </button>
                    </div>
                  )}

                  <div className="add-to-cart">
                    <button
                      onClick={() => handleClickMarkAsComplete(product_id)}
                    >
                      <span>Mark as Completed</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="sub-grid-items"> */}
        <div className="all-action-alignment">
          <div className="quantity-button">
            <button>
              <span>{productQuantity}</span>
              <div className="arrow-alignment">
                <div onClick={handleIncrementCount}>
                  <i className="fa-solid fa-angle-up"></i>
                </div>
                <div onClick={handleDecrementCount}>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </div>
            </button>
          </div>
          <div className="price-count">
          {price > 0 && (
            <h6>{`$${price}`}</h6>
          )}
          {price === 0 && (
            <p style={{color:'red'}}>Not Available</p>
          )}
            
          </div>
          <div className="product-view-grid-items">
            <h6>{department_name}</h6>
          </div>
          <div
            className="remove-icon"
            onClick={() => handleRemoveProductFromList(product_id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </div>
        </div>
        {/* </div> */}

        {/* <div className="sub-grid-items">
            <font>${final_total}</font>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ListProduct;
