import {
  FETCH_BANNER_AND_TESTIMONIALS_DATA,
  FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS,
  FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE,
  FETCH_OFFER_LIST_WITH_PRODUCTS,
  FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS,
  FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE,
  FETCH_PREVIOUS_BUYS_PRODUCT_LIST,
  FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS,
  FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCTS_DATA,
  FETCH_PRODUCTS_DATA_SUCCESS,
  FETCH_PRODUCTS_DATA_FAILURE,
  FETCH_HOME_PAGE_COUPONS_DATA,
  FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS,
  FETCH_HOME_PAGE_COUPONS_DATA_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  bannerAndTestimonialsData: {},
  bannerAndTestimonialsDataLoading: true,
  bannerAndTestimonialsDataError: "",
  offersWithProductData: [],
  offersWithCampaignProductData: [],
  offersWithProductDataLoading: true,
  offersWithProductDataError: "",
  offersType: "",
  previousBuysProductList: [],
  previousBuysProductListLoading: true,
  previousBuysProductListError: "",
  productList: [],
  searchProductLoading: true,
  productListError: "",
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    // Banner and Testimonials data

    case FETCH_BANNER_AND_TESTIMONIALS_DATA:
      return {
        ...state,
        bannerAndTestimonialsDataLoading: true,
      };
    case FETCH_BANNER_AND_TESTIMONIALS_DATA_SUCCESS:
      return {
        ...state,
        bannerAndTestimonialsDataLoading: false,
        bannerAndTestimonialsData: action.payload,
      };
    case FETCH_BANNER_AND_TESTIMONIALS_DATA_FAILURE:
      return {
        ...state,
        bannerAndTestimonialsDataError: action.payload.error,
      };

    // Offer with products

    case FETCH_OFFER_LIST_WITH_PRODUCTS:
      return {
        ...state,
        offersWithProductDataLoading: true,
      };
    case FETCH_OFFER_LIST_WITH_PRODUCTS_SUCCESS:
      return {
        ...state,
        offersWithProductDataLoading: false,
        offersWithProductData: action.payload,
        offersWithCampaignProductData: action.campaing_data,
        offersType: action.payload[0],
      };
    case FETCH_OFFER_LIST_WITH_PRODUCTS_FAILURE:
      return {
        ...state,
        offersWithProductDataError: action.payload.error,
      };

    // Previous products data
    case FETCH_PREVIOUS_BUYS_PRODUCT_LIST:
      return {
        ...state,
        previousBuysProductListLoading: true,
      };
    case FETCH_PREVIOUS_BUYS_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        previousBuysProductListLoading: false,
        previousBuysProductList: action.payload,
      };
    case FFETCH_PREVIOUS_BUYS_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        previousBuysProductListError: action.payload.error,
      };

    // products data
    case FETCH_PRODUCTS_DATA:
      return {
        ...state,
        searchProductLoading: true,
      };
    case FETCH_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        searchProductLoading: false,
        productList: action.payload,
      };
    case FETCH_PRODUCTS_DATA_FAILURE:
      return {
        ...state,
        searchProductLoading: false,
        productListError: action.payload.error,
      };

    // Home page coupons data
    case FETCH_HOME_PAGE_COUPONS_DATA:
      return {
        ...state,
        offersWithProductDataLoading: true,
      };
    case FETCH_HOME_PAGE_COUPONS_DATA_SUCCESS:
      return {
        ...state,
        offersWithProductDataLoading: false,
        offersWithProductData: action.payload,
      };
    case FETCH_HOME_PAGE_COUPONS_DATA_FAILURE:
      return {
        ...state,

        offersWithProductDataError: action.payload.error,
      };

    default:
      return state;
  }
};

export default homeReducer;
