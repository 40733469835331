import React from "react";
import { useSelector } from "react-redux";
import CampaignProduct from "./CampaignProduct/CampaignProduct";
import "./campaign-section.scss";
import ProductCardTheme2 from "components/ProductCardTheme2";

const CampaignSection = () => {
  const offersWithCampaignProductData = useSelector(
    ({ homepage }) => homepage.offersWithCampaignProductData
  );

  return (
    <>
      {offersWithCampaignProductData?.map((campaign) => {
        return (
          <>
            {campaign.campaign_products?.length > 0 && (
              <div className="campaign-of-week-theme2">
                <div className="container">
                  <div className="container-fluid">
                    <div className="product-row">
                      <CampaignProduct campaign={campaign} />
                      {campaign.campaign_products &&
                        campaign.campaign_products?.length > 0 && (
                          <div className="product-col-sm-8">
                            <div className="product-row deal-all-wrapper">
                              {campaign.campaign_products
                                .slice(0, 6)
                                .map((product) => {
                                  return (
                                    <>
                                      <ProductCardTheme2
                                        product={product}
                                        key={product.id}
                                      />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default CampaignSection;
