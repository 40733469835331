import {
  FETCH_COUPONS_LIST_DATA,
  FETCH_COUPONS_LIST_DATA_SUCCESS,
  FETCH_COUPONS_LIST_DATA_FAILURE,
  FETCH_COUPONS_CATEGORIES_DATA,
  FETCH_COUPONS_CATEGORIES_SUCCESS,
  FETCH_COUPONS_CATEGORIES_FAILURE,
  UPDATE_COUPONS_CATEGORIES_LIST,
  FETCH_AVAILABLE_COUPONS_SUCCESS,
  FETCH_AVAILABLE_COUPONS_FAILURE,
  FETCH_AVAILABLE_COUPONS,
  APPLY_AVAILABLE_COUPONS,
  APPLY_AVAILABLE_COUPONS_SUCCESS,
  APPLY_AVAILABLE_COUPONS_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch COUPONLIST data
export const fetchCouponListDetails = (payload) => {
  return {
    type: FETCH_COUPONS_LIST_DATA,
    payload: payload,
  };
};
export const fetchCouponListDetailsSuccess = (payload) => {
  return {
    type: FETCH_COUPONS_LIST_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchCouponListDetailsFailure = (payload) => {
  return {
    type: FETCH_COUPONS_LIST_DATA_FAILURE,
    payload: payload,
  };
};

export const updateCouponList = (payload) => {
  return {
    type: UPDATE_COUPONS_CATEGORIES_LIST,
    payload: payload,
  };
};

export const fetchCouponCategories = (payload) => {
  return {
    type: FETCH_COUPONS_CATEGORIES_DATA,
    payload: payload,
  };
};
export const fetchCouponCategoriesSuccess = (payload) => {
  return {
    type: FETCH_COUPONS_CATEGORIES_SUCCESS,
    payload: payload,
  };
};
export const fetchCouponCategoriesFailure = (payload) => {
  return {
    type: FETCH_COUPONS_CATEGORIES_FAILURE,
    payload: payload,
  };
};

// fetch available coupons
export const fetchAvailableCoupons = (payload) => {
  return {
    type: FETCH_AVAILABLE_COUPONS,
    payload: payload,
  };
};
export const fetchAvailableCouponsSuccess = (payload) => {
  return {
    type: FETCH_AVAILABLE_COUPONS_SUCCESS,
    payload: payload,
  };
};
export const fetchAvailableCouponsFailure = (payload) => {
  return {
    type: FETCH_AVAILABLE_COUPONS_FAILURE,
    payload: payload,
  };
};

// apply available coupons
export const applyAvailableCoupons = (payload) => {
  return {
    type: APPLY_AVAILABLE_COUPONS,
    payload: payload,
  };
};
export const applyAvailableCouponsSuccess = (payload) => {
  return {
    type: APPLY_AVAILABLE_COUPONS_SUCCESS,
    payload: payload,
  };
};
export const applyAvailableCouponsFailure = (payload) => {
  return {
    type: APPLY_AVAILABLE_COUPONS_FAILURE,
    payload: payload,
  };
};

export const getCouponListDetails = (body) => async (dispatch) => {
  dispatch(fetchCouponListDetails());

  axios
    .post("/AogGetDetailsForCoupons", body)
    .then((res) => {
      dispatch(fetchCouponListDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchCouponListDetailsFailure({ error: error.data.message }));
    });
};

export const getCouponCategoriesDetails = (body) => async (dispatch) => {
  dispatch(fetchCouponCategories());

  axios
    .post("/AogGetCategoriesForCoupons", body)
    .then((res) => {
      dispatch(fetchCouponCategoriesSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchCouponCategoriesFailure({ error: error.data.message }));
    });
};

export const getAvailableCoupons = (body) => async (dispatch) => {
  dispatch(fetchAvailableCoupons());

  axios
    .post("/AogGetCoupons", body)
    .then((res) => {
      dispatch(fetchAvailableCouponsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchAvailableCouponsFailure({ error: error.data.message }));
    });
};

export const getAppliedAvailableCoupons = (body) => async (dispatch) => {
  dispatch(applyAvailableCoupons());

  return axios
    .post("/AogCouponApply", body)
    .then((res) => {
      dispatch(applyAvailableCouponsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(applyAvailableCouponsFailure({ error: error.data.message }));
    });
};
