import React, { useState } from "react";
import Slider from "react-slick/lib/slider";
import ImageFadeIn from "react-image-fade-in";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import "./product-preview-slider.scss";

const ProductPreviewSlider = ({ image, gallery }) => {
  const [activeSlide, setActiveSlide] = useState();

  const handleSlider = (slide) => {
    setActiveSlide(slide);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="product-preview-slider">
        <div className="selected-product">
          <ImageFadeIn src={activeSlide || image} />
        </div>
        <div className="child-images">
          <Slider {...settings}>
            {gallery.map((slide, index) => {
              return (
                <div key={index}>
                  <div
                    className={
                      activeSlide === slide.file
                        ? "active procut-child-image"
                        : "procut-child-image"
                    }
                  >
                    <img
                      src={slide.file}
                      alt="ChildProductImage"
                      onClick={() => handleSlider(slide.file)}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default ProductPreviewSlider;
