import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./promotion-card.scss";


const PromotionCard = () => {
  
  const promotionDataLists = useSelector(
    ({ common }) => common.commonDetailsData.promotion_data_list
  );

  const getPromotionCartContent = (item) => {

    let isUrlLink = 0;
    if(item.url === "") {
      isUrlLink = 2;
    } else {
      isUrlLink = (item.url.match(/^(http|https):\/\/?/))?1:0;
    }
    switch (isUrlLink) {
      case 0:
        return (
          <>
          <div className="icon-center-align">
            <Link
              to={`/campaign/${item.url}`}
            >
              <img src={item.image} alt="WideIcon" />
            </Link>
          </div>
          <p className="department">Promotion</p>
            <Link
              to={`/campaign/${item.url}`}
            ><h2>{item.title}</h2>
            </Link>
          </>
        );
      case 1:
        return (
          <>
          <div className="icon-center-align">
            <a href={item.url}>
              <img src={item.image} alt="WideIcon" />
            </a>
          </div>
          <p className="department">Promotion</p>
          <a href={item.url}><h2>{item.title}</h2></a>
          </>
        );
      default:
        return (
          <>
          <div className="icon-center-align">
              <img src={item.image} alt="WideIcon" />
          </div>
          <p className="department">Promotion</p>
          <h2>{item.title}</h2>
          </>
        );
    }
  }
  return (
    <>
      {promotionDataLists && promotionDataLists.section_list.length > 0?(
      <div className="promotion-section">
        <div className="container">
          <div className="page-title">
              <h1>
                {promotionDataLists.highlist_title ? promotionDataLists.highlist_title : ""}
              </h1>
            </div>
          <div className="grid">
            {promotionDataLists.section_list &&
              promotionDataLists.section_list.map((item) => {
                return (
                  <div className="grid-items">
                      {getPromotionCartContent(item)}
                  </div>
                );
              })}
          </div>
          
        </div>
      </div>
      ):("")}
    </>
  );
};
export default PromotionCard;
