


import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
    saveEbtPaymentResponse,
    savePlaceOrderSuccess,
    savePlaceOrderPaidList
} from "redux/actions";
import './ebt-checkout-confirmation.scss'
import { useDispatch, useSelector } from 'react-redux';
import axios from "config/axios";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';

const EBTCheckoutInformation = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { currentStoreID } = useSelector(({ store }) => store);

    const user_token = getLocalStorageValue("user-token");
    const member_number = getLocalStorageValue("member-number");
    const clientId = getLocalStorageValue("RSAclient-id");
    const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

    const [ebtPaymentResponse, setEbtPaymentResponse] = useState(null)

    const {
        // ebt_payment_status = "",
        status = "",
        message = "",
        is_payment = ""
    } = ebtPaymentResponse || {}


    const {
        // customer_id = "",
        // ebt_final_amount = "",
        last_new_order_id = "",
        ebt_initiate_tran_id = "",
        ebt_card_balance_info = ""
    } = (ebtPaymentResponse && ebtPaymentResponse.data) || {}

    useEffect(() => {
        if (orderId) {
            const decodeData = window.atob(orderId)
            setEbtPaymentResponse(JSON.parse(decodeData))
        }
    }, [orderId]) // eslint-disable-line

    useEffect(() => {
        if (orderId) {
            dispatch(saveEbtPaymentResponse(null))
            const decodeData = window.atob(orderId)
            setEbtPaymentResponse(JSON.parse(decodeData))
        }
    }, [orderId]) // eslint-disable-line

    useEffect(() => {
        if (ebtPaymentResponse && status !== "error") {
            dispatch(saveEbtPaymentResponse(ebtPaymentResponse))
            if (is_payment === "complete") {
                fetchCompleteOrderDetail()
            }
            if (is_payment === "pending") {

                fetchPendingOrderDetail()
                setLocalStorageValue('last_new_order_id', last_new_order_id)
            }
        } else {
            dispatch(saveEbtPaymentResponse(null))
        }
    }, [ebtPaymentResponse]) // eslint-disable-line

    const fetchCompleteOrderDetail = () => {
        const body = {
            RSAClientId: clientId,
            ClientStoreId: +storeId || 1,
            member_number,
            last_new_order_id: last_new_order_id,
            user_token,
            AppName: "shop",
            DeviceType: "web",
            Version: 1,
        };

        axios
            .post("/AogGetTransactionDetails", body)
            .then((res) => {
                dispatch(savePlaceOrderSuccess(res.data.data.order_details));
                dispatch(savePlaceOrderPaidList(res.data.data.paid_list));
                navigate("/order-confirmation");
            })
            .catch((error) => { });
    }

    const fetchPendingOrderDetail = () => {
        const body = {
            RSAClientId: clientId,
            ClientStoreId: +storeId || 1,
            member_number,
            last_new_order_id: last_new_order_id,
            user_token,
            AppName: "shop",
            DeviceType: "web",
            Version: 1,
        };

        axios
            .post("/AogGetTransactionDetails", body)
            .then((res) => {
                dispatch(savePlaceOrderSuccess(res.data.data.order_details));
                dispatch(savePlaceOrderPaidList(res.data.data.paid_list));
                navigate("/checkout");
            })
            .catch((error) => { });
    }


    return (
        <>
            <div className="ebt-checkout-confirmation-screen">
                <div className="container">
                    {ebtPaymentResponse && status !== "error" ?
                        <div className="transaction-info">
                            <h3 className="payment-status">{message}</h3>
                            <h4 className="payment-transaction-id">Your EBT Payment Transaction ID is : {ebt_initiate_tran_id}</h4>
                            <span className="card-bal-info">{ebt_card_balance_info}</span>
                            <span>Please wait...</span>
                        </div>
                        : ""}

                    {ebtPaymentResponse && status === "error" ?
                        <div className="transaction-info">
                            <h3 className="payment-status-error">{message}</h3>
                        </div>
                        : ""}
                </div>
            </div>
        </>
    );
};

export default EBTCheckoutInformation;
