import React, { useEffect } from "react";
import { useDispatch} from "react-redux";
 
import {  resetSearch } from "redux/actions";

import "./details.scss"
import Thumbnail from "assets/images/thumbnail.jpeg";

const Details = () => {
  const dispatch = useDispatch();
  
  
  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

 
  return (
   <>
      <div className="container">
        <div className="grid-flex">
          <div className="col col-image" style={{ backgroundImage: 'url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/280406/seattle-botanical-gardens.jpg)' }}>
          </div>
          <div className="col col-text">

            <div className="Aligner-item">
              <h1>Visit Kingman's favorite grocery store</h1>
              <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum
              </p>
            </div>
          </div>
        </div>

        <div className="grid-flex">
          <div className="col col-image" style={{ backgroundImage: `URL(https://s3-us-west-2.amazonaws.com/s.cdpn.io/280406/seattle-library-sm.jpg)` }}>
            &nbsp;
          </div>
          <div className="col col-text col-left">

            <div className="Aligner-item">
              <h1>Visit Kingman's favorite grocery store</h1>

              <p>&ldquo;  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum
              </p>
            </div>
          </div>
        </div>

        <div className="grid-flex">
          <div className="col col-image" style={{ backgroundImage: `URL(https://s3-us-west-2.amazonaws.com/s.cdpn.io/280406/madison-overture.jpg)` }}>
            &nbsp;
          </div>
          <div className="col col-text">
            <div className="Aligner-item">
              <h1>Visit Kingman's favorite grocery store</h1>

              <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum
              </p>
            </div>
          </div>
        </div>

        <div className="grid-flex">
          <div className="col col-image" style={{ backgroundImage: `URL(https://s3-us-west-2.amazonaws.com/s.cdpn.io/280406/seattle-skylight-sm.jpg)` }}>
            &nbsp;
          </div>
          <div className="col col-text col-left">

            <div className="Aligner-item">
              <h1>Visit Kingman's favorite grocery store</h1>

              <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum
              </p>
            </div>
          </div>
        </div>

        <div className="store-video">

          <div className="store-video__container">

            <img src={Thumbnail} alt='video' />

          </div>

        </div>

      </div>

  
    </>
  );
};
export default Details;
