import {
  FETCH_FEEDBACK_DETAILS,
  FETCH_FEEDBACK_DETAILS_SUCCESS,
  FETCH_FEEDBACK_DETAILS_FAILURE,

  FETCH_CONTACTSERVICES_DETAILS,
  FETCH_CONTACTSERVICES_DETAILS_SUCCESS,
  FETCH_CONTACTSERVICES_DETAILS_FAILURE
} from "redux/constants/actionTypes";
import axios from "config/axios";

export const fetchFeedbackDetails = (payload) => {
  return {
    type: FETCH_FEEDBACK_DETAILS,
    payload: payload,
  };
};

export const fetchFeedbackSuccess = (payload) => {
  return {
    type: FETCH_FEEDBACK_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchFeedbackFailure = (payload) => {
  return {
    type: FETCH_FEEDBACK_DETAILS_FAILURE,
    payload: payload,
  };
};


export const fetchContactservicesList = (payload) => {
  return {
    type: FETCH_CONTACTSERVICES_DETAILS,
    payload: payload,
  };
};

export const fetchContactservicesListSuccess = (payload) => {
  return {
    type: FETCH_CONTACTSERVICES_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchContactservicesListFailure = (payload) => {
  return {
    type: FETCH_CONTACTSERVICES_DETAILS_FAILURE,
    payload: payload,
  };
};

export const userFeedback = (body) => async (dispatch) => {
  dispatch(fetchFeedbackDetails());
  axios
    .post("/AogContactUs", body)
    .then((res) => {
      dispatch(fetchFeedbackSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchFeedbackFailure({ error: error.response.data.message })
      );
    });
};



export const contactServicesList = (body) => async (dispatch) => {
  dispatch(fetchContactservicesList());
  axios
    .post("/AogGetContactServicesList", body)
    .then((res) => {
      dispatch(fetchContactservicesListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchContactservicesListFailure({ error: error.response.data.message })
      );
    });
};