import React from "react";
import LeftArrow from "assets/icons/left.svg";
import RightArrow from "assets/icons/right.svg";
import "./slider-dots.scss";

const SliderDots = (props) => {
  const { pages, activeSlide, handleActiveDot, onPrev, onNext } = props;

  const dots = Array.from({ length: pages }, (_, i) => i + 1);

  return (
    <div>
      <div className="slider-dots">
        <div className="slider-dots-wrapper">
          {activeSlide !== 1 ? (
            <div onClick={onPrev} className={"left-arrow"}>
              <img src={LeftArrow} alt="LeftArrow" />
            </div>
          ) : (
            <div className={"left-arrow"}>
              <img src={LeftArrow} alt="LeftArrow" />
            </div>
          )}

          {!activeSlide + 1 < pages ? (
            <>
              {dots.map((dot, index) => {
                return (
                  <button
                    key={index}
                    className={
                      activeSlide === dot ? "page-counter active-dots" : ""
                    }
                    onClick={() => handleActiveDot(dot)}
                  >
                    {dot}
                  </button>
                );
              })}
            </>
          ) : (
            <button className="active-dots">1</button>
          )}
          {activeSlide !== pages && activeSlide > 0 ? (
            <div onClick={onNext} className={"right-arrow"}>
              <img src={RightArrow} alt="RightArrow" />
            </div>
          ) : (
            <div className={"right-arrow"}>
              <img src={RightArrow} alt="RightArrow" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SliderDots;
