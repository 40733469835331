import React from "react";
import "./awards-card.scss";

const awardCard = ({ awardData }) => {
  
  return (
    <>
      <div className="award-card-section">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <img
                src={
                  awardData
                    ? awardData.image
                    : ""
                }
                alt="AboutImage"
              />
            </div>
            <div className="grid-items">
              <h1>
                {awardData
                  ? awardData.awards_title
                  : ""}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: awardData
                    ? awardData.short_description
                    : "",
                }}
              >
                {}
              </p>
              {/* <span>
                            Joe and Frances retired in the mid-1990’s. Joe’s love for the business was inspired by
                            visits to his paternal grandparents in SE Kansas who operated small grocery stores in that region. After working at several stores in Dodge City, KS, and Denver, CO, he realized a dream with the purchase of his first store.
                        </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default awardCard;
