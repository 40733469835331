import React, { useState } from "react";
import ProductPreview from "./ProductPreview";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import recipesImg from 'assets/images/recipes.png'
import "./recipe-product-preview.scss";


const RecipeProductPreview = ({ productDetails }) => {
  const {
    title,
    image,
    summary,
    preparationMinutes,
    cookingMinutes,
    readyInMinutes,
    servings,
    dishTypes,
    sourceName,
    creditsText,
    license,
    diets,
    nutrition
  } = productDetails;

  const [isShowSeeMore, setShowSeeMore] = useState(false);

  let summaryText = summary

  if (isShowSeeMore) {
    summaryText = summaryText.slice(0, 200) + '...'
  }

  const calories = nutrition?.nutrients?.find(cal => cal.name === "Calories") ?? 0

  return (
    <div className="recipe-product-preview-section">
      <div className="container">
        <div className="grid">
          <div className="grid-items">
            <div>
              <ProductPreview image={image} gallery={[]} />
            </div>
          </div>
          <div className="grid-items">
            <h1>{title}</h1>

            <div className="summary-text">
              <ReactHtmlParser
                htmlString={summaryText || ""}
              />
            </div>
            <div className="see-more" onClick={() => setShowSeeMore(!isShowSeeMore)}>
              <p>{isShowSeeMore ? 'See More' : 'See Less'}</p>
            </div>
            {calories ? <p className="cal">{calories.amount} {calories.unit}</p> : ""}

            <div className="department-code">
              {preparationMinutes > 0 && (
                <div>
                  <p>
                    <strong>PREP:</strong> <span>{preparationMinutes}</span> mins
                  </p>
                </div>
              )}
              {cookingMinutes > 0 && (
                <div>
                  <p>
                    <strong>COOK:</strong> <span>{cookingMinutes}</span> mins
                  </p>
                </div>
              )}
              {readyInMinutes > 0 && (
                <div>
                  <p>
                    <strong>READY IN:</strong> <span>{readyInMinutes}</span> mins
                  </p>
                </div>
              )}
              {servings > 0 && (
                <div>
                  <p>
                    <strong>SERVINGS:</strong> <span>{servings}</span> people
                  </p>
                </div>
              )}
            </div>
            <div className="department-code">
              <div>
                <p>
                  <strong>Dish Types:</strong> <span>{dishTypes.join(', ')}</span>
                </p>
              </div>
            </div>
            <div className="other-price-details">
              {sourceName && <p>Courtesy of: {sourceName}</p>}
              {creditsText && <p>Credit: {creditsText}</p>}
              {license && <p>License: {license}</p>}
            </div>
          </div>

          {!!diets.length && (
            <div className="grid-items">
              <div className="diets-wrapper">
                {diets.map(dite => {
                  return <div className="diet-item" key={dite}>
                    <img src={recipesImg} alt="boneless chicken thighs" />
                    <span>{dite} </span>
                  </div>
                })}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};
export default RecipeProductPreview;
