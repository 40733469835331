import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddressSection from "./AddressSection";
import CheckoutInformation from "./CheckoutInformation";
import DeliveryInfo from "./DeliveryInfo";
import AddressInfo from "./AddressInfo";
import SpecialComment from "./SpecialComment";
import "./checkout-info.scss";

const storeTypeSlots = {
  store_pickup: "pickup_slots",
  delivery: "delivery_slots",
};

const CheckoutInfo = () => {
  const [isShowAddress, setShowAddress] = useState(false);

  const {
    storeTimeSlots,
    customerAddressList = [],
    checkoutStoreType,
  } = useSelector(({ checkout }) => checkout);

  const selectDateAndTimeOptions =
    storeTimeSlots &&
    storeTimeSlots[storeTypeSlots[checkoutStoreType]] &&
    storeTimeSlots[storeTypeSlots[checkoutStoreType]]
      .map((slot) => slot.process_slots)
      .flat()
      .map((process_slot) => {
        return {
          title: process_slot.shipping_date_day_time,
          value: process_slot,
        };
      });





  useEffect(() => {
    if (checkoutStoreType === 'delivery') {
      !customerAddressList.length && setShowAddress(true);
    }
    if (checkoutStoreType === 'store_pickup') {
      setShowAddress(true);
    }
  }, [checkoutStoreType]); // eslint-disable-line


  const handleAddNewAddress = () => {
    setShowAddress(false);
  };

  const isAddressList = () => {
    setShowAddress(true);
  };

  const handleSelectPaymentMethod = (method) => {
    if (method === "store_pickup") {
      setShowAddress(true);
    }
  }

  return (
    <div className="checkout-info-section">
      <div className="container">
        <div className="grid">
          <div className="grid-items">
            <DeliveryInfo selectDateAndTimeOptions={selectDateAndTimeOptions} handleSelectPaymentMethod={handleSelectPaymentMethod} />

            {isShowAddress && (
              <AddressInfo handleAddNewAddress={handleAddNewAddress} />
            )}
            {!isShowAddress && <AddressSection isAddressList={isAddressList} />}
            <SpecialComment />
          </div>

          <div className="grid-items">
            <CheckoutInformation />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutInfo;
