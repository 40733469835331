import React, { useEffect, useState } from "react";
import CartItems from "./CartItems";
import {
  getcouponcartlist,
  getremoveCouponcartlist,
} from "redux/actions/cartCoupon";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "components/Common/Breadcrumb";
import "./couponview-cart.scss";
import { getWeeklyadgallery } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const CouponViewCart = () => {
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const [isAddingCouponCart, setAddingCouponToCart] = useState(false);

  const { couponCartList } = useSelector(({ cartCoupon }) => cartCoupon);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getWeeklyadgallery(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn &&
      dispatch(getcouponcartlist(body)).then(() => {
        setAddingCouponToCart(false);
      });
  }, [isAddingCouponCart]); // eslint-disable-line

  const breadcrumbList = [{ lable: "Coupon List", link: "" }];

  const handleRemoveCouponToCart = (offer_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      offer_id: offer_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(getremoveCouponcartlist(body)).then(() => {
      setAddingCouponToCart(true);
    });
  };

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div className="container coupon-cart-section-align">
        <div className="coupon-cart-title">
          <h1>Clipped Coupons</h1>
        </div>
        <div className="coupon-page-grid-align">
          <div>
            <p>
              There are{" "}
              {couponCartList.length > 0 ? couponCartList.length : "0"} coupons
              clipped.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="coupon-cart-section">
          <div className="coupon-cart-grid">
            <div className="cart-grid-items">
              <CartItems
                couponCartList={couponCartList}
                onRemovecopuon={handleRemoveCouponToCart}
              />
            </div>
            <div className="cart-grid-items">
              {/* <CouponSpecialPromotion couponList={coupons_section} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CouponViewCart;
