import React from "react";

const SearchDropdown = ({
  productKeyWord,
  setIsShowSearchDropdown,
  onSearchChange,
}) => { 
  return (
    <li
      onClick={() => {
        setIsShowSearchDropdown();
        onSearchChange(productKeyWord.keyword_title.replace(/(<([^>]+)>)/ig, ''));
      }}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: productKeyWord
            ? productKeyWord.keyword_title
            : "",
        }}
      >
        {}
      </p>
    </li>
  );
};

export default SearchDropdown;
