import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addcouponToCart } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';
import "./coupon-card.scss";
import DescriptionModal from "./Description";

const CouponCard = ({ coupon, handleCouponcartList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contentModal, setContentModal] = useState("");
  const [contentDescription, setcontentDescription] = useState("");
  const [contentExpire, setExpireon] = useState("");
  const [image, setImage] = useState("");

  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const storeId = getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userFirstName = getLocalStorageValue("first-name");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const [isAddingCouponCart, setAddingCouponToCart] = useState(false);

  const { couponCartList, addCouponToCartListLoading } = useSelector(
    ({ cartCoupon }) => cartCoupon
  );



  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: memberNumber,
      offer_id: coupon.offer_id,
      news_category_id: coupon.news_category_id,
      user_first_name: userFirstName,
      ncr_promotion_code: coupon.ncr_promotion_code,
      is_in_ncr_impressions: false,
      title: coupon.title,
      details: coupon.details,
      amount: coupon.amount,
      product_name: coupon.product_name,
      real_valid_from: coupon.real_expires_on,
      real_expires_on: coupon.real_valid_from,
      upc: "",
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };

    setAddingCouponToCart(true);
    isUserLoggedIn &&
      dispatch(addcouponToCart(body)).then(() => {
        setAddingCouponToCart(false);
        handleCouponcartList();
      });
  };

  const offerId = coupon.offer_id;
  const addedCoupon =
    couponCartList &&
    couponCartList.find((coupon) => coupon.offer_id === offerId);


  return (
    <>
      <div className="coupon-card" key={coupon.offer_id}>
        {coupon.coupon_limit ? (
          <div className="coupon-used-time-ribbon">
            {coupon.coupon_limit === "1" ? '' :

              <div className="coupon-used-time">
                <p>
                  <span>{coupon.coupon_limit + 'X'}</span>
                </p>

              </div>
            }
          </div>
        ) : (
          ""
        )}

        {coupon.plu_code === "100.00%" ? (
          <div className="coupon-design-ribbon">
            <div className="coupon-design">
              <p>
                <span>Free!</span>
              </p>
            </div>
          </div>
        ) : (
          <div className="coupon-design-ribbon">
            <div className="coupon-design">
              <p>
                <span>{coupon.plu_code}</span>
              </p>
            </div>
          </div>
        )}

        <div className="product-card-image">
          <div className="modal__btn">
            <a
              href={() => false}
              onClick={() => {
                Toggle();
                setContentModal(coupon.title);
                setcontentDescription(coupon.details);
                setExpireon(coupon.expires_on);
                setImage(coupon.image_path);
              }}
            >
              <img src={coupon.image_path} alt="ProductImage" />
            </a>
          </div>
        </div>

        <div className="product-card-details">
          <h3>{`Save ${coupon.plu_code}`}</h3>
          <p className="coupon-details"> {coupon?.details}</p>

          <div className="button-group-alignment">
            <div className="add-to-cart">
              <button onClick={handleAddToCart} disabled={addedCoupon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="11.575"
                  viewBox="0 0 14 11.575"
                >
                  <g
                    id="Group_111"
                    data-name="Group 111"
                    transform="translate(-132.997 -1553.667)"
                  >
                    <g
                      id="Group_111-2"
                      data-name="Group 111"
                      transform="translate(133.997 1554.667)"
                    >
                      <path
                        id="Subtraction_1"
                        data-name="Subtraction 1"
                        d="M9.539,8.826a.244.244,0,0,1-.127-.039H4.7a.227.227,0,0,1-.254,0c-.046,0-.1,0-.16,0H4.038a2.346,2.346,0,0,1-.319-.018,1.075,1.075,0,0,1-.883-.851,1.1,1.1,0,0,1,.516-1.173.123.123,0,0,0,.074-.165C2.988,4.55,2.561,2.548,2.2.858,2.186.788,2.158.766,2.085.766H1.162c-.19,0-.441,0-.693.006H.45A.435.435,0,0,1,0,.5V.306A.359.359,0,0,1,.4,0L1.463,0h.919a.431.431,0,0,1,.473.391c.042.188.083.379.122.565l0,.01.014.066.045.213c.046.212.046.212.253.212h8.277a.628.628,0,0,1,.191.022A.33.33,0,0,1,12,1.788v.17a6.265,6.265,0,0,0-.19.636c-.022.085-.044.168-.066.251-.276.993-.546,1.972-.845,3.058L10.6,7a.375.375,0,0,1-.425.328H3.895a.4.4,0,0,0-.118.014.365.365,0,0,0-.26.384.361.361,0,0,0,.334.33l.065,0h6.293a.49.49,0,0,1,.1.007.364.364,0,0,1,.287.386.357.357,0,0,1-.341.334c-.134,0-.269,0-.4,0H9.666A.243.243,0,0,1,9.539,8.826ZM6.859,6.6l3.008,0a.122.122,0,0,0,.142-.109c.2-.723.394-1.436.612-2.227l.224-.812c.112-.407.223-.813.336-1.224H3.218c0,.006,0,.01,0,.014a.077.077,0,0,0,0,.017l.147.683.056.261c.232,1.083.472,2.2.706,3.3.018.086.06.093.128.093Z"
                        transform="translate(0 0)"
                        fill="#fff"
                        stroke="rgba(0,0,0,0)"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_63"
                        data-name="Path 63"
                        d="M369.843,385.073h.215a.93.93,0,0,1,.612.366.858.858,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C369.807,385.085,369.828,385.09,369.843,385.073Z"
                        transform="translate(-365.426 -376.279)"
                        fill="#fff"
                      />
                      <path
                        id="Path_64"
                        data-name="Path 64"
                        d="M581.767,385.073h.215a.93.93,0,0,1,.611.366.857.857,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C581.731,385.085,581.752,385.09,581.767,385.073Z"
                        transform="translate(-571.964 -376.279)"
                        fill="#fff"
                      />
                      <path
                        id="Path_66"
                        data-name="Path 66"
                        d="M399.355,415.569a.3.3,0,1,1,.3-.294A.3.3,0,0,1,399.355,415.569Z"
                        transform="translate(-394.799 -405.584)"
                        fill="#fff"
                      />
                      <path
                        id="Path_67"
                        data-name="Path 67"
                        d="M611.574,415.273a.3.3,0,1,1-.3-.3A.3.3,0,0,1,611.574,415.273Z"
                        transform="translate(-601.704 -405.586)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>

                <span>
                  {isUserLoggedIn
                    ? addCouponToCartListLoading && isAddingCouponCart
                      ? "Adding..."
                      : addedCoupon
                        ? "Added to List"
                        : 'Add'
                    : "Sign in to Add"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <DescriptionModal
        show={modal}
        title="My Modal"
        close={Toggle}
        contentModal={contentModal}
        contentDescription={contentDescription}
        contentExpire={contentExpire}
        image={image}
        addedCoupon={addedCoupon}
        addtoCart={handleAddToCart}
        isUserLoggedIn={isUserLoggedIn}
        addCouponToCartListLoading={addCouponToCartListLoading}
        isAddingCouponCart={isAddingCouponCart}
      />
    </>
  );
};

export default CouponCard;
