import {
    FETCH_JOBS_LIST,
    FETCH_JOBS_LIST_SUCCESS,
    FETCH_JOBS_LIST_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch contact reason list
  export const fetchJobsList = (payload) => {
    return {
      type: FETCH_JOBS_LIST,
      payload: payload,
    };
  };
  export const fetchJobsListSuccess = (payload) => {
    return {
      type: FETCH_JOBS_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const fetchJobsListFailure = (payload) => {
    return {
      type: FETCH_JOBS_LIST_FAILURE,
      payload: payload,
    };
  };
  
  export const getJobsList = (body) => async (dispatch) => {
    dispatch(fetchJobsList());
  
    axios
      .post("/AogGetCarrersDetails", body)
      .then((res) => {
        dispatch(fetchJobsListSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchJobsListFailure({ error: error.data.message }));
      });
  };
  