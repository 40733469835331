import React from "react";
import "./provide-section.scss";

const CustomProvider = ({ section }) => {
  return (
    <div>
      <div className="provide-section">
        <div className="container">
          <div className="provide-section-title">
            <h1>{section.highlight_title}</h1>
          </div>
          <div className="grid">
            {section.custom_child_section.map((item) => {
              return (
                <div className="grid-items" key={item.cms_new_pages_child_section_id}>
                  <div className="icon-center-align">
                    {item.click_on_image_redirect_url ? (
                        <a href={item.click_on_image_redirect_url} target="_blank" rel="noreferrer">
                          <img src={item.image} alt="WideIcon" />
                        </a>
                    ) : (
                      <img src={item.image} alt="WideIcon" />
                    )}
                    
                  </div>
                  <h2>{item.title}</h2>
                  <p>{item.short_description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomProvider;
