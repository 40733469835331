import React from "react";
import { uniqBy } from 'lodash'

const Equipment = ({ steps }) => {

    const equipmentList = steps.map(eq => eq.equipment).flat()
    const equipmentListUniqBy = uniqBy(equipmentList, 'name');

    return (
        <div className="equipment-wrapper">
            {equipmentListUniqBy.map(eq => {
                return <div className="equipment" key={eq.id}>
                    <img src={'https://spoonacular.com/cdn/equipment_100x100/' + eq.image} alt={eq.name} />
                    <span>{eq.name}</span>
                </div>
            })}
        </div>
    );
};
export default Equipment;
