import React from "react";

const Nutrition = ({ nutrition }) => {

    const nutritionList = nutrition && nutrition.nutrients ? nutrition.nutrients : []

    return (
        <div className="nutrition-wrapper">
            {nutritionList && nutritionList.length ? nutritionList.map(eq => {
                return <div className="nutrition" key={eq.id}>
                    <span>{eq.name}</span>
                    <span>{eq.amount}{eq.unit}</span>
                </div>
            }) : "No nutrition info found."}
        </div>
    );
};
export default Nutrition;
