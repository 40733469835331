import {
  FETCH_CONTACT_REASON_LIST,
  FETCH_CONTACT_REASON_LIST_SUCCESS,
  FETCH_CONTACT_REASON_LIST_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch contact reason list
export const fetchContactReasonList = (payload) => {
  return {
    type: FETCH_CONTACT_REASON_LIST,
    payload: payload,
  };
};
export const fetchContactReasonListSuccess = (payload) => {
  return {
    type: FETCH_CONTACT_REASON_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchContactReasonListFailure = (payload) => {
  return {
    type: FETCH_CONTACT_REASON_LIST_FAILURE,
    payload: payload,
  };
};

export const getContactReasonList = (body) => async (dispatch) => {
  dispatch(fetchContactReasonList());

  axios
    .post("/AogGetContactReasonList", body)
    .then((res) => {
      dispatch(fetchContactReasonListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchContactReasonListFailure({ error: error.data.message }));
    });
};
