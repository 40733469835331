import {
  FETCH_CONTACT_REASON_LIST,
  FETCH_CONTACT_REASON_LIST_SUCCESS,
  FETCH_CONTACT_REASON_LIST_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  contactReasonList: [],
  contactReasonListLoading: true,
  contactReasonListError: "",
};

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACT_REASON_LIST:
      return {
        ...state,
        contactReasonListLoading: true,
      };
    case FETCH_CONTACT_REASON_LIST_SUCCESS:
      return {
        ...state,
        contactReasonListLoading: false,
        contactReasonList: action.payload,
      };
    case FETCH_CONTACT_REASON_LIST_FAILURE:
      return {
        ...state,
        contactReasonListError: action.payload.error,
      };

    default:
      return state;
  }
};

export default contactUsReducer;
