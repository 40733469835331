import React, { useState } from "react";  
//import MapImage from "assets/images/map.png";
import Markerimg from "assets/images/marker.png";
import "./store-location.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

 
const markerIcon = new L.Icon({
  iconUrl: Markerimg,
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46] //[left/right, top/bottom]
});


const Location = ({ storeList }) => {
   
  const defaultCity = storeList.slice(0, 1).map((item) => {
    return item.ClientStoreName;
  });

  const [selectedCity] = useState(defaultCity.toString()); 
   
  
  const storeSingleData = storeList.find(
    (storeListData) => storeListData.ClientStoreName === selectedCity
  );

  const position = [storeSingleData.Latitude, storeSingleData.Longitude];
  const popupcity = [storeSingleData.ClientStoreName ];
  const popupstate = [storeSingleData.StateName ];
  const popupphone = [storeSingleData.StorePhoneNumber ];
  
  const formatPhoneNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
   
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
 
   if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
   };
 
   return null
 };
 
  return (
    <>
      <div className="container">
        <div className="tabs">
          
        </div>
      </div>

      {storeSingleData ? (
        <div className="container">
          <div className="location-section">
            <div className="grid">

              <div className="grid-items">
                <h2>{storeSingleData.City}</h2>
                <p>{storeSingleData.AddressLine1}</p>
                <p>
                  {storeSingleData.AddressLine2}, {storeSingleData.ZipCode}
                </p>
                <a
                  href={`tel:${storeSingleData.StorePhoneNumber}`}
                  rel="noreferrer"
                >
                  Phone: {formatPhoneNumber(storeSingleData.StorePhoneNumber)}
                </a>
                <a
                  href={`mailto:${storeSingleData.StoreEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Email: {storeSingleData.StoreEmail}
                </a>
                <div className="time-text">

                <table className="coupon-cart-table">
                <tbody>
                     <tr>
                      <td data-label="Image">Monday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>
                     <tr>
                      <td data-label="">Tuesday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>
                 
                     <tr>
                      <td data-label="">Wednesday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>
                 
                     <tr>
                      <td data-label="">Thursday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>
                 
                     <tr>
                      <td data-label="">Friday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>
                     <tr>
                      <td data-label="">Saturday 
                      </td>
                      <td data-label="Hours"> {storeSingleData.StoreTimings}</td>
                     </tr>   
              </tbody>
            </table>      
            </div>   
              </div>

              <div className="grid-items">
                <div className="map-image" style={{height:'400px',overflow:'hidden'}}>
                  {/* <img src={MapImage} alt="MapImage" /> */}

                  <MapContainer
                        className="markercluster-map"
                        center={position}
                        zoom={7}
                        maxZoom={14}

                        scrollWheelZoom={false}
                       >
                        <TileLayer
                         //url="https://api.maptiler.com/maps/topographique/256/{z}/{x}/{y}.png?key=Jwldj418wFeWAGThBHQF"
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?key=Jwldj418wFeWAGThBHQF"
                          attribution=' <a href="http://osm.org/copyright"></a>'
                        />
                           <Marker
                            position={position}
                            icon={markerIcon}                             
                          >
                            <Popup>
                              <b>
                               {popupcity}, {popupstate}, 
                              </b><br/>
                              <b>Phone: {popupphone}</b> 
                            </Popup>
                          </Marker>
                      
                      </MapContainer>
                </div>
                <div className="store-loc-details" style={{float:'right'}}>
               <button className="store-loc-details__button">View Weekly Add</button>
               <button className="store-loc-details__button">Shop Online</button>
               </div>
              </div>

           


            </div>

          
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Location;
