import {
    ADD_COUPON_PRODUCT_TO_CART_LIST,
    ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS,
    ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE,
    FETCH_COUPON_CART_LIST,
    FETCH_COUPON_CART_LIST_SUCCESS,
    FETCH_COUPON_CART_LIST_FAILURE,
 
    REMOVE_COUPON_TO_CART_LIST,
    REMOVE_COUPON_TO_CART_LIST_SUCCESS,
    REMOVE_COUPON_TO_CART_LIST_FAILURE

  } from "redux/constants/actionTypes";
  let initialState = {
    
     
    couponCartList: [],
    addCouponToCartListLoading: false,
    addCouponToCartListLoadingError: "",

    couponCartListLoading:false,
    couponCartListLoadingError:"",
    couponresponseCartList: [],

    removeCouponToCartListLoading:true,
    removeCouponToCartListError:false
    
    

      };
  
  const cartCoupon = (state = initialState, action) => {
    switch (action.type) {
      // Add to cart list
  
      case ADD_COUPON_PRODUCT_TO_CART_LIST:
        return {
          ...state,
          addCouponToCartListLoading: true,
        };
      case ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS:
        return {
          ...state,
          addProductToCartListLoading: false,
         
          couponCartList: [...state.couponCartList, action.payload],
          couponresponseCartList:[...state.couponresponseCartList,action.payload]
        };
      case ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE:
        return {
          ...state,
          addCouponToCartListLoadingError: action.payload.error,
        };


          // fetch cart list
    case FETCH_COUPON_CART_LIST:
        return {
          ...state,
          couponCartListLoading: true,
        };
      case FETCH_COUPON_CART_LIST_SUCCESS:
        return {
          ...state,
          couponCartListLoading: false,
          couponCartList: action.payload,
        };
      case FETCH_COUPON_CART_LIST_FAILURE:
        return {
          ...state,
          couponCartListLoadingError: action.payload.error,
        };

  
  // remove cart list

    case REMOVE_COUPON_TO_CART_LIST:
      const cartCouponList = state.couponCartList;
      return {
        ...state,
        removeProductToCartListLoading: true,
        couponCartList: cartCouponList.filter(
          (coupon) => coupon.offer_id !== action.payload
        ),
      };
    case REMOVE_COUPON_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        removeCouponToCartListLoading: false,
      };
    case REMOVE_COUPON_TO_CART_LIST_FAILURE:
      return {
        ...state,
        removeCouponToCartListError: action.payload.error,
      };

  

      default:
        return state;
    }
  };
  
  export default cartCoupon;
  