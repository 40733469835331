import {
  FETCH_BLOG_LIST,
  FETCH_BLOG_LIST_SUCCESS,
  FETCH_BLOG_LIST_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch blog data
export const fetchBlogDetails = (payload) => {
  return {
    type: FETCH_BLOG_LIST,
    payload: payload,
  };
};
export const fetchBlogDetailsSuccess = (payload) => {
  return {
    type: FETCH_BLOG_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchBlogDetailsFailure = (payload) => {
  return {
    type: FETCH_BLOG_LIST_FAILURE,
    payload: payload,
  };
};

export const getBlogDetails = (body) => async (dispatch) => {
  dispatch(fetchBlogDetails());

  axios
    .post("/AogGetBlogs", body)
    .then((res) => {
      dispatch(fetchBlogDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchBlogDetailsFailure({ error: error.data.message }));
    });
};
