import {
    FETCH_CAMPAIGN_PRODUCT_LIST,
    FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS,
    FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch about data
  export const fetchCampaignproduct = (payload) => {
    return {
      type: FETCH_CAMPAIGN_PRODUCT_LIST,
      payload: payload,
    };
  };
  export const fetchCampaignproductSuccess = (payload) => {
    return {
      type: FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const fetchCampaignproductFailure = (payload) => {
    return {
      type: FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE,
      payload: payload,
    };
  };
  
  export const getCampaignproduct = (body) => async (dispatch) => {
    dispatch(fetchCampaignproduct());
  
    axios
      .post("/AogGetCampaginProducts", body)
      .then((res) => {
        dispatch(fetchCampaignproductSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchCampaignproductFailure({ error: error.data.message }));
      });
  };
  