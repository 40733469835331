import {
   
    FETCH_FOOTER_HELP_DETAILS,
    FETCH_FOOTER_HELP_DETAILS_SUCCESS,
    FETCH_FOOTER_HELP_DETAILS_FAILURE
  
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
   
  // fetach footer help data
  export const fetchfooterHelpDetails = (payload) => {
    return {
      type: FETCH_FOOTER_HELP_DETAILS,
      payload: payload,
    };
  };
  export const fetchfooterHelpDetailsSuccess = (payload) => {
    return {
      type: FETCH_FOOTER_HELP_DETAILS_SUCCESS,
      payload: payload,
    };
  };
  export const fetchfooterHelpDetailsFailure = (payload) => {
    return {
      type: FETCH_FOOTER_HELP_DETAILS_FAILURE,
      payload: payload,
    };
  };
  
  export const getFooterhelpdetails = (body) => async (dispatch) => {
    dispatch(fetchfooterHelpDetails());
  
    axios
      .post("/AogGetPageContent", body)
      .then((res) => {
        dispatch(fetchfooterHelpDetailsSuccess(res.data.data));
      })
      .catch((error) => {
  
        dispatch(fetchfooterHelpDetailsFailure({ error: error.data.message }));
      });
  };
  