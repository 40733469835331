import React from "react";
import DropdownListItem from "components/Common/Dropdown/DropdownListItem";

const CategoryDropdown = (props) => {
  const { isShow, dropdownData, onSelectOption, selectedSearchType } = props;

  return (
    <div
      className={
        isShow
          ? "categories-menu dropdown-show"
          : "categories-menu dropdown-hidden"
      }
    >
      <div className="list-menu">
        <ul>
          {dropdownData &&
            dropdownData.map((dropdownOption, index) => {
              return (
                <DropdownListItem
                  optionName={dropdownOption.name}
                  optionValue={dropdownOption.search_type}
                  onSelectOption={onSelectOption}
                  key={index}
                  selectedOptionValue={selectedSearchType}
                  onClose={() => {}}
                />
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default CategoryDropdown;
