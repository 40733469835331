import React from "react";
import PromotionCard from "./PromotionCard";

const PromotionSection = () => {
  
  return (
    <div>
      <>
        <PromotionCard />
      </>
  </div>
  );
};
export default PromotionSection;
