import React from "react";
import "./custom-add-space-section-feature.scss";
import { Link } from "react-router-dom";
const CustomBanner = ({ section }) => {
  return (
    <div className="ads-space-section-seller">
      {section.click_on_image_redirect_url ? (
        <a href={section.click_on_image_redirect_url} target="_blank" rel="noreferrer">
          <img
            src={section.image}
            alt="banner"
          />
        </a>
      ) : (
        <img
          src={section.image}
          alt="banner"
        />
      )}
    </div>
  );
};
export default CustomBanner;
