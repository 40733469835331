import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import RecipeCard from "../RecipesCard";
import Pagination from "components/Common/Pagination";
import { getRecipesList, getRecipesRandomList } from "redux/actions";
import "./recipes-items.scss";

const RecipesList = ({ recipeList, recipeListTotalRecord }) => {
  const dispatch = useDispatch();
  const { searchKeyWord } = useParams();
  const [page, setPage] = useState(1);
  const pageLimit = 120;
  const totalPages = Math.ceil(recipeListTotalRecord / pageLimit);

  useEffect(() => {
    if (page === 1) {
      setPage(1);
    }
  }, [dispatch, page]);

  useEffect(() => {
    if (searchKeyWord) {
      setPage(1);
    }
  }, [dispatch, searchKeyWord]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    const offset = (data - 1) * pageLimit;
    scrollToTop();
    setPage(data);
    if (searchKeyWord !== "Popular" && searchKeyWord) {
      dispatch(
        getRecipesList(
          `offset=${offset}&number=120&type=${searchKeyWord ? searchKeyWord : ""}`
        )
      );
    } else {
      dispatch(getRecipesRandomList(`offset=${offset}&number=120&limitLicense=true`));
    }
  };

  return (
    <div>
      <div className="recipes-list-grid">
        {recipeList &&
          recipeList.map((product) => {
            return (
              <div className="recipes-list-grid-items" key={product.id}>
                <RecipeCard product={product} isShowButton={true} />
              </div>
            );
          })}
      </div>
      {recipeList.length ? (
        <Pagination pages={totalPages} current={page} onClick={handleSetPage} />
      ) : (
        ""
      )}
    </div>
  );
};
export default RecipesList;
