import React from "react";
import { Link } from "react-router-dom";
import "./download-our-app.scss";

const DownloadOurApp = () => {
  return (
    <div className="download-app-wrapper">
      <div className="download-app">
        <Link className="secondary-button" to={`/mobapp`} rel="noreferrer">
          Download our App
        </Link>
      </div>
    </div>
  );
};

export default DownloadOurApp;
