import {
    FETCH_EMPLOYMENT_DETAILS,
    FETCH_EMPLOYMENT_DETAILS_SUCCESS,
    FETCH_EMPLOYMENT_DETAILS_FAILURE,

    
  } from "redux/constants/actionTypes";
  
  let initialState = {
    
    employmentLoading: false,
    employmentStatus: "",
    employmentMessage: "",

     
   
  };
  
  const employmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_EMPLOYMENT_DETAILS:
        return {
          ...state,
  
          employmentLoading: true,
          employmentStatus: "",
        };
      case FETCH_EMPLOYMENT_DETAILS_SUCCESS:
        return {
          ...state,
          employmentLoading: false,
          employmentStatus: action.payload.status,
          employmentMessage: action.payload.message,
        };
      case FETCH_EMPLOYMENT_DETAILS_FAILURE:
        return {
          ...state,
          employmentStatus: action.payload.error,
        };

       

      
      default:
        return state;
    }
  };
  
  export default employmentReducer;
  