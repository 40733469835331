import {
  UPDATE_CUSTOMER_PROFILE_DETAILS,
  UPDATE_CUSTOMER_PROFILE_SUCCESS,
  UPDATE_CUSTOMER_PROFILE_FAILURE,
  UPDATE_CUSTOMER_PIN_DETAILS,
  UPDATE_CUSTOMER_PIN_SUCCESS,
  UPDATE_CUSTOMER_PIN_FAILURE,
  FETCH_ORDERS_DATA,
  FETCH_ORDERS_DATA_SUCCESS,
  FETCH_ORDERS_DATA_FAILURE,
  FETCH_WISHLIST_DATA,
  FETCH_WISHLIST_DATA_SUCCESS,
  FETCH_WISHLIST_DATA_FAILURE,
  FETCH_CUSTOMER_PROFILE_DETAILS,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAILURE,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_CATERING_MEALS_ORDERS_DATA,
  FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS,
  FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE,
  CANCEL_ORDER_BY_CUSTOMER,
  CANCEL_ORDER_BY_CUSTOMER_SUCCESS,
  CANCEL_ORDER_BY_CUSTOMER_FAILURE,
  FETCH_EBT_CARD_INFORMATION,
  FETCH_EBT_CARD_INFORMATION_SUCCESS,
  FETCH_EBT_CARD_INFORMATION_FAILURE,
  CLEAR_MESSAGE_FOR_UPDATE_PROFILE,
  CLEAR_MESSAGE_FOR_CHANGE_PASSWORD
} from "redux/constants/actionTypes";
import axios from "config/axios";

export const UpdateCustomerDetails = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PROFILE_DETAILS,
    payload: payload,
  };
};

export const UpdateCustomerSuccess = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PROFILE_SUCCESS,
    payload: payload,
  };
};

export const UpdateCustomerFailure = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PROFILE_FAILURE,
    payload: payload,
  };
};

export const updateCustomerProfile = (body) => async (dispatch) => {
  dispatch(UpdateCustomerDetails());
  axios
    .post("/AogCustomerUpdateProfile", body)
    .then((res) => {
      dispatch(UpdateCustomerSuccess(res.data));
    })
    .catch((error) => {
      dispatch(UpdateCustomerFailure({ error: error.response.data.message }));
    });
};

export const fetchCustomerOrderDetails = (payload) => {
  return {
    type: FETCH_ORDERS_DATA,
    payload: payload,
  };
};

export const fetchCustomerOrderSuccess = (payload) => {
  return {
    type: FETCH_ORDERS_DATA_SUCCESS,
    payload: payload,
  };
};

export const fetchCustomerOrderFailure = (payload) => {
  return {
    type: FETCH_ORDERS_DATA_FAILURE,
    payload: payload,
  };
};

export const getCustomerOrderdetails = (body) => async (dispatch) => {
  dispatch(fetchCustomerOrderDetails());
  axios
    .post("/AogGetMyOrder", body)
    .then((res) => {
      dispatch(fetchCustomerOrderSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchCustomerOrderFailure({ error: error.response.data.message })
      );
    });
};

//catering and mealkit

export const fetchCateringAndMealKitCustomerOrderDetails = (payload) => {
  return {
    type: FETCH_CATERING_MEALS_ORDERS_DATA,
    payload: payload,
  };
};
export const fetchCateringAndMealKitCustomerOrderDetailsSuccess = (payload) => {
  return {
    type: FETCH_CATERING_MEALS_ORDERS_DATA_SUCCESS,
    payload: payload,
  };
};

export const fetchCateringAndMealKitCustomerOrderDetailsFailure = (payload) => {
  return {
    type: FETCH_CATERING_MEALS_ORDERS_DATA_FAILURE,
    payload: payload,
  };
};

export const getCateringAndMealKitCustomerOrderDetails =
  (body) => async (dispatch) => {
    dispatch(fetchCateringAndMealKitCustomerOrderDetails());
    axios
      .post("/GetMyCateringAndMealKitOrdersList", body)
      .then((res) => {
        dispatch(fetchCateringAndMealKitCustomerOrderDetailsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(
          fetchCateringAndMealKitCustomerOrderDetailsFailure({
            error: error.response.data.message,
          })
        );
      });
  };

export const fetchCustomerPassword = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PIN_DETAILS,
    payload: payload,
  };
};

export const fetchCustomerPasswordSuccess = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PIN_SUCCESS,
    payload: payload,
  };
};

export const fetchCustomerPasswordFailure = (payload) => {
  return {
    type: UPDATE_CUSTOMER_PIN_FAILURE,
    payload: payload,
  };
};

export const updateCustomerPassword = (body) => async (dispatch) => {
  dispatch(fetchCustomerPassword());
  axios
    .post("/AogCustomerChangePassword", body)
    .then((res) => {
      dispatch(fetchCustomerPasswordSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchCustomerPasswordFailure({ error: error.response.data.message })
      );
    });
};

export const fetchCustomerWishlist = (payload) => {
  return {
    type: FETCH_WISHLIST_DATA,
    payload: payload,
  };
};

export const fetchCustomerWishlistSuccess = (payload) => {
  return {
    type: FETCH_WISHLIST_DATA_SUCCESS,
    payload: payload,
  };
};

export const fetchCustomerWishlistFailure = (payload) => {
  return {
    type: FETCH_WISHLIST_DATA_FAILURE,
    payload: payload,
  };
};

export const getCustomerWishlist = (body) => async (dispatch) => {
  dispatch(fetchCustomerWishlist());
  axios
    .post("/AogGetWishlist", body)
    .then((res) => {
      dispatch(fetchCustomerWishlistSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchCustomerWishlistFailure({ error: error.response.data.message })
      );
    });
};

export const fetchCustomerDetails = (payload) => {
  return {
    type: FETCH_CUSTOMER_PROFILE_DETAILS,
    payload: payload,
  };
};

export const fetchCustomerDetailsSuccess = (payload) => {
  return {
    type: FETCH_CUSTOMER_PROFILE_SUCCESS,
    payload: payload,
  };
};

export const fetchCustomerDetailsFailure = (payload) => {
  return {
    type: FETCH_CUSTOMER_PROFILE_FAILURE,
    payload: payload,
  };
};

export const getCustomerDetails = (body) => async (dispatch) => {
  dispatch(fetchCustomerDetails());
  axios
    .post("/AogGetCustomer", body)
    .then((res) => {
      dispatch(fetchCustomerDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchCustomerDetailsFailure({ error: error.response.data.message })
      );
    });
};

export const fetchOrderDetails = (payload) => {
  return {
    type: FETCH_ORDER_DETAILS,
    payload: payload,
  };
};

export const fetchOrderDetailsSuccess = (payload) => {
  return {
    type: FETCH_ORDER_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchOrderDetailsFailure = (payload) => {
  return {
    type: FETCH_ORDER_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getOrderDetails = (body) => async (dispatch) => {
  dispatch(fetchOrderDetails());
  axios
    .post("/AogGetMyOrderDetails", body)
    .then((res) => {
      dispatch(fetchOrderDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchOrderDetailsFailure({ error: error.response.data.message })
      );
    });
};

export const getCateringMealsOrderDetails = (body) => async (dispatch) => {
  dispatch(fetchOrderDetails());
  axios
    .post("/GetMyCateringAndMealKitOrderDetails", body)
    .then((res) => {
      dispatch(fetchOrderDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchOrderDetailsFailure({ error: error.response.data.message })
      );
    });
};


export const cancelOrderByCustomer = (payload) => {
  return {
    type: CANCEL_ORDER_BY_CUSTOMER,
    payload: payload,
  };
};

export const cancelOrderByCustomerSuccess = (payload) => {
  return {
    type: CANCEL_ORDER_BY_CUSTOMER_SUCCESS,
    payload: payload,
  };
};

export const cancelOrderByCustomerFailure = (payload) => {
  return {
    type: CANCEL_ORDER_BY_CUSTOMER_FAILURE,
    payload: payload,
  };
};

export const cancelOrder = (body) => async (dispatch) => {
  dispatch(cancelOrderByCustomer());
  return axios
    .post("/AogOrderCancelByCustomer", body)
    .then((res) => {
      dispatch(cancelOrderByCustomerSuccess(res.data.data));
      return res
    })
    .catch((error) => {
      dispatch(
        cancelOrderByCustomerFailure({ error: error.response.data.message })
      );
    });
};


//EBT CARD INFO
export const fetchEbtCardInformation = (payload) => {
  return {
    type: FETCH_EBT_CARD_INFORMATION,
    payload: payload,
  };
};

export const fetchEbtCardInformationSuccess = (payload) => {
  return {
    type: FETCH_EBT_CARD_INFORMATION_SUCCESS,
    payload: payload,
  };
};

export const fetchEbtCardInformationFailure = (payload) => {
  return {
    type: FETCH_EBT_CARD_INFORMATION_FAILURE,
    payload: payload,
  };
};

export const getEbtCardInformation = (body) => async (dispatch) => {
  dispatch(fetchEbtCardInformation());
  return axios
    .post("/EBTBalanceInquiry", body)
    .then((res) => {
      dispatch(fetchEbtCardInformationSuccess(res.data));
      return res
    })
    .catch((error) => {
      dispatch(fetchEbtCardInformationFailure({ error: error.response.data.message }));
    });
};

export const clearUpdateProfileMessage = () => ({
  type: CLEAR_MESSAGE_FOR_UPDATE_PROFILE,
});

export const clearChangePasswordMessage = () => ({
  type: CLEAR_MESSAGE_FOR_CHANGE_PASSWORD,
});