import {
  FETCH_SEARCH_RESULT_LIST,
  FETCH_SEARCH_RESULT_LIST_SUCCESS,
  FETCH_SEARCH_RESULT_LIST_FAILURE,
  UPDATE_SEARCH_TEXT,
  UPDATE_SEARCH_TYPE,
  REMOVE_SEARCH_TAG,
  RESET_SEARCH,
} from "redux/constants/actionTypes";

let initialState = {
  searchResultList: [],
  searchResultListLoading: true,
  searchResultListError: "",
  searchText: "",
  searchType: "products",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_RESULT_LIST:
      return {
        ...state,
        searchResultListLoading: true,
      };
    case FETCH_SEARCH_RESULT_LIST_SUCCESS:
      return {
        ...state,
        searchResultListLoading: false,
        searchResultList: action.payload,
      };
    case FETCH_SEARCH_RESULT_LIST_FAILURE:
      return {
        ...state,
        searchResultListLoading: false,
        searchResultListError: action.payload.error,
      };

    case UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };

    case UPDATE_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case REMOVE_SEARCH_TAG:
      return {
        ...state,
        searchText:
          state.searchText &&
          state.searchText
            .split(" ")
            .filter((tag) => tag !== action.payload)
            .join(" "),
      };
    case RESET_SEARCH:
      return {
        ...state,
        searchText: "",
      };
    default:
      return state;
  }
};

export default searchReducer;
