import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import "./proceed-checkout.scss";
import { getLocalStorageValue } from 'config/helper';

const ProceedCheckout = ({ productCartList, cartType }) => {
  const { productCartDetails } = useSelector(({ cartWishlist }) => cartWishlist);
  const { storeListData } = useSelector(({ store }) => store);

  const cartTotalAmount =
    productCartList &&
    productCartList.reduce((acc, prodcut) => {
      return acc + +prodcut.final_total;
    }, 0);


  const ebtProducts = productCartList.filter(product => product.ebt_item === "1")
  const nonEbtProducts = productCartList.filter(product => product.ebt_item === "0")

  const ebtCartTotalAmount =
    ebtProducts &&
    ebtProducts.reduce((acc, prodcut) => {
      return acc + +prodcut.final_total;
    }, 0);

  const nonEbtCartTotalAmount =
    nonEbtProducts &&
    nonEbtProducts.reduce((acc, prodcut) => {
      return acc + +prodcut.final_total;
    }, 0);


  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  const {
    allow_delivery = "0",
    allow_pickup = "0",
    minimum_delivery_order_amount = "0",
    minimum_pickup_order_amount = "0"
  } = productCartDetails

  let buttonDisable = false
  let minimum_order_amount_message = ""

  // enable disable button flag based on remaining amount and pickup or delivery
  if (allow_delivery === "1" && minimum_delivery_order_amount < cartTotalAmount) {
    buttonDisable = true
  } else if (allow_pickup === "1" && minimum_pickup_order_amount < cartTotalAmount) {
    buttonDisable = true
  }
  else if ((allow_delivery === "1" && minimum_delivery_order_amount < cartTotalAmount) || (allow_pickup === "1" && minimum_pickup_order_amount < cartTotalAmount)) {
    buttonDisable = true
  }

  // min amount msg based on remaining amount and pickup or delivery
  if ((allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount) && (allow_pickup === "1" && minimum_pickup_order_amount > cartTotalAmount)) {
    minimum_order_amount_message = `Min: <span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span><span className="danger">✘</span> &nbsp;&nbsp;&nbsp;&nbsp; <span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  }
  else if (allow_pickup === "1" && allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount && minimum_pickup_order_amount <= cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="success">$${minimum_pickup_order_amount} (pickup)✔</span>&nbsp;&nbsp;&nbsp;&nbsp; <span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  } else if (allow_pickup === "1" && allow_delivery === "1" && minimum_pickup_order_amount > cartTotalAmount && minimum_delivery_order_amount <= cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span> <span className="danger">✘</span>&nbsp;&nbsp;&nbsp;&nbsp; <span className="success">$${minimum_delivery_order_amount} (delivery)✔</span>`
  }
  else if (allow_pickup === "1" && minimum_pickup_order_amount > cartTotalAmount) {
    minimum_order_amount_message = `Min: <span className="delivery-text">$${minimum_pickup_order_amount} (pickup)</span><span className="danger">✘</span>`
  } else if (allow_delivery === "1" && minimum_delivery_order_amount > cartTotalAmount) {
    minimum_order_amount_message = `Min:<span className="delivery-text">$${minimum_delivery_order_amount} (delivery)</span> <span className="danger">✘</span>`
  }

  return (
    <div className="proceed-checkout">
      <div className="box-title">
        <p>Store: {selectedStore && selectedStore.ClientStoreName}</p>
      </div>
      <div className="box-body">
        {(!!ebtProducts.length && !!nonEbtProducts.length) ? (
          <>
            <div className="all-text-alignment">
              <p>EBT Items(s) Total</p>
              <h2>{ebtCartTotalAmount ? `$${ebtCartTotalAmount.toFixed(2)}` : "$ 0"}</h2>
            </div>
            <div className="all-text-alignment">
              <p>Non EBT Items(s) Total</p>
              <h2>{nonEbtCartTotalAmount ? `$${nonEbtCartTotalAmount.toFixed(2)}` : "$ 0"}</h2>
            </div>
          </>
        ) : <div className="all-text-alignment">
          <p>Subtotal</p>
          <h2>{cartTotalAmount ? `$${cartTotalAmount.toFixed(2)}` : "$ 0"}</h2>
        </div>}
        <div className="all-text-alignment">
          <p>Total</p>
          <h2>{cartTotalAmount ? `$${cartTotalAmount.toFixed(2)}` : "$ 0"}</h2>
        </div>
        {!cartType && (
          <div className="proceed-to-checkout">
            <ReactHtmlParser
              htmlString={minimum_order_amount_message || ""}
            />
            <div className="ebt-message">* Any applicable coupons will be applied at the time of checkout.</div>
            {!!ebtProducts.length && <div className="ebt-message">Min order amount not applicable to orders with only EBT items</div>}
            {!!ebtProducts.length ? <Link to="/checkout">
              <button>
                <span>Proceed to Checkout</span>
                <i className="fa-solid fa-right-long"></i>
              </button>
            </Link> : buttonDisable ? (
              <Link to="/checkout">
                <button>
                  <span>Proceed to Checkout</span>
                  <i className="fa-solid fa-right-long"></i>
                </button>
              </Link>
            ) : (
              <button className="disabled">
                <span>Proceed to Checkout</span>
                <i className="fa-solid fa-right-long"></i>
              </button>
            )}
          </div>
        )}
        {cartType === "catering-meal" && (
          <div className="proceed-to-checkout">
            <Link to="/checkout/catering-meal">
              <button>
                <span>Proceed to Checkout</span>
                <i className="fa-solid fa-right-long"></i>
              </button>
            </Link>

          </div>
        )}
      </div>
    </div>
  );
};
export default ProceedCheckout;
