import React from "react";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

import "./welcome-white-foodliners.scss";

const DepartmentInformation = ({ singleDepartmentData }) => {
  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );

  return (
    <>
      {couponDepartmentmenu.length > 0 && (
        <div className="welcome-white-foodliners-coupon-department">
          <div className="container">
            <div className="grid">
              <div className="grid-items">
                <img
                  src={
                    singleDepartmentData
                      ? singleDepartmentData.department_image_url
                      : ""
                  }
                  alt="WhitesAboutImage"
                />
              </div>
              <div className="grid-items">
                <h1>
                  {singleDepartmentData
                    ? singleDepartmentData.department_name
                    : ""}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: singleDepartmentData
                      ? singleDepartmentData.department_description
                      : "",
                  }}
                >
                  {}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DepartmentInformation;
