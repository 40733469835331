import React from "react";

const Tab = ({ tab, isActive, onSelectTab }) => {
  return (
    <li
      className={isActive ? "active-offer-type" : ""}
      onClick={() => onSelectTab(tab.id)}
    >
      {tab.title}
    </li>
  );
};
export default Tab;
