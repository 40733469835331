import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, saveList, makeListActive } from "redux/actions";
import useOnClickOutside from "hooks/useOnClickOutside";
import "./add-ingredients-to-my-list.scss";
import { getLocalStorageValue } from 'config/helper';

const AddIngredientsToMyList = ({
  isShowMyListDropdown,
  isShowNewListModal,
  setShowNewListModal,
  setShowMyListDropdown,
  handleApply,
  disabled,
  loading,
}) => {
  const refDropdown = useRef();
  const refDropdownSecond = useRef();
  const dispatch = useDispatch();
  const [listText, setListText] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  const customerLists = useSelector(
    ({ customlist }) => customlist.customerList
  );
  useOnClickOutside(refDropdown, () => setShowNewListModal(false));
  useOnClickOutside(refDropdownSecond, () => setShowMyListDropdown(false));

  useEffect(() => {
    const activeList = customerLists.find((item) => {
      return item.status === "1";
    });
    !activeItem && activeList && setActiveItem(activeList.list_id);
  }, [customerLists]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    isShowMyListDropdown && !customerLists.length && dispatch(getList(body));
  }, [isShowMyListDropdown]); // eslint-disable-line

  const addItems = (data) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      list_name: data,
      image: "",
    };
    dispatch(saveList(body));
  };

  const handleListStatus = (list_id) => {
    setActiveItem(list_id);
    const activeList = customerLists.find((item) => {
      return item.status === "1";
    });
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id,
      member_number: memberNumber,
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };

    !activeList && dispatch(makeListActive(body));
  };

  return (
    <div
      className={`recipe-add-list-btn ${disabled ? "disabled" : ""}`}
      ref={refDropdownSecond}
    >
      {isShowMyListDropdown && (
        <div
          className={
            isShowMyListDropdown
              ? "add-to-list-dropdown add-to-list-show"
              : "add-to-list-dropdown add-to-list-hidden"
          }
        >
          <div className="add-list-dropdown-design">
            <div className="new-list-title">
              <p onClick={() => setShowNewListModal(!isShowNewListModal)}>
                New List
              </p>
              <div
                className={
                  isShowNewListModal
                    ? "new-list-input-dropdown add-to-list-show"
                    : "new-list-input-dropdown add-to-list-hidden"
                }
              >
                <div className="new-list-input-list-style" ref={refDropdown}>
                  <div className="form-control">
                    <label>Enter List Name</label>
                    <input
                      type="text"
                      onChange={(e) => setListText(e.target.value)}
                    />
                  </div>
                  <div
                    className="save-number"
                    onClick={() => setShowNewListModal(false)}
                  >
                    <span onClick={() => addItems(listText)}>Save</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown-body">
              {customerLists && customerLists.length
                ? customerLists.map((item) => {
                  return (
                    <div className="relative-checkbox" key={item.list_id}>
                      <input
                        type="checkbox"
                        id={item.list_name}
                        onClick={() => handleListStatus(item.list_id)}
                        checked={item.list_id === activeItem ? true : false}
                        defaultChecked={activeItem}
                      />
                      <label htmlFor={item.list_name}>
                        <span>{item.list_name}</span>
                      </label>
                    </div>
                  );
                })
                : "No list found."}
              {loading ? (
                <div className="apply-text-alignment">
                  <p>Adding.. </p>
                </div>
              ) : (
                customerLists &&
                !!customerLists.length && (
                  <div className="apply-text-alignment">
                    <p onClick={() => handleApply(activeItem)}>Add to List </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddIngredientsToMyList;
