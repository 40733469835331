import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import Jobs from "./Jobs";
import "./career.scss";
import { getJobsList, resetSearch } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";

const Coupon = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { jobs_section: jobList = [] } = useSelector(
    ({ career }) => career.joblistData
  );

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getJobsList(body));
  }, [dispatch, storeId]); // eslint-disable-line

  const breadcrumbList = [{ lable: "Careers", link: "", searchTag: "" }];

  const jobBenifits = useSelector(
    ({ career }) => career.joblistData.jobs_benefits
  );

  return (
    <>
      <>
        <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`Careers`} />

        <div className="container">
          <div className="career-page-wrapper">
            <div className="listing-page-wrapper-items">
              {jobList && <Jobs jobList={jobList} />}
            </div>

            {jobBenifits && jobBenifits.length > 0 && (
              <div className="listing-page-wrapper-items">
                <div className="col-lg-4 sidebar">
                  <div className="single-slidebar">
                    <h4>Our Benefits</h4>
                    <ul className="cat-list">
                      {jobBenifits.map((items, index) => {
                        return (
                          <li key={index}>
                            <a
                              className="justify-content-between d-flex"
                              href="javascript:;"
                            >
                              <p>{items.benefits_title}</p>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};
export default Coupon;
