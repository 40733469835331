/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterIcon from "assets/icons/filter-icon.svg";
import CloseIcon from "assets/icons/white-close.svg";
import loader from "assets/images/loader.gif";

import {
  emptyIftCheckList,
  updatePageLimit,
  updateSortByValue,
  updateIftCheckList,
  updateNodeFuctionalText,
  updateNodeFunctionalLevel,
} from "redux/actions";
import ListingPageSidebarTheme2 from "../SidebarTheme2";
import "./actions.scss";
import { useNavigate, useSearchParams } from "react-router-dom";

const ActionsTheme2 = ({
  setCouponConditionChange,
  couponCondition,
  pageLimit,
  setPageLimit,
  sortByOption,
  listLimitOption,
  productListByDepartmentTotalCount,
  priceSection,
  departmentTree,
  departmentTreeLoading,
  otherDepartmentTree,
  iftOptionList,
  maxPriceValue,
  minPriceValue,
  isShowReorder,
  handleClickAddAllToCart,
  querySearchKey,
  redirectBaseUrl,
  departmentSlug,
  activeChildIndex,
  setActiveChildIndex,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const {
    pastOrderCheckedList,
    fromCheckedList,
    sortByValue,
    productListByDepartmentLoading,
    filterOptions,
    iftCheckedList,
    weeklySalesSelected,
    selectedDepartmentName
  } = useSelector(({ product }) => product);
  const { uncompleteProductMessage } = useSelector(
    ({ customlist }) => customlist
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowMobileFilter, setIsShowMobileFilter] = useState(false);

  const [sortType, setSortType] = useState("");

  useEffect(() => {
    if (!sortByValue) {
      setSortType("");
    }
  }, [sortByValue]); // eslint-disable-line

  const handleChangeOption = (_, value) => {
    setPageLimit(value);
    dispatch(updatePageLimit(value));
  };

  const handleChangeSortFilter = (sortBy, value) => {
    setSortType(value);
    dispatch(updateSortByValue(sortBy));
  };

  // scroll bar vertical
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDown(true);
    e.currentTarget.classList.add("active");
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseLeave = (e) => {
    setIsDown(false);
    e.currentTarget.classList.remove("active");
  };

  const handleMouseUp = (e) => {
    setIsDown(false);
    e.currentTarget.classList.remove("active");
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleNodeChange = (
    e,
    text,
    funtional_level,
    function_names,
    category_ids
  ) => {
    dispatch(updateNodeFuctionalText(text));
    dispatch(updateNodeFunctionalLevel(funtional_level));
    if (querySearchKey === "category_ids") {
      setSearchParams({ category_ids: category_ids });
    } else {
      if(searchParams.get('s'))
      {
        setSearchParams({ s: searchParams.get('s'), function_names: function_names });
      }
      else
      {
        setSearchParams({ function_names: function_names });
      }
    }
  };

  const segments = window.location.pathname.split("/");
  const last_slug = segments[segments.length - 1];

  const childNodes = departmentTree?.filter(
    (dept) => dept.slug === last_slug
  )[0]?.node;

  const productData = useSelector(({ product }) => product);
  const updatedChildNodes = productData?.filterOptions?.find(
    (each) => each.ift === "functional_name1"
  )?.values;

  return (
    <div>
      <div className="listing-action-section-theme2">
        <div className="listing-grid-wrapper">
          <div className="found-items-tag">
            {/* {departmentTreeLoading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={loader}
                  alt=""
                />
                <span style={{ marginLeft: "10px" }}>
                  Loading, please wait....
                </span>
              </div>
            ) : ( */}
            <ul
              className="found-item-tag-list items"
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              {/* childnodes udpated as per the request by Angel on - 31/07/2024 - Madhu */}
              {updatedChildNodes?.map((eachchild, i) => (
                <li
                  onClick={(e) => {
                    if (activeChildIndex === i) {
                      setActiveChildIndex("");
                      navigate(`/departments/${departmentSlug}`);
                    } else {
                      handleNodeChange(
                        e,
                        eachchild?.text,
                        eachchild?.funtional_level,
                        eachchild,
                        eachchild?.category_ids
                      );
                      setActiveChildIndex(i);
                    }
                  }}
                  className={i === activeChildIndex && "activeChildNode"}
                >
                  <span>{eachchild}</span>
                </li>
              ))}
            </ul>
          </div>
          {/* <>filter code move</> */}
        </div>
        <div className="found-items">
          {!productListByDepartmentLoading &&
            productListByDepartmentTotalCount > 0 && (
              <p>
                We found{" "}
                <span> {productListByDepartmentTotalCount}   {weeklySalesSelected && selectedDepartmentName !== "From our weekly ads" ?  selectedDepartmentName + " products in our weekly ad" : "products"} </span> for
                you!
              </p>
            )}
          {!productListByDepartmentLoading &&
            productListByDepartmentTotalCount == 0 && (
              <div style={{ display: "flex" }}>
                <p>
                  Currently there are no products that match your search
                  criteria. Please try searching all products.
                </p>

                <div
                  style={{
                    marginLeft: "2rem",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                    border: "0",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                  className="clear-filter"
                  onMouseDown={() => {
                    dispatch(emptyIftCheckList());
                    handleChangeSortFilter("", "");
                    setActiveChildIndex("");
                    handleChangeOption(pageLimit, listLimitOption[0]?.value);
                  }}
                  onMouseUp={() => {
                    // getProductListDeptAPICall();
                    navigate(`/search/products/`);
                  }}
                >
                  Clear Filters
                </div>
              </div>
            )}
          {productListByDepartmentLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "100px", height: "50px" }}
                src={loader}
                alt=""
              />
              <span style={{ marginLeft: "10px" }}>
                Loading, please wait....
              </span>
            </div>
          )}
          {!productListByDepartmentLoading && (
            <p className="product-count-mobile-screen">
              <span> {productListByDepartmentTotalCount} items </span>
              {isShowReorder &&
                (!!pastOrderCheckedList.length || !!fromCheckedList.length) && (
                  <button
                    className="add-all-to-cart-button"
                    onClick={handleClickAddAllToCart}
                  >
                    <span>Add All to Cart</span>
                  </button>
                )}
              <div
                className="mobile-filter-button"
                onClick={() => setIsShowMobileFilter(!isShowMobileFilter)}
              >
                <button>
                  <img src={FilterIcon} alt="FilterIcon" />
                  <span>Filter</span>
                </button>
              </div>
            </p>
          )}
        </div>
        <div className="restricted">
          <p>{uncompleteProductMessage && uncompleteProductMessage}</p>
        </div>
      </div>
      <div
        className={
          isShowMobileFilter
            ? "mobile-filter mobile-filter-show"
            : "mobile-filter mobile-filter-hidden"
        }
      >
        <div className="mobile-filter-header">
          <p>Filter</p>
          <div
            className="filter-close"
            onClick={() => setIsShowMobileFilter(false)}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <ListingPageSidebarTheme2
          priceSection={priceSection}
          departmentTree={departmentTree}
          otherDepartmentTree={otherDepartmentTree}
          iftOptionList={filterOptions}
          maxPriceValue={maxPriceValue}
          minPriceValue={minPriceValue}
          isMobileSidebar={true}
          isShowReorder={isShowReorder}
          querySearchKey={querySearchKey}
          redirectBaseUrl={redirectBaseUrl}
        />
      </div>
    </div>
  );
};
export default ActionsTheme2;
