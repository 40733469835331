import {
    FETCH_DONATION_LIST,
    FETCH_DONATION_LIST_SUCCESS,
    FETCH_DONATION_LIST_FAILURE,

    
  } from "redux/constants/actionTypes";
  
  let initialState = {    
    donationLoading: false,
    donationStatus: "",
    donationMessage: "",
   };
  
  const donationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DONATION_LIST:
        return {
          ...state,
          donationLoading: true,
          donationStatus: "",
        };
      case FETCH_DONATION_LIST_SUCCESS:
        return {
          ...state,
          donationLoading: false,
          donationStatus: action.payload.status,
          donationMessage: action.payload.message,
        };
      case FETCH_DONATION_LIST_FAILURE:
        return {
          ...state,
          donationStatus: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default donationReducer;
  