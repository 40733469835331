import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from 'lodash';
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "assets/icons/search.svg";
import {
  getRecipesList,
  getRecipesRandomList,
  filterRecipeSearchText
} from "redux/actions";
import './recipes-sidebar.scss'

const RecipesPageSidebar = ({
  isMobileSidebar,
  setIsShowMobileFilter
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { searchKeyWord } = useParams();

  const { recipesMasterData, recipeSearchText } = useSelector(
    ({ recipes }) => recipes
  );

  const types = recipesMasterData?.Types ?? []
  const recipesMasterDataSort = types.sort((a, b) => a.isMealType > b.isMealType ? 1 : -1)

  const handleClickMealType = (e, meal) => {
    navigate(`/recipes/${meal.type}`)

    const selectedOptionValue = {
      ...(recipeSearchText && { query: [`${recipeSearchText}`] }),
      ...(meal.type && { type: [`${meal.type}`] }),
      number: [24]
    }

    const queryParams = Object.keys(selectedOptionValue).reduce((acc, cur) => {
      return acc ? acc + `&${cur}=${selectedOptionValue[cur].join(",")}` : `${cur}=${selectedOptionValue[cur].join(",")}`
    }, "")

    if (meal.isMealType === 1) {
      dispatch(getRecipesList(queryParams));
    }
    if (meal.isMealType === 0) {
      navigate(`/recipes`)
      dispatch(getRecipesRandomList(`limitLicense=true&${queryParams}`));
    }
    setIsShowMobileFilter && setIsShowMobileFilter()
  }

  const onSearchChange = debounce((value) => {

    const selectedOptionValue = {
      ...(value && { query: [`${value}`] }),
      ...(searchKeyWord && { type: [`${searchKeyWord}`] }),
      number: [24]
    }

    const queryString = Object.keys(selectedOptionValue).reduce((acc, cur) => {
      return acc ? acc + `&${cur}=${selectedOptionValue[cur].join(",")}` : `${cur}=${selectedOptionValue[cur].join(",")}`
    }, "")

    searchKeyWord ? dispatch(getRecipesList(queryString)) : dispatch(getRecipesRandomList(`limitLicense=true&${queryString}`));;

    dispatch(filterRecipeSearchText(value))
  }, 500);

  const updatedSearchKeyWord = searchKeyWord ? searchKeyWord : "Popular"

  return (
    <div
      className={`recipes-sidebar-sticky ${isMobileSidebar ? "mobile-view" : ""
        }`}
    >
      <div className="listing-page-sidebar">
        <div className="other-department-list">
          <div className="sidebar-title-alignment">
            <span>
              Meal Types
            </span>
          </div>
          <div
            className="meal-types-search"
          >
            <div className="relative-div">
              <input
                type="text"
                placeholder={`Find a Recipe`}

                onChange={(e) => {
                  onSearchChange(e.target.value)
                }
                }
              />
              <div className="icon-wrapper">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="meal-types-show"
        >
          <div className="meal-types-content">
            <div className="grid">
              {recipesMasterDataSort &&
                recipesMasterDataSort.map((el) => {
                  return (
                    <span
                      // to={`/recipes/${el.type}`}
                      key={el.type}
                      onClick={(e => handleClickMealType(e, el))}

                    >
                      <div className={`grid-items ${updatedSearchKeyWord === el.type ? 'active' : ""}`}>
                        <div className="department-name">
                          <p>{el.type.charAt(0).toUpperCase() + el.type.slice(1)}</p>
                        </div>
                      </div>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesPageSidebar;
