import {
  FETCH_CUSTOM_PAGE_CONTENT,
  FETCH_CUSTOM_PAGE_CONTENT_SUCCESS,
  FETCH_CUSTOM_PAGE_CONTENT_FAILURE
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // send Product Track to analytics
  export const fetchCustomPage = (payload) => {
    return {
      type: FETCH_CUSTOM_PAGE_CONTENT,
      payload: payload,
    };
  };
  export const fetchCustomPageSuccess = (payload) => {
    return {
      type: FETCH_CUSTOM_PAGE_CONTENT_SUCCESS,
      payload: payload,
    };
  };
  export const fetchCustomPageFailure = (payload) => {
    return {
      type: FETCH_CUSTOM_PAGE_CONTENT_FAILURE,
      payload: payload,
    };
  };
  
  export const getCustomPage = (body) => async (dispatch) => {
    dispatch(fetchCustomPage());
    axios
      .post("/AogGetPageContentData", body)
      .then((res) => {
        dispatch(fetchCustomPageSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchCustomPageFailure({ error: error.data.message }));
      });
  };

  