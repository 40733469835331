import React from "react";
import TestimonialsSection from "routes/home/TestimonialsSection";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import BlogCard from "./BlogCard";

const Blog = () => {
  const breadcrumbList = [{ lable: "Blog", link: "", searchTag: "" }];
  return (
    <div>
      <>
        <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Blog" />
        <BlogCard />
      </>

      <TestimonialsSection />
    </div>
  );
};
export default Blog;
