import React from "react";
import CartProduct from "./CartProduct";
import CateringCartProduct from './CateringCartProduct'
import "./cart-items.scss";

const CartItems = ({ productCartList, cartType }) => {

  const cartProductByCartType = (product, i) => {
    switch (cartType) {
      case 'catering-meal': {
        return <CateringCartProduct product={product} key={product.item_id} productIndex={i} />
      }
      default:
        return <CartProduct product={product} key={product.item_id} />
    }
  }

  return (
    <>
      <div className="cart-items-table">
        <div className="table-header-align">
          <div className="first-title">
            <input type="checkbox" id="termsandconditions" />
            <label htmlFor="termsandconditions">
              <span>Products</span>
            </label>
          </div>
          <div className="child-all-title">
            <div className="child-title">
              <span>Price</span>
            </div>
            <div className="child-title">
              <span>Quantity</span>
            </div>
            <div className="child-title">
              <span>Total</span>
            </div>
            <div className="child-title">
              <span>Remove</span>
            </div>
          </div>
        </div>
        <div className="cart-items-body">
          {productCartList.map((product, i) => {
            return cartProductByCartType(product, i);
          })}
        </div>
        {/* <div className="cart-footer">
          <div className="continue-shopping">
            <Link to="/search/products/*">
              <button>
                <i className="fa-solid fa-left-long"></i>
                <span>Continue Shopping</span>
              </button>
            </Link>
          </div>
          <div className="update-cart">
            <button>
              <i className="fa-solid fa-arrows-rotate"></i>
              <span>Update Cart</span>
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default CartItems;
