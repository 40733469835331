import {
  FETCH_HOT_OFFERS_DATA,
  FETCH_HOT_OFFERS_DATA_SUCCESS,
  FETCH_HOT_OFFERS_DATA_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetach Hot offer data
export const fetchHotOffersData = (payload) => {
  return {
    type: FETCH_HOT_OFFERS_DATA,
    payload: payload,
  };
};
export const fetchHotOffersDataSuccess = (payload) => {
  return {
    type: FETCH_HOT_OFFERS_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchHotOffersDataFailure = (payload) => {
  return {
    type: FETCH_HOT_OFFERS_DATA_FAILURE,
    payload: payload,
  };
};

export const getHotOffersData = (body) => async (dispatch) => {
  dispatch(fetchHotOffersData());

  axios
    .post("/AogGetManualAdvertise", body)
    .then((res) => {
      dispatch(fetchHotOffersDataSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchHotOffersDataFailure({ error: error.data.message }));
    });
};
