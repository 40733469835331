import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { resetFilterSideBar } from "redux/actions";

const Search = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFilterSideBar());
    };
  }, []); // eslint-disable-line
  return <Outlet />;
};

export default Search;
