import {
    FETCH_ABOUTUS_DATA,
    FETCH_ABOUTUS_DATA_SUCCESS,
    FETCH_ABOUTUS_DATA_FAILURE,
    FETCH_HISTORY_DATA,
    FETCH_HISTORY_DATA_SUCCESS,
    FETCH_HISTORY_DATA_FAILURE,
    FETCH_AWARD_DATA,
    FETCH_AWARD_DATA_SUCCESS,
    FETCH_AWARD_DATA_FAILURE,
    FETCH_GIVINGBACK_DATA,
    FETCH_GIVINGBACK_DATA_SUCCESS,
    FETCH_GIVINGBACK_DATA_FAILURE
  
  } from "redux/constants/actionTypes";
  
  let initialState = {
    aboutusData: {},
    aboutusDataLoading: true,
    aboutusDataError: "",
    historyData: {},
    historyDataLoading: true,
    historyDataError: "",
    awardData: {},
    awardDataLoading: true,
    awardDataError: "",
    givingbackData: {},
    givingbackDataLoading: true,
    givingbackDataError: "",
  };
  
  const aboutusReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_ABOUTUS_DATA:
        return {
          ...state,
          aboutusDataLoading: true,
        };
      case FETCH_ABOUTUS_DATA_SUCCESS:
        return {
          ...state,
          aboutusDataLoading: false,
          aboutusData: action.payload,
        };
      case FETCH_ABOUTUS_DATA_FAILURE:
        return {
          ...state,
          aboutusDataError: action.payload.error,
        };
      case FETCH_HISTORY_DATA:
        return {
          ...state,
          historyDataLoading: true,
        };
      case FETCH_HISTORY_DATA_SUCCESS:
        return {
          ...state,
          historyDataLoading: false,
          historyData: action.payload,
        };
      case FETCH_HISTORY_DATA_FAILURE:
        return {
          ...state,
          historyDataError: action.payload.error,
        };
      case FETCH_AWARD_DATA:
        return {
          ...state,
          awardDataLoading: true,
        };
      case FETCH_AWARD_DATA_SUCCESS:
        return {
          ...state,
          awardDataLoading: false,
          awardData: action.payload,
        };
      case FETCH_AWARD_DATA_FAILURE:
        return {
          ...state,
          awardDataError: action.payload.error,
        };
      case FETCH_GIVINGBACK_DATA:
        return {
          ...state,
          givingbackDataLoading: true,
        };
      case FETCH_GIVINGBACK_DATA_SUCCESS:
        return {
          ...state,
          givingbackDataLoading: false,
          givingbackData: action.payload,
        };
      case FETCH_GIVINGBACK_DATA_FAILURE:
        return {
          ...state,
          givingbackDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default aboutusReducer;
  