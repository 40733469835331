import React from "react";
import ProductListSlider from "routes/home/ProductListSlider";
import "./related-product.scss";

const RelatedProduct = ({ relatedProductList }) => {
  return (
    <div>
      <div className="related-product-section">
        <div className="container">
          <h1>Related products</h1>
        </div>

        <ProductListSlider products={relatedProductList} />
      </div>
    </div>
  );
};
export default RelatedProduct;
