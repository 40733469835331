import React, { useEffect } from "react";
// import { useState } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ListCard from "./ListCard";
import { saveList, getList, resetSearch } from "redux/actions";
import "./list-section.scss";
import { getLocalStorageValue } from 'config/helper';

const ProductList = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const dispatch = useDispatch();

  //   const [page, setPage] = useState(1);

  const customerLists = useSelector(
    ({ customlist }) => customlist.customerList
  );
  const addListError = useSelector(({ customlist }) => customlist.ListError);
  const ListLoading = useSelector(({ customlist }) => customlist.ListLoading);
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const AogAddYourListApiUrl = commonDetailsData.header_section.AogApiURL;
  

  useEffect(() => {
    dispatch(resetSearch());
  }, []);// eslint-disable-line

  useEffect(() => {
    reset({ listname: "", picture: null });
  }, [addListError, reset]);// eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getList(body));
  }, [dispatch, clientId, memberNumber, userToken, isUserLoggedIn, storeId]);// eslint-disable-line
  // New object variable "AogAddYourListApiUrl" is added - 2/23/2023 - Navina
  const handleSaveList = (data) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      list_name: data.listname,
      AogAddYourListApiUrl: AogAddYourListApiUrl,
      image: data.picture[0]
        ? data.picture[0].type.includes("image/")
          ? data.picture[0]
          : null
        : null,
    };
    dispatch(saveList(body));
  };
  const breadcrumbList = [{ lable: "Favorites", link: "", searchTag: "" }];
  return (
    <div>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Favorites" />
      <div className="custom-list-section">
        <div className="reward-section-title">
          <div className="container">
            <div className="product-list-grid">
              <div className="product-list-grid-items" key="0">
                <div
                  className="custom-list-card"
                  style={{ backgroudColor: "gray" }}
                  key="0"
                >
                  <h4>Create New List</h4>
                  <br />
                  <form name="savelist" onSubmit={handleSubmit(handleSaveList)}>
                    <div className="input-name-grid">
                      <div className="form-control">
                        <input
                          type="text"
                          name="listname"
                          placeholder="Enter List Name"
                          id="listname"
                          ref={register({
                            required: true,
                            pattern: /^[A-Za-z0-9 ]+$/,
                          })}
                        />
                        {errors.listname &&
                          errors.listname.type === "required" && (
                            <span>List name cannot be blank</span>
                          )}
                        {errors.listname &&
                          errors.listname.type === "pattern" && (
                            <span>Only characters allowed</span>
                          )}
                      </div>
                    </div>
                    <h4>Upload Image</h4>
                    <br />
                    <input
                      ref={register({
                        required: false,
                      })}
                      type="file"
                      name="picture"
                      id="picture"
                      accept="image/*"
                    />

                    <div className="address-submit-btn">
                      {/* <button onClick={changeHandler} >
                        {"Cancel"}
                        </button> */}
                      <div
                        className={
                          addListError === "List Added Successfully."
                            ? "add-success"
                            : "add-error"
                        }
                      >
                        {addListError}
                      </div>
                      <button type="submit">
                        {ListLoading ? "Saving..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {customerLists &&
                customerLists.map((list) => {
                  return (
                    <div className="product-list-grid-items" key={list.list_id}>
                      <ListCard List={list} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductList;
