import {
  FETCH_HOME_ADS_LIST,
  FETCH_HOME_ADS_LIST_SUCCESS,
  FETCH_HOME_ADS_LIST_FAILURE,

} from "redux/constants/actionTypes";

let initialState = {
  adsData: [],
  adsDataLoading: false,
  adsDataError: "",
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case
      FETCH_HOME_ADS_LIST:
      return {
        ...state,
        adsDataLoading: true,
      };
    case FETCH_HOME_ADS_LIST_SUCCESS:
      return {
        ...state,
        adsDataLoading: false,
        adsData: action.payload,
      };
    case FETCH_HOME_ADS_LIST_FAILURE:
      return {
        ...state,
        adsDataError: action.payload.error,
      };
    default:
      return state;
  }
};

export default adsReducer;
