import {
  FETCH_HOT_OFFERS_DATA,
  FETCH_HOT_OFFERS_DATA_SUCCESS,
  FETCH_HOT_OFFERS_DATA_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  hotOffersData: {},
  hotOffersDataLoading: true,
  hotOffersDataError: "",
};

const hotOffersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOT_OFFERS_DATA:
      return {
        ...state,
        hotOffersDataLoading: true,
      };
    case FETCH_HOT_OFFERS_DATA_SUCCESS:
      return {
        ...state,
        hotOffersDataLoading: false,
        hotOffersData: action.payload,
      };
    case FETCH_HOT_OFFERS_DATA_FAILURE:
      return {
        ...state,
        hotOffersDataError: action.payload.error,
      };

    default:
      return state;
  }
};

export default hotOffersReducer;
