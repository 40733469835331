import React, { useState, useEffect } from "react";
import TabList from "components/Common/TabList";
import { useSelector, useDispatch } from "react-redux";
import { getHomePageCouponsData } from "redux/actions/homepage";
import CouponListSlider from "../CouponListSlider";
import "./offer-section.scss";
import { getLocalStorageValue } from 'config/helper';

const EcomDisabledOfferSection = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const homePageCouponsData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );

  const [activeOfferId, setActiveOfferId] = useState("");

  const activeOfferCouponList =
    homePageCouponsData.find((offer) => offer.id === activeOfferId)
      ?.data_list || [];

  useEffect(() => {
    !!homePageCouponsData.length && setActiveOfferId(homePageCouponsData[0].id);
  }, [homePageCouponsData]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHomePageCouponsData(body));
  }, [storeId]); // eslint-disable-line

  return (
    <div>
      <TabList
        tabs={homePageCouponsData.map((offer) => ({
          title: offer.title,
          id: offer.id,
        }))}
        activeTabId={activeOfferId}
        onSelectTab={(tabId) => setActiveOfferId(tabId)}
      />
      {!!activeOfferCouponList.length ? (
        <CouponListSlider coupons={activeOfferCouponList} />
      ) : (
        ""
      )}
    </div>
  );
};
export default EcomDisabledOfferSection;
