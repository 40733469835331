import {
    FETCH_CAMPAIGN_PRODUCT_LIST,
    FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS,
    FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE,
 
  } from "redux/constants/actionTypes";
  
  let initialState = {
    campaignproductData: {},
    campaignproductDataLoading: true,
    campaignproductDataError: "",
  };
  
  const campaignproductReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_CAMPAIGN_PRODUCT_LIST:
        return {
          ...state,
          campaignproductDataLoading: true,
        };
      case FETCH_CAMPAIGN_PRODUCT_LIST_SUCCESS:
        return {
          ...state,
          campaignproductDataLoading: false,
          campaignproductData: action.payload,
        };
      case FETCH_CAMPAIGN_PRODUCT_LIST_FAILURE:
        return {
          ...state,
          campaignproductDataLoading: false,
          campaignproductDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default campaignproductReducer;
  