import React from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import FeaturedOpenings from "./featured-openings";

const FutureOpenings = () => {

  const breadcrumbList = [{ lable: "Employment", link: "", searchTag: "" }];

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Employment"} 
      />
     <FeaturedOpenings />
    </>
  );
};
export default FutureOpenings;
