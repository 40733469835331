import React, { useEffect } from "react";
import "./donation.scss";
import { useSelector, useDispatch } from "react-redux";
import DonationForm from "./donation-form";
import { resetSearch } from "redux/actions";

import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";

const Donation = () => {
  const dispatch = useDispatch();
  const breadcrumbList = [{ lable: "Donation", link: "", searchTag: "" }];
  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={"Donation"} />

      <div className="donation-info-section">
        <div className="container">
          <h1>{headerSectionData.client_name} Donation Request Application </h1>
          <span>Please complete all fields for consideration.</span>
        </div>
      </div>

      <DonationForm headerSectionData={headerSectionData} />
    </>
  );
};
export default Donation;
