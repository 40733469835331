import {
  FETCH_FEEDBACK_DETAILS,
  FETCH_FEEDBACK_DETAILS_SUCCESS,
  FETCH_FEEDBACK_DETAILS_FAILURE,

  FETCH_CONTACTSERVICES_DETAILS,
  FETCH_CONTACTSERVICES_DETAILS_SUCCESS,
  FETCH_CONTACTSERVICES_DETAILS_FAILURE

} from "redux/constants/actionTypes";

let initialState = {

  feedbackLoading: false,
  feedbackStatus: "",
  feedbackMessage: "",


  contactserviceData: {},
  contactserviceLoading: true,
  contactserviceDataError: "",
  weeklyAdsButtonUrl: ""
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEEDBACK_DETAILS:
      return {
        ...state,

        feedbackLoading: true,
        feedbackStatus: "",
      };
    case FETCH_FEEDBACK_DETAILS_SUCCESS:
      return {
        ...state,
        feedbackLoading: false,
        feedbackStatus: action.payload.status,
        feedbackMessage: action.payload.message,
      };
    case FETCH_FEEDBACK_DETAILS_FAILURE:
      return {
        ...state,
        feedbackStatus: action.payload.error,
      };

    case FETCH_CONTACTSERVICES_DETAILS:
      return {
        ...state,
        contactserviceLoading: true,
      };
    case FETCH_CONTACTSERVICES_DETAILS_SUCCESS:
      return {
        ...state,
        contactserviceLoading: false,
        contactserviceData: action.payload.data,
        weeklyAdsButtonUrl: action.payload.weekly_ads_button_url
      };
    case FETCH_CONTACTSERVICES_DETAILS_FAILURE:
      return {
        ...state,
        contactserviceDataError: action.payload.error,
      };



    default:
      return state;
  }
};

export default feedbackReducer;
