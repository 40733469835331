import React, { useState } from "react";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import Ingradients from '../Ingradients'
import Nutrition from '../Nutrition'
import Equipment from '../Equipment'
import "./recipe-product-info.scss";
import { useSearchParams } from 'react-router-dom';

const RecipeProductInfo = ({ productDetails }) => {
  const { instructions, analyzedInstructions, nutrition, extendedIngredients } =
    productDetails;

  const [searchParams] = useSearchParams();
  const includeNutrition = searchParams.get("includeNutrition");

  const [selectedAttribute, setSelectedAttribute] = useState("ingradients");

  const steps = (analyzedInstructions && analyzedInstructions[0] && analyzedInstructions[0].steps) || []
  return (
    <>
      <div className="recipe-product-info">
        <div className="container">
          <div className="info-box">
            <div className="info-tab">

              <button
                className={
                  selectedAttribute === "ingradients" ? "active" : ""
                }
                onClick={() => setSelectedAttribute("ingradients")}
              >
                Ingredients
              </button>
              {includeNutrition === "true" && (
                <button
                  className={
                    selectedAttribute === "nutrition-info" ? "active" : ""
                  }
                  onClick={() => setSelectedAttribute("nutrition-info")}
                >
                  Nutrition Information
                </button>
              )}

              {steps && !!steps.length && (
                <button
                  className={
                    selectedAttribute === "equipment" ? "active" : ""
                  }
                  onClick={() => setSelectedAttribute("equipment")}
                >
                  Equipment
                </button>
              )}

            </div>
            <div className="info-details">
              {selectedAttribute === "ingradients" && (
                <Ingradients extendedIngredients={extendedIngredients} />
              )}
              {selectedAttribute === "nutrition-info" && (
                <Nutrition nutrition={nutrition} />
              )}
              {selectedAttribute === "equipment" && (
                <Equipment steps={steps} />
              )}
            </div>
          </div>
        </div>
      </div>
      {((steps && !!steps.length) || instructions) && <div className="step-product-info">
        <div className="container">
          <div className="step-box">
            <div className="step-tab">
              <button
                className="active"
              >
                Instructions
              </button>
            </div>
            <div className="info-details">

              {steps && !!steps.length ? steps.map((step, i) => {
                return (
                  <div key={i} className="steps-instruction">
                    <div className="step-label">
                      <div className="success-checkmark">✔</div> <span><strong>Step {i + 1}</strong></span>
                    </div>
                    {step.step}
                  </div>
                )
              }) : <ReactHtmlParser htmlString={instructions || ""} />}

            </div>
          </div>
        </div>
      </div>}

    </>
  );
};
export default RecipeProductInfo;
