import {
    FETCH_STORE_HISTORY_LIST,
    FETCH_STORE_HISTORY_LIST_SUCCESS,
    FETCH_STORE_HISTORY_LIST_FAILURE,

  } from "redux/constants/actionTypes";
  
  let initialState = {
    storDetailsData: [],
    storDetailsDataLoading: true,
    storDetailsDataError: "",
  };
  
  const storeDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 
      FETCH_STORE_HISTORY_LIST:
        return {
          ...state,
          storDetailsDataLoading: true,
        };
      case FETCH_STORE_HISTORY_LIST_SUCCESS:
        return {
          ...state,
          storDetailsDataLoading: false,
          storDetailsData: action.payload,
        };
      case FETCH_STORE_HISTORY_LIST_FAILURE:
        return {
          ...state,
          storDetailsDataError: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default storeDetailsReducer;
  