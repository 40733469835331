import React, { useEffect } from "react";
import "./provide-section.scss";
import { getcouponDepartmentservices } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';

const ProvideSection = () => {
  const dispatch = useDispatch();
  const { departmentSlug } = useParams();

  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      department_name_slug: departmentSlug,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getcouponDepartmentservices(body));
  }, [dispatch, storeId,departmentSlug]); // eslint-disable-line

  const couponDepartmentservices = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentServicesData
  );

  return (
    <>
      {couponDepartmentservices && couponDepartmentservices.services_list && couponDepartmentservices.services_list.length > 0 ? (
        <div className="provide-section">
          <div className="container">
            <div className="provide-section-title">
              <h1>{couponDepartmentservices.title}</h1>
            </div>
            <div className="grid">
              {couponDepartmentservices.services_list.map((item) => {
                return (
                  <div className="grid-items" key={item.our_services_id}>
                    <div className="icon-center-align">
                      {item.icon_image ? (
                        <img src={item.icon_image} alt="WideIcon" />
                      ) : (
                        ""
                      )}
                    </div>
                    <h2>{item.title}</h2>
                    <p>{item.short_description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ProvideSection;
