import {
  FETCH_RECIPES_MASTER_DATA,
  FETCH_RECIPES_MASTER_DATA_SUCCESS,
  FETCH_RECIPES_MASTER_DATA_FAILURE,
  FETCH_RECIPES_LIST,
  FETCH_RECIPES_LIST_SUCCESS,
  FETCH_RECIPES_LIST_FAILURE,
  FETCH_RECIPE_DETAIL,
  FETCH_RECIPE_DETAIL_SUCCESS,
  FETCH_RECIPE_DETAIL_FAILURE,
  FETCH_SIMILAR_RECIPE_LIST,
  FETCH_SIMILAR_RECIPE_LIST_SUCCESS,
  FETCH_SIMILAR_RECIPE_LIST_FAILURE,
  FILTER_RECIPE_SEARCH_TEXT,
  FETCH_RECIPES_PRODUCT_LIST,
  FETCH_RECIPES_PRODUCT_LIST_SUCCESS,
  FETCH_RECIPES_PRODUCT_LIST_FAILURE,
} from "redux/constants/actionTypes";
import axios from "config/recipesAxios";
import appAxios from "config/axios";


// fetch recipes master data
export const fetchRecipesMasterData = (payload) => {
  return {
    type: FETCH_RECIPES_MASTER_DATA,
    payload: payload,
  };
};
export const fetchRecipesMasterDataSuccess = (payload) => {
  return {
    type: FETCH_RECIPES_MASTER_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchRecipesMasterDataFailure = (payload) => {
  return {
    type: FETCH_RECIPES_MASTER_DATA_FAILURE,
    payload: payload,
  };
};

export const getRecipesMasterData = () => async (dispatch) => {
  dispatch(fetchRecipesMasterData());

  axios
    .get(`/recipes/masterdata`)
    .then((res) => {
      dispatch(fetchRecipesMasterDataSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchRecipesMasterDataFailure({ error: error }));
    });
};
//recipes list
export const fetchRecipesList = (payload) => {
  return {
    type: FETCH_RECIPES_LIST,
    payload: payload,
  };
};
export const fetchRecipesListSuccess = (payload) => {
  return {
    type: FETCH_RECIPES_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchRecipesListFailure = (payload) => {
  return {
    type: FETCH_RECIPES_LIST_FAILURE,
    payload: payload,
  };
};

export const getRecipesList = (query) => async (dispatch) => {
  dispatch(fetchRecipesList());
  axios
    .get(`/recipes/complexSearch?${query}`)
    .then((res) => {
      dispatch(fetchRecipesListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchRecipesListFailure({ error: error }));
    });
};

export const getRecipesRandomList = (query) => async (dispatch) => {
  dispatch(fetchRecipesList());
  axios
    .get(`/recipes/random?${query}`)
    .then((res) => {
      dispatch(
        fetchRecipesListSuccess({
          results: res.data.recipes,
          totalResults: res.data.recipes.length,
        })
      );
    })
    .catch((error) => {
      dispatch(fetchRecipesListFailure({ error: error }));
    });
};

// recipe details
export const fetchRecipeDetail = (payload) => {
  return {
    type: FETCH_RECIPE_DETAIL,
    payload: payload,
  };
};
export const fetchRecipeDetailSuccess = (payload) => {
  return {
    type: FETCH_RECIPE_DETAIL_SUCCESS,
    payload: payload,
  };
};
export const fetchRecipeDetailFailure = (payload) => {
  return {
    type: FETCH_RECIPE_DETAIL_FAILURE,
    payload: payload,
  };
};

export const getRecipeDetail = (id, includeNutrition) => async (dispatch) => {
  dispatch(fetchRecipeDetail());

  axios
    .get(`/recipes/${id}/information?includeNutrition=${includeNutrition}`)
    .then((res) => {
      dispatch(fetchRecipeDetailSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchRecipeDetailFailure({ error: error }));
    });
};

// similar recipes
export const fetchSimilarRecipeList = (payload) => {
  return {
    type: FETCH_SIMILAR_RECIPE_LIST,
    payload: payload,
  };
};
export const fetchSimilarRecipeListSuccess = (payload) => {
  return {
    type: FETCH_SIMILAR_RECIPE_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchSimilarRecipeListFailure = (payload) => {
  return {
    type: FETCH_SIMILAR_RECIPE_LIST_FAILURE,
    payload: payload,
  };
};

export const getSimilarRecipeList = (id) => async (dispatch) => {
  dispatch(fetchSimilarRecipeList());

  axios
    .get(`/recipes/${id}/similar?number=24&limitLicense=true`)
    .then((res) => {
      dispatch(fetchSimilarRecipeListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchSimilarRecipeListFailure({ error: error }));
    });
};

export const filterRecipeSearchText = (payload) => {
  return {
    type: FILTER_RECIPE_SEARCH_TEXT,
    payload: payload,
  };
};

// handle add to cart
export const fetchRecipesProductList = (payload) => {
  return {
    type: FETCH_RECIPES_PRODUCT_LIST,
    payload: payload,
  };
};
export const fetchRecipesProductListSuccess = (payload) => {
  return {
    type: FETCH_RECIPES_PRODUCT_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchRecipesProductListFailure = (payload) => {
  return {
    type: FETCH_RECIPES_PRODUCT_LIST_FAILURE,
    payload: payload,
  };
};

export const getRecipesProductList = (body) => async (dispatch) => {
  dispatch(fetchRecipesProductList());

  appAxios
    .post("/AogGetProducts", body)
    .then((res) => {
      dispatch(fetchRecipesProductListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchRecipesProductListFailure({ error: error }));
    });
};
