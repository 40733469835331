import {
  FETCH_COUPONDEPARTMENT_DATA,
  FETCH_COUPONDEPARTMENT_SUCCESS,
  FETCH_COUPONDEPARTMENT_FAILURE,

  FETCH_COUPON_DEPARTMENT_SERVICES_DATA,
  FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS,
  FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE

} from "redux/constants/actionTypes";

let initialState = {
  coupondepartmentData: [],
  coupondepartmentsDataLoading: true,
  coupondepartmentDataError: "",

  coupondepartmentServicesData: {},
  coupondepartmentServicesDataLoading: true,
  coupondepartmentServicesDataError: "",

};

const couponDepartmentReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_COUPONDEPARTMENT_DATA:
      return {
        ...state,
        coupondepartmentsDataLoading: true,
      };
    case FETCH_COUPONDEPARTMENT_SUCCESS:
      return {
        ...state,
        coupondepartmentsDataLoading: false,
        coupondepartmentData: action.payload,
      };
    case FETCH_COUPONDEPARTMENT_FAILURE:
      return {
        ...state,
        coupondepartmentDataError: action.payload.error,
      };


    case FETCH_COUPON_DEPARTMENT_SERVICES_DATA:
      return {
        ...state,
        coupondepartmentServicesDataLoading: true,
      };
    case FETCH_COUPON_DEPARTMENT_SERVICES_SUCCESS:
      return {
        ...state,
        coupondepartmentServicesDataLoading: false,
        coupondepartmentServicesData: action.payload,
      };
    case FETCH_COUPON_DEPARTMENT_SERVICES_FAILURE:
      return {
        ...state,
        coupondepartmentServicesDataError: action.payload.error,
      };


    default:
      return state;
  }
};

export default couponDepartmentReducer;
