/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import "./campaign-product.scss";

function CampaignProduct({ campaign }) {
  return (
    <>
      <div className="campaign-product product-col-sm-4">
        <article className="campaign-product-main-wrapper">
          <div className="compaign-product">
            <div className="heading">
              <h2>{campaign?.campaign_name}</h2>
              <Link
                to={{
                  pathname: campaign?.cammpagin_slug,
                }}
                state={{ coupondata: "coupon" }}
              >
                View All{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M8.6 3.4L14.2 9H2v2h12.2l-5.6 5.6L10 18l8-8l-8-8z"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="campaign-product-wrap">
            <div className="campaign-product-main-wrap">
              {/* <div className="offer-tag-quickview">
                <ul className="offer-product-tag">
                  <li className="product-tag">Free</li>
                </ul>
              </div> */}
              <div className="campaign-product-img">
                <a href={campaign?.cammpagin_slug}>
                  <img
                    src={campaign?.campaign_thumbnall_banner_img}
                    alt="product image"
                  />
                </a>
              </div>
            </div>
            {/* <div className="campaign-product-info-wrapper">
              <button className="add-to-cart-btn">Shop now</button>
            </div> */}
          </div>
        </article>
      </div>
    </>
  );
}
export default CampaignProduct;
