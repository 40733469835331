import React from "react";
import { Link } from "react-router-dom";
// import ReactHtmlParser from "components/Common/ReactHtmlParser";
import "./recipes-card.scss";

const RecipesCard = ({ product, isShowButton }) => {
  const {
    title = "",
    id = "",
    image = "",

    // summary = "", preparationMinutes = 0, cookingMinutes = 0, servings = 0,
    includeNutrition = true,
  } = product;

  return (
    <div className="recipes-card" key={id}>
      <div className="recipes-card-details">
        {/* <div className="description">
            <p>
              <span>
                <ReactHtmlParser
                  htmlString={summary || ""}
                />
              </span>
            </p>
          </div>
          <div className="other-description">
            {preparationMinutes > 0 && <p>
              <strong>Prep:</strong> <span>{preparationMinutes} mins</span>
            </p>}
            {cookingMinutes > 0 &&
              <p>
                <strong>Cook: </strong> <span>{cookingMinutes} mins</span>
              </p>}

            <p>
              <strong>Servings: </strong> <span>{servings}</span>
            </p>
          </div> */}
        {image ? <div className="recipes-card-image" tabIndex="0">
          <Link
            to={`/recipe-description/${id}?includeNutrition=${includeNutrition}`}
          >
            <img src={image} alt="ProductImage" />
          </Link>
        </div> :
          <div className="recipes-card-image" tabIndex="0">
            <Link
              to={`/recipe-description/${id}?includeNutrition=${includeNutrition}`}
            >
              <img src={` https://spoonacular.com/recipeImages/${id}-556x370.jpg`} alt="ProductImage" />
            </Link>
          </div>}

        <div className="recipes-info">
          <Link
            to={`/recipe-description/${id}?includeNutrition=${includeNutrition}`}
          >
            {title}
          </Link>
        </div>
      </div>
      {isShowButton && (
        <div className="button-group-alignment">
          {/* <p className="col">
          950 cal
        </p> */}
          <div className="add-to-cart">
            <Link
              to={`/recipe-description/${id}?includeNutrition=${includeNutrition}`}
            >
              <button>
                <span>View Recipe</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipesCard;
