import {
  FETCH_LIST_DATA,
  FETCH_LIST_DATA_SUCCESS,
  FETCH_LIST_DATA_FAILURE,
  UPDATE_LIST_STATUS,
  UPDATE_LIST_STATUS_SUCCESS,
  UPDATE_LIST_STATUS_FAILURE,
  FETCH_LIST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  ADD_LIST,
  ADD_LIST_SUCCESS,
  ADD_LIST_FAILURE,
  REMOVE_LIST,
  REMOVE_LIST_SUCCESS,
  REMOVE_LIST_FAILURE,
  REMOVE_LIST_ITEM,
  REMOVE_LIST_ITEM_SUCCESS,
  REMOVE_LIST_ITEM_FAILURE,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS,
  ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE,
  MARK_UNCOMPLETED_PRODUCT,
  MARK_UNCOMPLETED_PRODUCT_SUCCESS,
  MARK_UNCOMPLETED_PRODUCT_FAILURE,
  MARK_COMPLETE_ALL_PRODUCT,
  MARK_COMPLETE_ALL_PRODUCT_SUCCESS,
  MARK_COMPLETE_ALL_PRODUCT_FAILURE,
  ADD_PRODUCT_TO_LIST,
  ADD_PRODUCT_TO_LIST_SUCCESS,
  ADD_PRODUCT_TO_LIST_FAILURE,
  ADD_MULTIPLE_PRODUCT_TO_LIST,
  ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS,
  ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";
import { useSelector } from "react-redux";
// ADD NEW LIST
export const addList = (payload) => {
  return {
    type: ADD_LIST,
    payload: payload,
  };
};
export const addListSuccess = (payload) => {
  return {
    type: ADD_LIST_SUCCESS,
    payload: payload,
  };
};
export const addListFailure = (payload) => {
  return {
    type: ADD_LIST_FAILURE,
    payload: payload,
  };
};

export const saveList = (body) => async (dispatch) => {
  dispatch(addList());

  const formData = new FormData();
  formData.append("RSAClientId", body.RSAClientId);
  formData.append("ClientStoreId", body.ClientStoreId);
  formData.append("DeviceType", body.DeviceType);
  formData.append("Version", body.Version);
  formData.append("AppName", body.AppName);
  formData.append("list_name", body.list_name);
  formData.append("member_number", body.member_number);
  formData.append("user_token", body.user_token);
  formData.append("image", body.image);

  // API changed for create new Mylist -on 2/23/2023 - Navina 
  
  axios
    .post(body.AogAddYourListApiUrl + "/AogAddYourLists", formData)
    .then((res) => {
      res.data.status === "success"
        ? dispatch(addListSuccess(res.data))
        : dispatch(addListFailure({ error: res.data.message }));
    })
    .catch((error) => {
      dispatch(addListFailure({ error: error.data.message }));
    });
};
// fetch Lists
export const fetchList = (payload) => {
  return {
    type: FETCH_LIST,
    payload: payload,
  };
};
export const fetchListSuccess = (payload) => {
  return {
    type: FETCH_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchListFailure = (payload) => {
  return {
    type: FETCH_LIST_FAILURE,
    payload: payload,
  };
};

export const getList = (body) => async (dispatch) => {
  dispatch(fetchList());

  axios
    .post("/AogGetYourLists", body)
    .then((res) => {
      dispatch(fetchListSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchListFailure({ error: error.data.message }));
    });
};
// Remove List
export const removeList = (payload) => {
  return {
    type: REMOVE_LIST,
    payload: payload,
  };
};
export const removeListSuccess = (payload) => {
  return {
    type: REMOVE_LIST_SUCCESS,
    payload: payload,
  };
};
export const removeListFailure = (payload) => {
  return {
    type: REMOVE_LIST_FAILURE,
    payload: payload,
  };
};

export const deleteList = (body) => async (dispatch) => {
  dispatch(removeList());

  axios
    .post("/AogDeleteYourLists", body)
    .then((res) => {
      dispatch(removeListSuccess({ ...res.data, list_id: body.list_id }));
    })
    .catch((error) => {
      dispatch(removeListFailure({ error: error.data.message }));
    });
};
// Remove Product from List
export const removeListItem = (payload) => {
  return {
    type: REMOVE_LIST_ITEM,
    payload: payload,
  };
};
export const removeListItemSuccess = (payload) => {
  return {
    type: REMOVE_LIST_ITEM_SUCCESS,
    payload: payload,
  };
};
export const removeListItemFailure = (payload) => {
  return {
    type: REMOVE_LIST_ITEM_FAILURE,
    payload: payload,
  };
};

export const deleteListItem = (body) => async (dispatch) => {
  dispatch(removeListItem());

  axios
    .post("/AogDeleteProductInList", body)
    .then((res) => {
      dispatch(
        removeListItemSuccess({ ...res.data, product_id: body.product_id })
      );
    })
    .catch((error) => {
      dispatch(removeListItemFailure({ error: error.data.message }));
    });
};
// fetch List data
export const fetchListDetails = (payload) => {
  return {
    type: FETCH_LIST_DATA,
    payload: payload,
  };
};
export const fetchListDetailsSuccess = (payload) => {
  return {
    type: FETCH_LIST_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchListDetailsFailure = (payload) => {
  return {
    type: FETCH_LIST_DATA_FAILURE,
    payload: payload,
  };
};

export const getListDetails = (body) => async (dispatch) => {
  dispatch(fetchListDetails());

  axios
    .post("/AogGetListWiseProductsList", body)
    .then((res) => {
      dispatch(fetchListDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchListDetailsFailure({ error: error.data.message }));
    });
};
// UPDATE LIST STATUS
export const updateListStatus = (payload) => {
  return {
    type: UPDATE_LIST_STATUS,
    payload: payload,
  };
};
export const updateListStatusSuccess = (payload) => {
  return {
    type: UPDATE_LIST_STATUS_SUCCESS,
    payload: payload,
  };
};
export const updateListStatusFailure = (payload) => {
  return {
    type: UPDATE_LIST_STATUS_FAILURE,
    payload: payload,
  };
};

export const makeListActive = (body) => async (dispatch) => {
  dispatch(updateListStatus());

  axios
    .post("/AogUpdateStatusList", body)
    .then((res) => {
      dispatch(updateListStatusSuccess({ ...res.data, list_id: body.list_id }));
    })
    .catch((error) => {
      dispatch(updateListStatusFailure({ error: error.data.message }));
    });
};
//add or mark as completed
export const addOrAddAllToCartProduct = (payload) => {
  return {
    type: ADD_OR_ADD_ALL_TO_CART_PRODUCT,
    payload: payload,
  };
};
export const addOrAddAllToCartProductSuccess = (payload) => {
  return {
    type: ADD_OR_ADD_ALL_TO_CART_PRODUCT_SUCCESS,
    payload: payload,
  };
};
export const addOrAddAllToCartProductFailure = (payload) => {
  return {
    type: ADD_OR_ADD_ALL_TO_CART_PRODUCT_FAILURE,
    payload: payload,
  };
};

export const addOrAddAllProductToCart = (body) => async (dispatch) => {
  dispatch(addOrAddAllToCartProduct());

  return axios
    .post("/AogAddToCartMultipleItems", body)
    .then((res) => {
      dispatch(addOrAddAllToCartProductSuccess(res.data));
    })
    .catch((error) => {
      dispatch(addOrAddAllToCartProductFailure({ error: error.data.message }));
    });
};
//completed mark
export const markUncompletedProduct = (payload) => {
  return {
    type: MARK_UNCOMPLETED_PRODUCT,
    payload: payload,
  };
};
export const markUncompletedProductSuccess = (payload) => {
  return {
    type: MARK_UNCOMPLETED_PRODUCT_SUCCESS,
    payload: payload,
  };
};
export const markUncompletedProductFailure = (payload) => {
  return {
    type: MARK_UNCOMPLETED_PRODUCT_FAILURE,
    payload: payload,
  };
};

export const productMarkUncompleted = (body) => async (dispatch) => {
  dispatch(markUncompletedProduct());

  axios
    .post("/AogMyListProductMarkUncomplete", body)
    .then((res) => {
      dispatch(markUncompletedProductSuccess(body.items_data));
    })
    .catch((error) => {
      dispatch(markUncompletedProductFailure({ error: error.data.message }));
    });
};

//completed mark all
export const markCompleteAllProduct = (payload) => {
  return {
    type: MARK_COMPLETE_ALL_PRODUCT,
    payload: payload,
  };
};
export const markCompleteAllProductSuccess = (payload) => {
  return {
    type: MARK_COMPLETE_ALL_PRODUCT_SUCCESS,
    payload: payload,
  };
};
export const markCompleteAllProductFailure = (payload) => {
  return {
    type: MARK_COMPLETE_ALL_PRODUCT_FAILURE,
    payload: payload,
  };
};

export const productMarkCompleteAll = (body) => async (dispatch) => {
  dispatch(markCompleteAllProduct());

  axios
    .post("/AogMyListProductMarkComplete", body)
    .then((res) => {
      dispatch(markCompleteAllProductSuccess(body.items_data));
    })
    .catch((error) => {
      dispatch(markCompleteAllProductFailure({ error: error.data.message }));
    });
};

export const addProduct = (payload) => {
  return {
    type: ADD_PRODUCT_TO_LIST,
    payload: payload,
  };
};
export const addProductSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_TO_LIST_SUCCESS,
    payload: payload,
  };
};
export const addProductFailure = (payload) => {
  return {
    type: ADD_PRODUCT_TO_LIST_FAILURE,
    payload: payload,
  };
};

export const addProductToList = (body) => async (dispatch) => {
  dispatch(addProduct());

  return axios
    .post("/AogAddProductToList", body)
    .then((res) => {
      dispatch(addProductSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(addProductFailure({ error: error.data.message }));
    });
};

export const addMultipleProduct = (payload) => {
  return {
    type: ADD_MULTIPLE_PRODUCT_TO_LIST,
    payload: payload,
  };
};
export const addMultipleProductSuccess = (payload) => {
  return {
    type: ADD_MULTIPLE_PRODUCT_TO_LIST_SUCCESS,
    payload: payload,
  };
};
export const addMultipleProductFailure = (payload) => {
  return {
    type: ADD_MULTIPLE_PRODUCT_TO_LIST_FAILURE,
    payload: payload,
  };
};

export const addMultipleProductToList = (body) => async (dispatch) => {
  dispatch(addMultipleProduct());

  return axios
    .post("/AogAddToListMultipleItems", body)
    .then((res) => {
      dispatch(addMultipleProductSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(addMultipleProductFailure({ error: error.data.message }));
    });
};
