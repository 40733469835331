import React from "react";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import "./catering-ads-space-section.scss";

const AdsSpaceSection = ({ adsDetails, department = "" }) => {
  return (
    <>
      {adsDetails && adsDetails.image && adsDetails.description ? (
        <div>
          <div className="catering-ads-space-section">
            <div className="add-grid">
              <div className="add-grid-description">
                <h2>{department}</h2>
                <p>
                  {" "}
                  <ReactHtmlParser htmlString={adsDetails.description || ""} />
                </p>
              </div>
              <div className="add-grid-image">
                <img src={adsDetails.image} alt="ads" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AdsSpaceSection;
