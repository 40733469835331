


import React, { useEffect } from "react";
import './ebt-payment-gateway.scss'
// import { getLocalStorageValue } from 'config/helper';


const PaymentModal = (props) => {
    const { isShowPaymentPopup, ebtPaymentResponse } = props;
    // const { currentStoreID } = useSelector(({ store }) => store);


    // const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
    // const clientId = getLocalStorageValue("RSAclient-id");

    const { redirectURL } = ebtPaymentResponse




    useEffect(() => {
        if (redirectURL) {
            window.location.href = redirectURL
        }
    }, [redirectURL])

    const paymentGateway = (mode) => {

        return (
            <div className="ept-payment-gateway">

            </div>
        )
    }



    return (
        <>
            {isShowPaymentPopup && paymentGateway()}
        </>
    );
};

export default PaymentModal;
