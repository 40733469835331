import {
  FETCH_ABOUTUS_DATA,
  FETCH_ABOUTUS_DATA_SUCCESS,
  FETCH_ABOUTUS_DATA_FAILURE,
  FETCH_HISTORY_DATA,
  FETCH_HISTORY_DATA_SUCCESS,
  FETCH_HISTORY_DATA_FAILURE,
  FETCH_AWARD_DATA,
  FETCH_AWARD_DATA_SUCCESS,
  FETCH_AWARD_DATA_FAILURE,
  FETCH_GIVINGBACK_DATA,
  FETCH_GIVINGBACK_DATA_SUCCESS,
  FETCH_GIVINGBACK_DATA_FAILURE
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch about data
export const fetchAboutusDetails = (payload) => {
  return {
    type: FETCH_ABOUTUS_DATA,
    payload: payload,
  };
};
export const fetchAboutusDetailsSuccess = (payload) => {
  return {
    type: FETCH_ABOUTUS_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchAboutusDetailsFailure = (payload) => {
  return {
    type: FETCH_ABOUTUS_DATA_FAILURE,
    payload: payload,
  };
};

export const getAboutusDetails = (body) => async (dispatch) => {
  dispatch(fetchAboutusDetails());

  axios
    .post("/AogGetAboutDetails", body)
    .then((res) => {
      dispatch(fetchAboutusDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchAboutusDetailsFailure({ error: error.data.message }));
    });
};
// fetch history data
export const fetchHistoryDetails = (payload) => {
  return {
    type: FETCH_HISTORY_DATA,
    payload: payload,
  };
};
export const fetchHistoryDetailsSuccess = (payload) => {
  return {
    type: FETCH_HISTORY_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchHistoryDetailsFailure = (payload) => {
  return {
    type: FETCH_HISTORY_DATA_FAILURE,
    payload: payload,
  };
};

export const getHistoryDetails = (body) => async (dispatch) => {
  dispatch(fetchHistoryDetails());

  axios
    .post("/AogGetClientHistory", body)
    .then((res) => {
      dispatch(fetchHistoryDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchHistoryDetailsFailure({ error: error.data.message }));
    });
};
// fetch award data
export const fetchAwardDetails = (payload) => {
  return {
    type: FETCH_AWARD_DATA,
    payload: payload,
  };
};
export const fetchAwardDetailsSuccess = (payload) => {
  return {
    type: FETCH_AWARD_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchAwardDetailsFailure = (payload) => {
  return {
    type: FETCH_AWARD_DATA_FAILURE,
    payload: payload,
  };
};

export const getAwardDetails = (body) => async (dispatch) => {
  dispatch(fetchAwardDetails());

  axios
    .post("/AogGetAwardsData", body)
    .then((res) => {
      dispatch(fetchAwardDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchAwardDetailsFailure({ error: error.data.message }));
    });
};
// fetch giving-back data
export const fetchGivingBackDetails = (payload) => {
  return {
    type: FETCH_GIVINGBACK_DATA,
    payload: payload,
  };
};
export const fetchGivingBackDetailsSuccess = (payload) => {
  return {
    type: FETCH_GIVINGBACK_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchGivingBackDetailsFailure = (payload) => {
  return {
    type: FETCH_GIVINGBACK_DATA_FAILURE,
    payload: payload,
  };
};

export const getGivingBackDetails = (body) => async (dispatch) => {
  dispatch(fetchGivingBackDetails());

  axios
    .post("/AogGetGivingBackData", body)
    .then((res) => {
      dispatch(fetchGivingBackDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchGivingBackDetailsFailure({ error: error.data.message }));
    });
};
