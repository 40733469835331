import {
  FETCH_STORE_LIST,
  FETCH_STORE_LIST_SUCCESS,
  FETCH_STORE_LIST_FAILURE,
  SELECT_STORE_TYPE,
  SELECT_CURRENT_STORE,
  FETCH_STORE_DETAILS,
  FETCH_STORE_DETAILS_SUCCESS,
  FETCH_STORE_DETAILS_FAILURE,
} from "redux/constants/actionTypes";
import { setLocalStorageValue } from 'config/helper';
import axios from "config/axios";

// fetach Store data

export const fetchStoreList = (payload) => {
  return {
    type: FETCH_STORE_LIST,
    payload: payload,
  };
};

export const fetchStoreListSuccess = (payload) => {
  return {
    type: FETCH_STORE_LIST_SUCCESS,
    payload: payload,
  };
};

export const fetchStoreListFailure = (payload) => {
  return {
    type: FETCH_STORE_LIST_FAILURE,
    payload: payload,
  };
};

export const getStoreList = (body) => async (dispatch) => {
  dispatch(fetchStoreList());
  axios
    .post("/AogGetStoreList", body)
    .then((res) => {
      dispatch(fetchStoreListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchStoreListFailure({ error: error.data.message }));
    });
};

// fetch store details
export const fetchStoreDetails = (payload) => {
  return {
    type: FETCH_STORE_DETAILS,
    payload: payload,
  };
};
export const fetchStoreDetailsSuccess = (payload) => {
  return {
    type: FETCH_STORE_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const fetchStoreDetailsFailure = (payload) => {
  return {
    type: FETCH_STORE_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getStoreDetails = (body) => async (dispatch) => {
  dispatch(fetchStoreDetails());

  axios
    .post("/AogGetStoreDetail", body)
    .then((res) => {
      dispatch(fetchStoreDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchStoreDetailsFailure({ error: error.data.message }));
    });
};

// SELECT STORE TYPE
export const selectStoreType = (payload) => {
  return {
    type: SELECT_STORE_TYPE,
    payload: payload,
  };
};

// SELECT  CURRENT STORE
export const selectCurrentStore = (payload) => {
  setLocalStorageValue("selected-store-id", payload.ClientStoreId);
  return {
    type: SELECT_CURRENT_STORE,
    payload: payload,
  };
};
