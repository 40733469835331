import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { checkoutSpecialInstruction } from "redux/actions";
import "./special-comment.scss";

const SpecialComment = () => {
  const dispatch = useDispatch();

  const [specialInstruction, setSpecialInstruction] = useState("");

  const handleSpecialInstruction = (e) => {
    const { value } = e.target;
    setSpecialInstruction(value);
    dispatch(checkoutSpecialInstruction(value));
  };

  return (
    <div className="special-comment-section">
      <div className="form-control">
        <label>Add Special Instructions For Your Order</label>
        <textarea
          placeholder="Enter Notes.."
          onChange={handleSpecialInstruction}
          value={specialInstruction}
        ></textarea>
      </div>
    </div>
  );
};
export default SpecialComment;
