import React, { useEffect } from "react";
import TestimonialsSection from "routes/home/TestimonialsSection";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import InformationSection from "./InformationSection";
import ProvideSection from "./ProvideSection";
import WelcomeWhiteFoodliners from "./WelcomeWhiteFoodliners";
import {
  getBannerAndTestimonialsData,
  getAboutusDetails,
  resetSearch,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";

const AboutUs = () => {
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  useEffect(() => {
    dispatch(resetSearch());
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getBannerAndTestimonialsData(body));
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getAboutusDetails(body));
  }, [dispatch]); // eslint-disable-line

  const aboutData = useSelector(({ aboutus }) => aboutus.aboutusData);

  const breadcrumbList = [{ lable: "About Us", link: "", searchTag: "" }];

  return (
    <div>
      {aboutData && aboutData.data && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={aboutData.data.title} //Need to pass title through api res
          />
          <WelcomeWhiteFoodliners welcomeAboutusData={aboutData.data} />
          {aboutData?.data.services_section.client_review_section.length >
            0 && (
            <InformationSection
              client_review={
                aboutData.data.services_section.client_review_section
              }
            />
          )}
          <ProvideSection services_section={aboutData.data.services_section} />;
        </>
      )}

      <TestimonialsSection />
    </div>
  );
};
export default AboutUs;
