import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY

// Encrypt
export const encrypted = (data) => CryptoJS.AES.encrypt(data, secretKey).toString();

// Decrypt
export const decrypted = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText
}
