import React, { useRef } from "react";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import ComponentToPrint from './ComponentToPrint'
import "./coupon-cart-table.scss";

const CartItems = ({ couponCartList, onRemovecopuon }) => {
  const componentRef = useRef();

  return (
    <>
      <div
        style={{ display: "none" }}
      >
        <ComponentToPrint
          couponCartList={couponCartList}
          ref={componentRef}
        />
      </div>
      {couponCartList.length > 0 && (
        <div className="coupon-cart-items-table">

          <table className="coupon-cart-table">
            <thead>
              <th></th>
              <th className="title">Title</th>
              <th>Use(times)</th>
              <th></th>
            </thead>
            <tbody>
              {couponCartList.map((coupon, index) => {
                const { image_path, title, offer_id } = coupon;
                return (
                  <tr key={index}>
                    <td data-label="Image">
                      <img src={image_path} alt="CouponImage" />
                    </td>
                    <td data-label="Title" className="title">
                      {title}
                    </td>
                    <td data-label="Limit">{1}</td>
                    <td data-label="Action">
                      <div
                        className="remove-icon"
                        onClick={() => onRemovecopuon(offer_id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="coupon-cart-footer">
            <div className="continue-shopping">
              <Link to="/coupons">
                <button>
                  <i className="fa-solid fa-left-long"></i>
                  <span>Continue Shopping</span>
                </button>
              </Link>
            </div>
            <div className="download-button">
              <ReactToPrint
                trigger={() => <button >
                  <i className="fa-solid fa-print"></i>
                  <span>Print and Download</span>
                </button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CartItems;
