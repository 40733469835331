import React from "react";
import QuoteIcon from "assets/icons/quote.svg";

const TestimonialCard = ({ testimonial }) => {
  const { description, customer_name, designation } = testimonial;

  return (
    <div>
      <div className="testimonials-card">
        <div className="icon-align">
          <img src={QuoteIcon} alt="QuoteIcon" />
        </div>
        <p>{description}</p>
        <span>{customer_name}</span>
        <a rel="noforever" href="/">
          {designation}
        </a>
      </div>
    </div>
  );
};

export default TestimonialCard;
