import {
    FETCH_STORE_HISTORY_LIST,
    FETCH_STORE_HISTORY_LIST_SUCCESS,
    FETCH_STORE_HISTORY_LIST_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch blog data
  export const fetchStoreDetail = (payload) => {
    return {
      type: FETCH_STORE_HISTORY_LIST,
      payload: payload,
    };
  };
  export const fetchStoreDetailsuccess = (payload) => {
    return {
      type: FETCH_STORE_HISTORY_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const fetchStoreDetailFailure = (payload) => {
    return {
      type: FETCH_STORE_HISTORY_LIST_FAILURE,
      payload: payload,
    };
  };
  
  export const getStoreDetailsHistory = (body) => async (dispatch) => {
    dispatch(fetchStoreDetail());
    axios
      .post("/AogGetStoreHistory", body)
      .then((res) => {
        dispatch(fetchStoreDetailsuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchStoreDetailFailure({ error: error.data.message }));
      });
  };
  