import React, { useEffect } from "react";

const WeeklyAdsDigitalPage = () => {
  useEffect(() => {
    const condition = window.location.pathname === "/weekly-ads-digital";

    if (condition) {
      const onloadFunction = `
        var storefront = new Flipp.Storefront({
          accessToken: "eaba9fe25bb07752e3786d4226daed54",
          merchantId: "2891",
          merchantNameIdentifier: "waltsfoodcenter",
          locale: "en-US",
        });
        storefront.renderStorefront(document.querySelector("#flipp-container"));
      `;

      const script = document.createElement("script");
      script.src = "https://aq.flippenterprise.net/2891/iframe.js";
      script.id = "flipp-storefront-script";

      script.setAttribute("onload", onloadFunction);

      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div id="flipp-container"></div>
    </div>
  );
};

export default WeeklyAdsDigitalPage;
