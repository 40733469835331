import React from "react";
import "./add-section.scss";
import { useSelector } from 'react-redux';
const AddSection = () => {

  const { ads_list = [] } = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentServicesData
  );

  return (
    <div>
      {ads_list && ads_list.length > 0 && (
        <div className="add-section-alignment">
          <div className="container">
            <div className="add-grid">
              {ads_list.map((ads, index) => {
                return (
                  <div className="add-grid-items" key={index}>
                    {ads.is_link === "1" ? <a
                      target={`${ads.is_open_self === "1" ? '_self' : '_blank'}`}
                      rel="noopener noreferrer"
                      href={ads.ad_target_url}
                    >
                      <img src={ads.image} alt={ads.ad_title} />
                    </a> :
                      <img src={ads.image} alt={ads.ad_title} />
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
export default AddSection;
