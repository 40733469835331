import {
  FETCH_DONATION_LIST,
  FETCH_DONATION_LIST_SUCCESS,
  FETCH_DONATION_LIST_FAILURE,
} from "redux/constants/actionTypes";
import axios from "config/axios";

export const fetchDonationDetails = (payload) => {
  return {
    type: FETCH_DONATION_LIST,
    payload: payload,
  };
};

export const fetchDonationSuccess = (payload) => {
  return {
    type: FETCH_DONATION_LIST_SUCCESS,
    payload: payload,
  };
};

export const fetchDonationFailure = (payload) => {
  return {
    type: FETCH_DONATION_LIST_FAILURE,
    payload: payload,
  };
};

export const addDonation = (body) => async (dispatch) => {
  dispatch(fetchDonationDetails());

  const formData = new FormData();
  formData.append("RSAClientId", body.RSAClientId);
  formData.append("ClientStoreId", body.ClientStoreId);
  formData.append("today_date", body.today_date);
  formData.append("event_date", body.event_date);
  formData.append("org_name", body.org_name);
  formData.append("title", body.title);
  formData.append("Adress", body.Adress);
  formData.append("City", body.City);
  formData.append("primary_contact_name", body.primary_contact_name);
  formData.append("State", body.State);
  formData.append("Zipcode", body.Zipcode);
  formData.append("Country", body.Country);
  formData.append("emailId", body.emailId);
  formData.append("PhoneNo", body.PhoneNo);
  formData.append("fax", body.fax);
  formData.append("about_organization", body.about_organization);
  formData.append(
    "event_mission_location_text",
    body.event_mission_location_text
  );
  formData.append("why_text", body.why_text);
  formData.append("requested_items", body.requested_items);
  formData.append("recognized_text", body.recognized_text);
  formData.append(
    "prevoiusly_recieved_donation",
    body.prevoiusly_recieved_donation
  );
  formData.append("number_of_people", body.number_of_people);
  formData.append("proximity", body.proximity);
  formData.append("tax_id_number", body.tax_id_number);
  formData.append("org_achievement", body.org_achievement);
  formData.append("irs_tax_image", body.irs_tax_image);

  axios
    .post(body.AogSaveDonationApiUrl + "/AogSaveDonationData", formData)
    .then((res) => {
      dispatch(fetchDonationSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchDonationFailure({ error: error.response.data.message }));
    });
};
