import {
    FETCH_JOBS_LIST,
    FETCH_JOBS_LIST_SUCCESS,
    FETCH_JOBS_LIST_FAILURE,
    
  } from "redux/constants/actionTypes";
  
  let initialState = {
    joblistData: {},
    joblistDataLoading: true,
    joblistDataError: "",
  };
  
  const careerReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_JOBS_LIST:
        return {
          ...state,
          joblistDataLoading: true,
        };
      case FETCH_JOBS_LIST_SUCCESS:
        return {
          ...state,
          joblistDataLoading: false,
          joblistData: action.payload,
        };
      case FETCH_JOBS_LIST_FAILURE:
        return {
          ...state,
          joblistDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default careerReducer;
  