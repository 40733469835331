import React from "react";
import "./add-space-section-feature.scss";
const AdsSpaceSection = ({ adsDetails }) => {
  return (
    <div>
      {adsDetails && adsDetails.ads_image ? (
        <div className="ads-space-section-seller">
          <img src={adsDetails.ads_image} alt="banner" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default AdsSpaceSection;
