import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FilterIcon from "assets/icons/filter-icon.svg";
import CloseIcon from "assets/icons/white-close.svg";
import ViewByDropdown from "./ViewByDropdown";
import RecipesPageSidebar from "../Sidebar";
import "./recipe-actions.scss";
import { useSelector } from 'react-redux';

const Actions = () => {

  const { searchKeyWord } = useParams();
  const [isShowMobileFilter, setIsShowMobileFilter] = useState(false)

  const { recipeSearchText } = useSelector(
    ({ recipes }) => recipes
  );

  return (
    <div>
      <div className="recipe-listing-action-section">
        <div className="listing-grid-wrapper">
          <div className="found-items">
            <p>
              {recipeSearchText ? recipeSearchText.charAt(0).toUpperCase() + recipeSearchText.slice(1)
                : searchKeyWord ? searchKeyWord.charAt(0).toUpperCase() + searchKeyWord.slice(1) : "Popular"}
            </p>
            <p className="product-count-mobile-screen">
              <span> {recipeSearchText ? recipeSearchText.charAt(0).toUpperCase() + recipeSearchText.slice(1)
                : searchKeyWord ? searchKeyWord.charAt(0).toUpperCase() + searchKeyWord.slice(1) : "Popular"} </span>{" "}
            </p>
          </div>



          <div
            className="mobile-filter-button"
            onClick={() => setIsShowMobileFilter(!isShowMobileFilter)}
          >
            <button>
              <img src={FilterIcon} alt="FilterIcon" />
              <span>Filter</span>
            </button>
          </div>
          <div className="all-action-wrapper">


            <ViewByDropdown
              lable="Filter By"
            />

          </div>
        </div>
      </div>
      <div
        className={
          isShowMobileFilter
            ? "mobile-filter mobile-filter-show"
            : "mobile-filter mobile-filter-hidden"
        }
      >
        <div className="mobile-filter-header">
          <p>Filter</p>
          <div
            className="filter-close"
            onClick={() => setIsShowMobileFilter(false)}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <RecipesPageSidebar isMobileSidebar={true} setIsShowMobileFilter={() => setIsShowMobileFilter(!isShowMobileFilter)} />
      </div>
    </div>
  );
};
export default Actions;
