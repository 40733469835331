import {
    ADD_COUPON_PRODUCT_TO_CART_LIST,
    ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS,
    ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE,

    FETCH_COUPON_CART_LIST,
    FETCH_COUPON_CART_LIST_SUCCESS,
    FETCH_COUPON_CART_LIST_FAILURE,


    REMOVE_COUPON_TO_CART_LIST,
    REMOVE_COUPON_TO_CART_LIST_SUCCESS,
    REMOVE_COUPON_TO_CART_LIST_FAILURE
 
  } from "redux/constants/actionTypes";
  import axios from "config/axios";
  
  // fetch shopping cart data
   
   
   
  //  add to cart list
  
  export const addCouponToCartList = (payload) => {
    return {
      type: ADD_COUPON_PRODUCT_TO_CART_LIST,
      payload: payload,
    };
  };
  export const addCouponToCartListSuccess = (payload) => {
    return {
      type: ADD_COUPON_PRODUCT_TO_CART_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const addCouponToCartListFailure = (payload) => {
    return {
      type: ADD_COUPON_PRODUCT_TO_CART_LIST_FAILURE,
      payload: payload,
    };
  };
  
  export const addcouponToCart = (body) => async (dispatch) => {
    dispatch(addCouponToCartList());
  
    return axios
      .post("/AogAddToCartCoupons", body)
      .then((res) => {
        dispatch(addCouponToCartListSuccess(res.data));
      })
      .catch((error) => {
        dispatch(addCouponToCartListFailure({ error: error.data.message }));
      });
  };


  //fetch coupon cart list
  export const fetchcouponCartList = (payload) => {
    return {
      type: FETCH_COUPON_CART_LIST,
      payload: payload,
    };
  };
  export const fetchcouponCartListSuccess = (payload) => {
    return {
      type: FETCH_COUPON_CART_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const fetchcpouponCartListFailure = (payload) => {
    return {
      type: FETCH_COUPON_CART_LIST_FAILURE,
      payload: payload,
    };
  };
  

  export const getcouponcartlist = (body) => async (dispatch) => {
    dispatch(fetchcouponCartList());

    return axios
      .post("/AogGetMyCouponsCartDetails", body)
      .then((res) => {
        dispatch(fetchcouponCartListSuccess(res.data.data.MyCartInfo));
      })
      .catch((error) => {
        dispatch(fetchcpouponCartListFailure({ error: error.data.message }));
      });
  };


  //remove coupon from cart list
  export const removecouponCartList = (payload) => {
    return {
      type: REMOVE_COUPON_TO_CART_LIST,
      payload: payload,
    };
  };
  export const removecouponCartListSuccess = (payload) => {
    return {
      type: REMOVE_COUPON_TO_CART_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const removecpouponCartListFailure = (payload) => {
    return {
      type: REMOVE_COUPON_TO_CART_LIST_FAILURE,
      payload: payload,
    };
  };
  

  export const getremoveCouponcartlist = (body) => async (dispatch) => {
    dispatch(removecouponCartList());

    return axios
      .post("/AogRemoveFromCartCoupons", body)
      .then((res) => {
        dispatch(removecouponCartListSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(removecpouponCartListFailure({ error: error.data.message }));
      });
  };


   