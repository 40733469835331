import React from "react";

const DropdownListItem = ({
  onSelectOption,
  optionName,
  optionValue,
  onClose,
  selectedOptionValue,
}) => {
  return (
    <li
      className={selectedOptionValue === optionValue ? "active" : ""}
      onClick={() => {
        onSelectOption(optionValue, optionName);
        onClose();
      }}
    >
      {optionName}
    </li>
  );
};

export default DropdownListItem;
