import React from "react";
import ServiceSection from "./ServiceSection";
import { useSelector } from "react-redux";

import NewsLetter from "./NewsLetter";
import FooterLinks from "./FooterLinks";
import CopyRightSection from "./CopyRightSection";
const Footer = () => {
  const commonDetailsDataLoading = useSelector(({ common }) => common);

  return (
    <>
      {commonDetailsDataLoading.commonDetailsData.header_section.is_show_subscribe_section ==="0" ? (
        ""
      ) : (
        <NewsLetter />
      )}

      <ServiceSection />

      <FooterLinks />

      <CopyRightSection />
    </>
  );
};

export default Footer;
