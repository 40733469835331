import {
    FETCH_FEATURE_PRODUCT_LIST,
    FETCH_FEATURE_PRODUCT_LIST_SUCCESS,
    FETCH_FEATURE_PRODUCT_LIST_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch about data
  export const fetchFeatureproduct = (payload) => {
    return {
      type: FETCH_FEATURE_PRODUCT_LIST,
      payload: payload,
    };
  };
  export const fetchFeatureproductSuccess = (payload) => {
    return {
      type: FETCH_FEATURE_PRODUCT_LIST_SUCCESS,
      payload: payload,
    };
  };
  export const fetchFeatureproductFailure = (payload) => {
    return {
      type: FETCH_FEATURE_PRODUCT_LIST_FAILURE,
      payload: payload,
    };
  };
  
  export const getFeatureproduct = (body) => async (dispatch) => {
    dispatch(fetchFeatureproduct());
  
    axios
      .post("/AogGetFeaturedProducts", body)
      .then((res) => {
        dispatch(fetchFeatureproductSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchFeatureproductFailure({ error: error.data.message }));
      });
  };
  