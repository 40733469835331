import React from "react";
import './dropdown.scss'

const DropdownListItem = ({
  onSelectOption,
  optionName,
  optionValue,
  onClose,
  selectedOptionValue,
  dropdownOption

}) => {
  return (
    <li
      className={selectedOptionValue === optionValue ? "active" : ""}
      onClick={() => {
        onSelectOption(optionValue, optionName, dropdownOption);
        onClose();
      }}
    >
      {optionName}{dropdownOption.price !== "0.00" ? `- $${dropdownOption.price}` : ""}
    </li>
  );
};

export default DropdownListItem;
