import React, { useEffect } from "react";

import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import ProvideSection from "./ProvideSection";
import { getLocalStorageValue } from 'config/helper';

import TextSection from "./TextSection";
import { getMobileAppDetails } from "redux/actions";

const Mobile = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getMobileAppDetails(body));
  }, [dispatch, storeId]); // eslint-disable-line

  const mobileappData = useSelector(({ mobileApp }) => mobileApp.mobileappData);

  const breadcrumbList = [{ lable: "Mobile Apps", link: "", searchTag: "" }];

  return (
    <div>
      {Object.keys(mobileappData).length > 0 && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={"Mobile Apps"} //Need to pass title through api res
          />
          <TextSection mobileappData={mobileappData} />
          <ProvideSection mobileappData={mobileappData} />
        </>
      )}
    </div>
  );
};
export default Mobile;
