import React, { useState } from "react";
import "./jobsidebar.scss";
import SliderDots from "./SliderDots";
import { Link } from "react-router-dom";
import ReactHtmlParser from "components/Common/ReactHtmlParser";
import { useSelector } from "react-redux";

const Jobs = ({ jobList }) => {
  const [activeDots, setActiveDots] = useState(1);
  const [itemsPerPage] = useState(2);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const dots = Array.from(
    {
      length: jobList.length > 0 ? Math.ceil(jobList.length / itemsPerPage) : 0,
    },
    (_, i) => i + 1
  );

  const handleActiveDot = (dot) => {
    setActiveDots(dot);
  };
  const onPrev = () => {
    setActiveDots(activeDots - 1);
  };
  const onNext = () => {
    setActiveDots(activeDots + 1);
  };

  const indexofLastItem = activeDots * itemsPerPage;
  const indexofFirsttItem = indexofLastItem - itemsPerPage;
  const currentItems = jobList
    ? jobList.slice(indexofFirsttItem, indexofLastItem)
    : "";

  return (
    <div className="listing-sidebar-sticky-jobs">
      <h2>Open Positions</h2>
      <p>
        There are <span>{jobList.length}</span> open positions
      </p>

      <div className="col-lg-8 post-list">
        {currentItems.map((el) => {
          return (
            <div className="single-post d-flex flex-row" key={el.job_id}>
              <div className="details">
                <div className="title d-flex flex-row justify-content-between">
                  <div className="titles">
                    <h4>{el.job_title}</h4>
                    <h6>{`Job Category: ${el.job_category_title}`}</h6>
                    <h6>{`Requisition Number: ${el.job_reg_number}`}</h6>
                    {el.job_company_Name ? (
                      <h6>{`Company Name: ${el.job_company_Name}`}</h6>
                    ) : (
                      <h6>{`Company Name: Test Company`}</h6>
                    )}

                    {el.job_time ? (
                      <h5>{`Job Nature: ${el.job_time}`}</h5>
                    ) : (
                      <h5>{`Job Nature: Full Time`}</h5>
                    )}

                    {el.job_skill ? (
                      <h5>
                        Skill:{" "}
                        <ReactHtmlParser htmlString={el.job_skill || ""} />
                      </h5>
                    ) : (
                      ""
                    )}
                  </div>
                  <ul className="btns">
                    <li>
                      <Link
                        to={
                          isUserLoggedIn ? `/employment/${el.job_id}` : "/login"
                        }
                        className="secondary-button"
                      >
                        Apply
                      </Link>
                    </li>
                  </ul>
                </div>
                {el.job_short_description && (
                  <ReactHtmlParser
                    htmlString={el.job_short_description || ""}
                  />
                )}

                <p className="address">
                  <i className="fa-solid fa-location-dot"></i>{" "}
                  {el.job_location_address1} {el.job_location_address2},{" "}
                  {el.job_location_city}, {el.job_location_state_name}{" "}
                  {el.job_location_zipcode}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {jobList.length > 0 ? (
        <SliderDots
          pages={dots.length}
          activeSlide={activeDots}
          handleActiveDot={handleActiveDot}
          onPrev={onPrev}
          onNext={onNext}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Jobs;
