import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductPreviewSlider from "./ProductPreviewSlider";
import Dropdown from "components/Common/Dropdown";
import AddToMyList from "components/AddToMyList";
import {
  addToCart,
  getRemoveProductToCartList,
  editProductToCartList,
  addToWishlist,
} from "redux/actions";
import { useNavigate } from "react-router-dom";
import "./product-preview.scss";
import { getLocalStorageValue } from "config/helper";

const ProductPreview = ({ productDetails }) => {
  const {
    name,
    image,
    custom_options,
    sku,
    price1,
    price2,
    price3,
    price4,
    mark_as_age_restricted_department_message,
    gallery,
    product_id,
    final_price,
    categories: { department_name = "" },
    stock_status,
    stores_inventory: {
      has_coupon = "0",
      digital_coupon_amount = "0",
      coupon_used_time = "0",
      ebt_item,
      ebt_image,
      is_discount_percentage,
      digital_coupon_details,
      digital_coupon_title,
      digital_coupon_item_qty_required
    },
  } = productDetails;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const {
    productCartList,
    addProductToCartListLoading,
    wishListItemsStatus,
    wishListItemsMessage,
    wishListItemsLoading,
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const addedProcduct =
    productCartList &&
    productCartList.find((product) => product.product_id === product_id);

  const itemId = addedProcduct && addedProcduct.item_id;
  const cartProductQuantity = addedProcduct && addedProcduct.qty;

  const [selectDropDownedOption, setSelectDropDownedOption] = useState();
  const [productQuantity, setProductQuantity] = useState(
    +cartProductQuantity || 1
  );
  //const [likeButtonToggle, setLikeButtonToggle] = useState(false);

  const [isAddingProductToCart, setAddingProductToCart] = useState(false);

  const customOptions = custom_options.find(
    (option) => option.type === "dropdown"
  );

  const selectedCustomOption =
    customOptions && customOptions["values"]
      ? customOptions["values"].filter(
        (value) => value.value === selectDropDownedOption
      )
      : [];

  useEffect(() => {
    addedProcduct && addedProcduct.custom_options_arr[0]
      ? setSelectDropDownedOption(addedProcduct.custom_options_arr[0].value)
      : setSelectDropDownedOption();
  }, [addedProcduct]);

  const handleChangeDropDownOption = (_, name) => {
    setSelectDropDownedOption(name);
  };

  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
    handleUpdateProductToCart(
      productQuantity + 1,
      product_id,
      itemId,
      selectDropDownedOption
    );
  };
  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
      handleUpdateProductToCart(
        productQuantity - 1,
        product_id,
        itemId,
        selectDropDownedOption
      );
    } else {
      addedProcduct && handleRemoveProductToCart(itemId);
      setProductQuantity(1);
    }
  };

  // const handleLikeButtonToggle = () => {
  //   setLikeButtonToggle(!likeButtonToggle);
  // };

  const handleAddToWishlist = (list_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id: product_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      qty: productQuantity,
      list_id,
    };

    isUserLoggedIn &&
      dispatch(addToWishlist(body)).then(() => {
        setShowMyListDropdown(false);
      });
  };

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      qty: productQuantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: "",
      custom_options_arr: selectedCustomOption || "",
    };

    setAddingProductToCart(true);
    isUserLoggedIn &&
      dispatch(addToCart(body)).then(() => {
        setAddingProductToCart(false);
      });
  };

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getRemoveProductToCartList(body));
  };

  const handleUpdateProductToCart = (
    quantity,
    product_id,
    item_id,
    selectedOption
  ) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      item_id,
      qty: quantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: "",
      custom_options_arr: selectedOption
        ? selectedOption
        : selectedCustomOption.length
          ? selectedCustomOption
          : "",
    };
    quantity >= 1 && dispatch(editProductToCartList(body));
  };

  return (
    <div className="product-preview-section">
      <div className="container">
        <div className="grid">
          <div className="grid-items">
            <div>
              <ProductPreviewSlider image={image} gallery={gallery} />
            </div>
          </div>
          <div className="grid-items">
            <h1>{name}</h1>
            {/*<div className="rating-alignment">
              <div className="star-alignment">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
             </div>
              <div className="review-text">
                <span>(32 reviews)</span>
              </div>
            </div>*/}
            <div className="final-price">
              <div className="price">
                <h2>{price1}</h2>
                {ebt_item === "1" && <img src={ebt_image} alt="ebt_image" />}
              </div>
              {/*<div className="discount-price">
          <p>20% off</p>
          <span>
            <del>$8.30</del>
          </span>
        </div>*/}
              {
                +productDetails.stores_inventory.is_onsale > 0 ?
                  <div className="product-card-onsale-lable">On&nbsp;Sale</div>
                  : ""
              }
            </div>
            <div className="other-price-details">
              {price2 !== "" ? <p>{price2}</p> : ""}
              {price3 !== "" ? <p>{price3}</p> : ""}
              {price4 !== "" ? <p>{price4}</p> : ""}
            </div>
            <div className="coupon-pdp-used-time-wrapper">
              {+has_coupon > 0 ? (
                <>
                  {is_discount_percentage === "0" && digital_coupon_amount > 0 ? (
                    <div className="coupon-design">
                      <p>
                        Digital Coupon:
                        <span>
                          {" "}
                          {is_discount_percentage === "0" ? "$" : ""}
                          {`${digital_coupon_amount}`}
                          {is_discount_percentage === "1" ? "%" : ""}
                        </span>
                      </p>
                    </div>

                  ) : (
                    <>
                      {is_discount_percentage === "1" ? (
                        <>
                          {+digital_coupon_amount === 100 ? (
                            <div className="coupon-design">
                              <p>
                                Digital Coupon:
                                <span>{" FREE* "}</span>
                              </p>
                            </div>
                          ) : (
                            <div className="coupon-design">
                              <p>
                                Digital Coupon:
                                <span>
                                  {" "}
                                  {is_discount_percentage === "0" ? "$" : ""}
                                  {`${digital_coupon_amount}`}
                                  {is_discount_percentage === "1" ? "%" : ""}
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      ) : ("")}
                    </>
                  )}
                  {digital_coupon_item_qty_required > 1 &&
                    <div className="offer-price">
                      <div className="offer-text">when you buy {digital_coupon_item_qty_required}</div>
                    </div>
                  }
                  {digital_coupon_title && digital_coupon_details &&
                    <span className="coupon-title">{digital_coupon_title + " - " + digital_coupon_details}</span>
                  }
                </>
              ) : (
                ""
              )}


              {coupon_used_time &&
                coupon_used_time > 1 &&
                +has_coupon > 0 ? (
                <div className="coupon-used-time-ribbon coupon-used-pdp-item">
                  <div className="coupon-used-time">
                    <p>
                      <span>Use {coupon_used_time}X</span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {final_price > 0 ? (
              <div className="final-price">
                <h2> Final price: ${final_price}</h2>
              </div>
            ) : (
              ""
            )}

            <div className="add-product-option">
              <div className=" couter-button">
                <button>
                  <span>
                    {(addedProcduct && addedProcduct.qty) || productQuantity}
                  </span>
                  <div className="arrow-right-side">
                    <div
                      onClick={handleIncrementCount}
                      title="Click to increase the qty"
                    >
                      <i className="fa-solid fa-angle-up"></i>
                    </div>
                    <div
                      onClick={handleDecrementCount}
                      title="Click to decrease the qty"
                    >
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </button>
              </div>

              <div className="add-to-cart">
                <button onClick={handleAddToCart} disabled={stock_status === "0" || addedProcduct}>
                  <i className="fa-solid fa-cart-shopping add-to-cart-icon"></i>
                  <i className="fa-solid fa-ban out-of-stock-icon"></i>
                  <span>
                    {addProductToCartListLoading && isAddingProductToCart
                      ? "Adding..."
                      : addedProcduct
                        ? "Added to cart"
                        : stock_status === "0" ?
                          "out of stock" : "Add"}
                  </span>
                </button>
              </div>
              <div className="link-button">
                {isUserLoggedIn ? (
                  <AddToMyList
                    isShowMyListDropdown={isShowMyListDropdown}
                    isShowNewListModal={isShowNewListModal}
                    setShowNewListModal={setShowNewListModal}
                    setShowMyListDropdown={setShowMyListDropdown}
                    handleApply={handleAddToWishlist}
                    loading={wishListItemsLoading}
                    title="Add to my list"
                  />
                ) : (
                  ""
                )}
              </div>
              {wishListItemsStatus && wishListItemsStatus === "error"
                ? wishListItemsMessage
                : ""}
            </div>

            {custom_options &&
              custom_options.map((option) => {
                return (
                  <div className="substitution">
                    <p>{option.title}</p>
                    <div className="select-substitition-dropdown">
                      <Dropdown
                        optionList={
                          option.values
                            ? option.values.map((list) => {
                              return { title: list.value, value: list.id };
                            })
                            : []
                        }
                        lable=""
                        value={selectDropDownedOption}
                        placeholder="Select Option"
                        defaultValue=""
                        onChange={handleChangeDropDownOption}
                      />
                    </div>
                  </div>
                );
              })}

            <div className="department-code">
              <div>
                <p>
                  Department: <span>{department_name}</span>
                </p>
              </div>
              {productDetails.like_code ? (
                <>
                  <div>
                    <p>
                      Like Code: <span>{productDetails.like_code}</span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <p>
                      UPC: <span>{sku}</span>
                    </p>
                  </div>
                </>
              )}

            </div>
            {+has_coupon > 0 && digital_coupon_title && digital_coupon_details &&
              <div className="department-code">
                <div>
                  <p>
                    Digital Coupon Details:
                  </p>
                  <p>
                    <span>{digital_coupon_title + " - " + digital_coupon_details}</span>
                  </p>
                </div>
              </div>
            }
            {productDetails.stores_inventory.aisle_number ? (
              <div className="department-code">
                <div>
                  <p>
                    Aisle Number:{" "}
                    <span>{productDetails.stores_inventory.aisle_number}</span>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {mark_as_age_restricted_department_message && (
              <div className="restricted">
                <p>{mark_as_age_restricted_department_message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductPreview;
