import React from "react";
import "./provide-section.scss";

const ProvideSection = ({mobileappData  }) => {
  return (
    <div>
      <div className="provide-section">
        <div className="container">
          <div className="provide-section-title">
            <h1>{mobileappData.question_section.title}</h1>
          </div>
          
            <div className="faq">
         
            <p
                   dangerouslySetInnerHTML={{
                    __html: mobileappData
                      ? mobileappData.question_section.content
                      : "",
                  }}
                >
                  { }
                </p>
           
           {/* { 
           mobileappData.question_section.question_section_list.map((el)=>{

              return(
                <>
                  <h1>{el.title}</h1>

                   {el.question_list.map((item)=>{
                     return(
                     <>
                    <h3>{item.title}</h3>

                    <span>{item.short_description}</span>
                    </>
                     )

                    }
                   )
                  }
                    
                    </>
              )
            })
          } */}
                  
           
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvideSection;
