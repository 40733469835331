import React from "react";
import "./custom-history-card.scss";

const LeftImageRightText = ({ section }) => {

  return (
    <>
    <div className="container">
      <div className="content content-page-main">
      <div className="grid">
        {section.click_on_image_redirect_url ? (
          <a href={section.click_on_image_redirect_url} target="_blank" rel="noreferrer">
             <img
              src={
                section
                  ? section.image
                  : ""
              }
              alt="AboutImage"
              className="left-side image-left"
            />
          </a>
        ) : (
          <img
          src={
            section
              ? section.image
              : ""
          }
          alt="AboutImage"
          className="left-side image-left"
        />
        )}
        <div className="about-text-main">
        <h1>
          {section
            ? section.highlight_title
            : ""}
        </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: section
              ? section.content
              : "",
          }}
        >
          { }
        </p>
        </div>
      </div>
    </div>
  </div>
    </>
  );
};

export default LeftImageRightText;
