import React from "react";
import { useSelector } from "react-redux";
import DownMenu from "assets/icons/menu-down.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Departmentmenu from "./Departmentmenu/index";

const NavMenu = () => {
  const navigate = useNavigate();

  const menuData = useSelector(({ common }) => common.commonDetailsData.menu);

  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const { allow_recipe } = headerSection;

  const handleClickMenu = (menuItem) => {
    navigate(menuItem.new_page_id ? `custom-pages/${menuItem.new_page_id}` : menuItem.menu_url);
  };

  const handleReplaceUrl = (url) => {
    window.location.href = url;
    // let pathName = url.split("/");
    // let exactPath = pathName[pathName.length - 1];
    // console.log(exactPath);
    // window.location.pathname = exactPath;
  };

  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );

  const menu = (ele) => {
    if (ele.submenu.length) {
      return (
        <div className="submenu-wrapper" key={ele.menu_id}>
          <span onClick={() => handleClickMenu(ele)}>
            {ele.menu_name}
          </span>
          <img src={DownMenu} alt="DownMenu" />
          <div className="sub-menu-dropdown">
            <div className="list-align">
              {ele.submenu
                .filter((fls) => fls.menu_visible === "1")
                .map((el) => {
                  return el.menu_link === "1" ? (
                    el.menu_open_self === "0" ? (
                      <a
                        key={el.menu_id}
                        href={el.menu_url}
                        target="_blank"
                        rel="noreferrer"
                        className={
                          el.is_highlight_menu === "1" ? "highlight-menu" : ""
                        }
                      >
                        <span>{el.menu_name}</span>
                      </a>
                    ) : (
                      <span
                        onClick={() => handleReplaceUrl(el.menu_url)}
                        key={el.menu_id}
                        className={
                          el.is_highlight_menu === "1" ? "highlight-menu" : ""
                        }
                      >
                        <span>{el.menu_name}</span>
                      </span>
                    )
                  ) : (
                    <NavLink
                      key={el.menu_id}
                      to={el.new_page_id ? `custom-pages/${el.new_page_id}` : el.menu_url}
                      className={
                        el.is_highlight_menu === "1" ? "highlight-menu" : ""
                      }
                    >
                      <span>{el.menu_name}</span>
                    </NavLink>
                  );
                })}
            </div>
          </div>
        </div>
      );
    } else {
      if (ele.show_modal === "0") {
        if (ele.menu_url === "recipes" && allow_recipe === "0") return null;
        return ele.menu_link === "1" ? (
          ele.menu_open_self === "0" ? (
            <a
              key={ele.menu_id}
              href={ele.menu_url}
              target="_blank"
              rel="noreferrer"
              className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
            >
              <span>{ele.menu_name}</span>
            </a>
          ) : (
            <>
            {ele.new_page_id === "" ? (
            <a
              href={(e) => e.preventDefault()}
              onClick={() => handleReplaceUrl(ele.menu_url)}
              key={ele.menu_id}
              className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
            >
              <span>{ele.menu_name} {ele.new_page_id}</span>
            </a>
            ) : (
              <NavLink
                      key={ele.menu_id}
                      to={`custom-pages/${ele.new_page_id}`}
                      className={
                        ele.is_highlight_menu === "1" ? "highlight-menu" : ""
                      }
                    >
                      <span>{ele.menu_name}</span>
                    </NavLink>
            )}
            </>
          )
        ) : (
          <NavLink
            key={ele.menu_id}
            to={ele.new_page_id ? `custom-pages/${ele.new_page_id}` : ele.menu_url}
            className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
            target={ele.menu_open_self === "0" && "_blank"}
          >
            <span>{ele.menu_name}</span>
          </NavLink>
        );
      } else {
        return (
          <Departmentmenu
            menuName={ele.menu_name}
            couponDepartmentmenu={couponDepartmentmenu}
          />
        );
      }
    }
  };

  return (
    <div className="nav-menu">
      {menuData
        .filter((fl) => fl.menu_visible === "1")
        .map((ele) => {
          return menu(ele);
        })}
    </div>
  );
};

export default NavMenu;
