import {
  FETCH_SEARCH_RESULT_LIST,
  FETCH_SEARCH_RESULT_LIST_SUCCESS,
  FETCH_SEARCH_RESULT_LIST_FAILURE,
  UPDATE_SEARCH_TEXT,
  UPDATE_SEARCH_TYPE,
  REMOVE_SEARCH_TAG,
  RESET_SEARCH,
} from "redux/constants/actionTypes";

import axios from "config/axios";
import { analyticsProductSearch } from "./analytics";

// fetch search products
export const fetchSearchResultList = (payload) => {
  return {
    type: FETCH_SEARCH_RESULT_LIST,
    payload: payload,
  };
};
export const fetchSearchResultListSuccess = (payload) => {
  return {
    type: FETCH_SEARCH_RESULT_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchSearchResultListFailure = (payload) => {
  return {
    type: FETCH_SEARCH_RESULT_LIST_FAILURE,
    payload: payload,
  };
};

export const getSearchResultList = (body) => async (dispatch) => {
  dispatch(fetchSearchResultList());
  axios
    .post("/AogGetSearchKeywords", body)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch(fetchSearchResultListSuccess(res.data.data));
      } else {
        dispatch(fetchSearchResultListSuccess([]));
      }
    })
    .catch((error) => {
      dispatch(fetchSearchResultListFailure({ error: error.data.message }));
    });
};

export const updateSearchText = (payload) => {
  return {
    type: UPDATE_SEARCH_TEXT,
    payload: payload,
  };
};
export const updateSearchType = (payload) => {
  return {
    type: UPDATE_SEARCH_TYPE,
    payload: payload,
  };
};
export const removeSearchTags = (payload) => {
  return {
    type: REMOVE_SEARCH_TAG,
    payload: payload,
  };
};

export const resetSearch = (payload) => {
  return {
    type: RESET_SEARCH,
    payload: payload,
  };
};
