import {
  FETCH_WEEKLYADGALLERY_DATA,
  FETCH_WEEKLYADGALLERY_DATA_SUCCESS,
  FETCH_WEEKLYADGALLERY_DATA_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  weeklyadgalleryData: {},
  weeklyadgalleryDataLoading: true,
  weeklyadgalleryDataError: "",
};

const weeklyadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEEKLYADGALLERY_DATA:
      return {
        ...state,
        weeklyadgalleryDataLoading: true,
      };
    case FETCH_WEEKLYADGALLERY_DATA_SUCCESS:
      return {
        ...state,
        weeklyadgalleryDataLoading: false,
        weeklyadgalleryData: action.payload,
      };
    case FETCH_WEEKLYADGALLERY_DATA_FAILURE:
      return {
        ...state,
        weeklyadgalleryDataError: action.payload.error,
      };

    default:
      return state;
  }
};

export default weeklyadReducer;
