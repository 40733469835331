import React, { useState, useEffect } from "react";
import "./offer-section.scss";
import TabList from "components/Common/TabList";
import ProductListSlider from "../ProductListSlider";
import { useSelector  } from "react-redux";
const OfferSection = () => {
  const offersWithProductData = useSelector(
    ({ homepage }) => homepage.offersWithProductData
  );
    
  const [activeOfferId, setActiveOfferId] = useState("");
  const activeOfferProductList =
    offersWithProductData?.find((offer) => offer.id === activeOfferId)
      ?.products_list || [];

  useEffect(() => {
    offersWithProductData?.length &&
      setActiveOfferId(offersWithProductData[0].id);
  }, [offersWithProductData]);

  return (
    <div>
      {offersWithProductData && activeOfferProductList && (
        <>
          <TabList
            tabs={offersWithProductData.map((offer) => ({
              title: offer.title,
              id: offer.id,
            }))}
            activeTabId={activeOfferId}
            onSelectTab={(tabId) => setActiveOfferId(tabId)}
          />
          <ProductListSlider products={activeOfferProductList} addButtonText="Add to Cart" />
        </>
      )}
    </div>
  );
};
export default OfferSection;
