import Slider from "react-slick/lib/slider";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import ImageCard from "./ImageCard";
import HomePageCouponCard from "./HomePageCouponCard";
import { addcouponToCart, getcouponcartlist } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

import "./coupon-list-slider.scss";
import DescriptionModal from "components/CouponCard/Description";

import { useNavigate } from "react-router-dom";


const CouponListSlider = ({ coupons }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { storeListData } = useSelector(({ store }) => store);
  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  const { ClientStoreName = "", ClientStoreId = "" } = selectedStore || "";
  const settings = {
    dots: false,
    infinite: coupons.length > 5,
    slidesToShow: coupons.length > 1 ? 5 : coupons.length,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const [modal, setModal] = useState(false);
  const [coupon, setCoupon] = useState({});

  const [contentModal, setContentModal] = useState("");
  const [contentDescription, setcontentDescription] = useState("");
  const [contentExpire, setExpireon] = useState("");
  const [image, setImage] = useState("");
  const [offerId, setOfferId] = useState("");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const [isAddingCouponCart, setAddingCouponToCart] = useState(false);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const storeId = getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userFirstName = getLocalStorageValue("first-name");

  const { couponCartList, addCouponToCartListLoading } = useSelector(
    ({ cartCoupon }) => cartCoupon
  );

  const showModal = (coupon) => {

    setContentModal(coupon.title);
    setcontentDescription(coupon.details);
    setExpireon(coupon.expires_on);
    setImage(coupon.image_path);
    setCoupon(coupon);
    setOfferId(coupon.offer_id);
    setModal(!modal);
  }

  const Toggle = () => setModal(!modal);

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: memberNumber,
      offer_id: coupon.offer_id,
      news_category_id: coupon.news_category_id,
      user_first_name: userFirstName,
      ncr_promotion_code: coupon.ncr_promotion_code,
      is_in_ncr_impressions: false,
      title: coupon.title,
      details: coupon.details,
      amount: coupon.amount,
      product_name: coupon.product_name,
      real_valid_from: coupon.real_expires_on,
      real_expires_on: coupon.real_valid_from,
      upc: "",
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };

    setAddingCouponToCart(true);
    isUserLoggedIn &&
      dispatch(addcouponToCart(body)).then(() => {
        setAddingCouponToCart(false);
        couponCartlist();

      });
  };

  const [renderCouponlist, setRenderCouponlist] = useState(false);

  const couponCartlist = () => {
    setRenderCouponlist(!renderCouponlist)
  }

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn &&
      dispatch(getcouponcartlist(body))
  }, [renderCouponlist]); // eslint-disable-line


  //const offerId = coupon.offer_id;

  const addedCoupon =
    couponCartList &&
    couponCartList.find((coupon) => coupon.offer_id === offerId);

  return (
    <div className="container">
      <div className="coupon-list-wrapper">
        <Slider {...settings}>
          {coupons.map((coupon) => {
            if (coupon?.gallery_type) {
              return (
                <ImageCard
                  image={coupon}
                  key={coupon.page_number}
                  clientStoreName={ClientStoreName}
                  clientStoreId={ClientStoreId}
                />
              );
            }
            return (
              // <CouponCard coupon={coupon} key={coupon.ncr_promotion_code} changeHandler={showModal}/>
              <HomePageCouponCard coupon={coupon} key={coupon.ncr_promotion_code} changeHandler={showModal} handleCouponcartList={couponCartlist} />
            );
          })}
        </Slider>


        <DescriptionModal
          show={modal}
          title="My Modal"
          close={Toggle}
          contentModal={contentModal}
          contentDescription={contentDescription}
          contentExpire={contentExpire}
          image={image}
          addedCoupon={addedCoupon}
          addtoCart={handleAddToCart}
          isUserLoggedIn={isUserLoggedIn}
          addCouponToCartListLoading={addCouponToCartListLoading}
          isAddingCouponCart={isAddingCouponCart}
        />

      </div >
    </div >
  );
};
export default CouponListSlider;
