import React from "react";
import "./add-section.scss";
import { useSelector } from "react-redux";

const AddSection = () => {
  const adsSectionData = useSelector(({ adsection }) => adsection.adsData);

  return (
    <>
      {adsSectionData.length > 0 ? (
        <div className="home-add-section-alignment">
          <div className="container">
            <div className="add-grid">
              {adsSectionData.map((ads, index) => {
                return (
                  <div className="add-grid-items" key={index}>
                    {ads.is_link === "1" ? <a
                      target={`${ads.is_open_self === "1" ? '_self' : '_blank'}`}
                      rel="noopener noreferrer"
                      href={ads.ad_target_url}
                    >
                      <img src={ads.image} alt={ads.ad_title} />
                    </a> :
                      <img src={ads.image} alt={ads.ad_title} />
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AddSection;
