import React from "react";
import "./tab-list.scss";
import Tab from "./Tab";

const TabList = (props) => {
  const { tabs, activeTabId, onSelectTab } = props;

  return (
    <div className="container">
      <div className="tab-alignment">
        <ul>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.id}
                tab={tab}
                isActive={tab.id === activeTabId}
                onSelectTab={onSelectTab}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default TabList;
