import React from "react";
import { Link } from "react-router-dom";
import "./hot-offers.scss";

const HotOffers = (props) => {
  const { title, adverstiseSectionData } = props;

  return adverstiseSectionData && adverstiseSectionData.length > 0 ? (
    <div>
      <div className="hot-offers-section">
        <div className="page-title">
          <h1>{title}</h1>
        </div>
        <div className="grid">
          {adverstiseSectionData.map((hotOffer) => {
            return hotOffer.is_link === "1" ? (
              hotOffer.is_open_self === "0" ? (
                <a
                  href={hotOffer.button_url}
                  target="_blank"
                  rel="noreferrer"
                  key={hotOffer.adverstise_id}
                >
                  <div className="grid-items">
                    <img src={hotOffer.image} alt="OfferBannerImage" />
                  </div>
                </a>
              ) : (
                <Link to={hotOffer.button_url} key={hotOffer.adverstise_id}>
                  <div className="grid-items">
                    <img src={hotOffer.image} alt="OfferBannerImage" />
                  </div>
                </Link>
              )
            ) : (
              <div className="grid-items" key={hotOffer.adverstise_id}>
                <img src={hotOffer.image} alt="OfferBannerImage" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
export default HotOffers;
