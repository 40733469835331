import {
  FETCH_CUSTOM_PAGE_CONTENT,
  FETCH_CUSTOM_PAGE_CONTENT_SUCCESS,
  FETCH_CUSTOM_PAGE_CONTENT_FAILURE
  } from "redux/constants/actionTypes";
  
  let initialState = {
    customPageData: {},
    fetchCustomPageLoading: true,
    fetchCustomPageError: ""
  };
  
  const customPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CUSTOM_PAGE_CONTENT:
        return {
          ...state,
          fetchCustomPageLoading: true,
        };
      case FETCH_CUSTOM_PAGE_CONTENT_SUCCESS:
        return {
          ...state,
          customPageData:action.payload,
          fetchCustomPageLoading: false
        };
      case FETCH_CUSTOM_PAGE_CONTENT_FAILURE:
        return {
          ...state,
          fetchCustomPageError: action.payload.error
        };
        
      default:
        return state;
    }
  };
  
  export default customPageReducer;