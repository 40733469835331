import React from "react";
import LeftArrow from "assets/icons/left.svg";
import RightArrow from "assets/icons/right.svg";

import "./pagination.scss";

const Pagination = (props) => {
  const { pages, current, onClick } = props;

  const onPrev = () => onClick && onClick(current - 1);
  const onNext = () => onClick && onClick(current + 1);
  const onPrevDash = () => onClick && onClick(current - 2);
  const onNextDash = () => onClick && onClick(current + 2);
  const onFirst = () => onClick && onClick(1);
  const onLast = () => onClick && onClick(pages);

  return (
    <div>
      <div className="pagination">
        <div className="pagination-wrapper">
          {current !== 1 ? (
            <div onClick={onPrev} className={"left-arrow"}>
              <img src={LeftArrow} alt="LeftArrow" />
            </div>
          ) : (
            <div className={"left-arrow"}>
              <img src={LeftArrow} alt="LeftArrow" />
            </div>
          )}

          {current !== 1 && <button onClick={onFirst}>1</button>}
          {current > 3 && <button onClick={onPrevDash}>...</button>}
          {current - 1 > 1 && <button onClick={onPrev}>{current - 1}</button>}
          <button className="page-counter active-page">{current}</button>
          {current + 1 < pages && (
            <button onClick={onNext}>{current + 1}</button>
          )}
          {current <= pages - 3 && <button onClick={onNextDash}>...</button>}
          {current !== pages && <button onClick={onLast}>{pages}</button>}
          {current !== pages ? (
            <div onClick={onNext} className={"right-arrow"}>
              <img src={RightArrow} alt="RightArrow" />
            </div>
          ) : (
            <div className={"right-arrow"}>
              <img src={RightArrow} alt="RightArrow" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Pagination;
