import React from "react";
import { Link } from "react-router-dom";
import "./add-space-section.scss";
const AdsSpaceSection = ({ adsImage }) => {
  return (
    <>
      {adsImage && adsImage.ads_image ? (
        <div>
          <div className="ads-space-section">
            <Link to={`/${adsImage.ads_url}`}>
              <img src={adsImage.ads_image} alt="ads" />
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AdsSpaceSection;
