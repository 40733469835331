import {
  FETCH_RECIPES_MASTER_DATA,
  FETCH_RECIPES_MASTER_DATA_SUCCESS,
  FETCH_RECIPES_MASTER_DATA_FAILURE,
  FETCH_RECIPES_LIST,
  FETCH_RECIPES_LIST_SUCCESS,
  FETCH_RECIPES_LIST_FAILURE,
  FETCH_RECIPE_DETAIL,
  FETCH_RECIPE_DETAIL_SUCCESS,
  FETCH_RECIPE_DETAIL_FAILURE,
  FETCH_SIMILAR_RECIPE_LIST,
  FETCH_SIMILAR_RECIPE_LIST_SUCCESS,
  FETCH_SIMILAR_RECIPE_LIST_FAILURE,
  FILTER_RECIPE_SEARCH_TEXT,
  FETCH_RECIPES_PRODUCT_LIST,
  FETCH_RECIPES_PRODUCT_LIST_SUCCESS,
  FETCH_RECIPES_PRODUCT_LIST_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  //master data
  recipesMasterData: null,
  recipesMasterDataLoading: true,
  recipesMasterDataError: "",

  //recipes list
  recipeList: [],
  recipeListTotalRecord: 0,
  recipeListLoading: true,
  recipeListError: "",

  recipeDetail: null,
  recipeDetailLoading: true,
  recipeDetailError: "",

  similarRecipeList: [],
  similarRecipeListLoading: true,
  similarRecipeListError: "",

  recipeSearchText: "",

  recipeProductList: [],
  recipeProductListLoading: false,
  recipeProductListError: "",
  recipeProductTotalCount: 0,
  recipeProductTotalPages: 0,
};

const recipesReducer = (state = initialState, action) => {
  switch (action.type) {
    //master data
    case FETCH_RECIPES_MASTER_DATA:
      return {
        ...state,
        recipesMasterDataLoading: true,
      };
    case FETCH_RECIPES_MASTER_DATA_SUCCESS:
      return {
        ...state,
        recipesMasterDataLoading: false,
        recipesMasterData: action.payload,
      };
    case FETCH_RECIPES_MASTER_DATA_FAILURE:
      return {
        ...state,
        recipesMasterDataError: action.payload.error,
        recipesMasterDataLoading: false,
      };
    //list
    case FETCH_RECIPES_LIST:
      return {
        ...state,
        recipeListLoading: true,
      };
    case FETCH_RECIPES_LIST_SUCCESS:
      return {
        ...state,
        recipeListLoading: false,
        recipeList: action.payload.results,
        recipeListTotalRecord: action.payload.totalResults,
      };
    case FETCH_RECIPES_LIST_FAILURE:
      return {
        ...state,
        recipesMasterDataError: action.payload.error,
        recipeListLoading: false,
      };
    //detail
    case FETCH_RECIPE_DETAIL:
      return {
        ...state,
        recipeDetailLoading: true,
      };
    case FETCH_RECIPE_DETAIL_SUCCESS:
      return {
        ...state,
        recipeDetailLoading: false,
        recipeDetail: action.payload,
      };
    case FETCH_RECIPE_DETAIL_FAILURE:
      return {
        ...state,
        recipeDetailError: action.payload.error,
        recipeDetailLoading: false,
      };

    //similar recipes
    case FETCH_SIMILAR_RECIPE_LIST:
      return {
        ...state,
        similarRecipeListLoading: true,
      };
    case FETCH_SIMILAR_RECIPE_LIST_SUCCESS:
      return {
        ...state,
        similarRecipeListLoading: false,
        similarRecipeList: action.payload,
      };
    case FETCH_SIMILAR_RECIPE_LIST_FAILURE:
      return {
        ...state,
        similarRecipeListError: action.payload.error,
        similarRecipeListLoading: false,
      };
    case FILTER_RECIPE_SEARCH_TEXT:
      return {
        ...state,
        recipeSearchText: action.payload,
      };

    case FETCH_RECIPES_PRODUCT_LIST:
      return {
        ...state,
        recipeProductListLoading: true,
        recipeProductList: [],
      };
    case FETCH_RECIPES_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        recipeProductListLoading: false,
        recipeProductList: action.payload.data,
        recipeProductTotalCount: action.payload.TotalRecords || 0,
        recipeProductTotalPages: action.payload.NoOfPages || 0,
      };
    case FETCH_RECIPES_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        recipeProductListError: action.payload.error,
        recipeProductListLoading: false,
      };

    default:
      return state;
  }
};

export default recipesReducer;
